import { Email } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useUiStore } from "../stores/uiStore";

const barHeight = 67;
export default function LinkList() {
  const navigate = useNavigate();
  const theme = useTheme();

  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [expanded, setExpanded] = useUiStore((state) => [
    state.menuOpen,
    state.setMenuOpen,
  ]);
  const [setShowWelcomeScreen, setShowNewsletterSignup] = useUiStore(
    (state) => [state.setShowWelcomeScreen, state.setShowNewsletterSignup]
  );
  const [hoveredResult, sethoveredResult] = useState<number>();

  const items = [
    {
      label: "How it works",
      onClick: () => setShowWelcomeScreen(true),
    },
    {
      label: "FAQ",
      onClick: () => {
        setShowWelcomeScreen(true);
        setTimeout(
          () =>
            document
              .getElementById("FAQ")
              ?.scrollIntoView({ behavior: "smooth" }),
          0
        );
      },
    },
    // {
    //   label: "Royalties",
    //   onClick: () => navigate(`royalties/${window.location.search}`),
    // },
    {
      label: "For events",
      onClick: () =>
        window
          .open(
            "https://pitch.com/public/bc704cf7-20d2-4843-b781-6f22f7a653ee/7b7c2ad0-11ff-4d5a-a086-c76de5206248",
            "_blank"
          )
          ?.focus(),
    },
  ];

  useEffect(() => {
    setExpanded(isBigScreen);
  }, [setExpanded, isBigScreen]);

  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        borderRadius: 3,
        overflow: {
          xs: "auto",
          md: "hidden",
        },
        mt: 2,
        maxHeight: (theme) => ({
          xs: expanded
            ? `calc(100vh - ${theme.spacing(6)} - ${barHeight * 2}px)`
            : 0,
          md: expanded ? 650 : 0,
        }),
        opacity: expanded ? 1 : 0,
        transition: "all 0.5s",
        boxShadow: 3,
      }}
    >
      <Box
        sx={{
          pl: 3,
          pr: 3,
          pb: 2,
          pt: 1,
        }}
      >
        {items.map((item, index) => (
          <Box key={item.label}>
            <Typography
              component="div"
              sx={{
                cursor: "pointer",
                py: 2,
                color: hoveredResult === index ? "primary.main" : "inherit",
              }}
              onClick={item.onClick}
              variant="h6"
              onMouseEnter={(e) => sethoveredResult(index)}
              onMouseLeave={() => sethoveredResult(undefined)}
            >
              {item.label}
            </Typography>
            <Divider />
          </Box>
        ))}
        {/* <Typography sx={{ mt: 2 }}>
          🌟{" "}
          <Link onClick={() => navigate("/random-drop/New York City")} href="#">
            Random drop ongoing!
          </Link>{" "}
          🌟
        </Typography> */}
        <Box sx={{ mt: 3 }}>
          <IconButton
            onClick={() =>
              window.open("https://twitter.com/TURF_NFT", "_blank")
            }
            sx={{ width: 40, height: 40, mr: 1 }}
          >
            <img alt="twitter" src={`${process.env.PUBLIC_URL}/twitter.svg`} />
          </IconButton>
          <IconButton
            onClick={() =>
              window.open("https://discord.com/invite/H5EjT4jdWj", "_blank")
            }
            sx={{ width: 40, height: 40, mr: 1 }}
          >
            <img alt="discord" src={`${process.env.PUBLIC_URL}/discord.svg`} />
          </IconButton>
          <IconButton
            href="mailto:hello@turfnft.com"
            sx={{ mr: 1, color: "rgba(0,0,0,0.4)" }}
          >
            <Email />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "grey.200",
          pl: 3,
          pr: 3,
          pb: 3,
          pt: 3,
          borderTop: 1,
          borderTopColor: "divider",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="GrayText"
          sx={{ mb: 3 }}
        >
          © TURF&nbsp;&nbsp;•&nbsp;&nbsp;
          <RouterLink
            to={`terms-and-conditions${window.location.search}`}
            style={{
              color: "inherit",
              textDecorationColor: "inherit",
              fontWeight: "normal",
              textDecoration: "underline",
            }}
          >
            Terms & Conditions
          </RouterLink>
          &nbsp;&nbsp;•&nbsp;&nbsp;
          <Link
            href="mailto:hello@turfnft.com"
            sx={{
              color: "inherit",
              textDecorationColor: "inherit",
              fontWeight: "normal",
            }}
          >
            hello@turfnft.com
          </Link>
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            filter: "grayscale(1)",
            opacity: 0.5,
          }}
        >
          {/* <img
            src={`${process.env.PUBLIC_URL}/visa.svg`}
            width="40"
            alt="visa"
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <img
            src={`${process.env.PUBLIC_URL}/mastercard.svg`}
            width="25"
            alt="mastercard"
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
          <img
            src={`${process.env.PUBLIC_URL}/cardano.svg`}
            width="90"
            alt="cardano"
          />
          {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <img
            src={`${process.env.PUBLIC_URL}/full-polygon-logo.svg`}
            width="80"
            alt="cardano"
          /> */}
        </Box>
      </Box>
    </Box>
  );
}
