import { useTheme } from "@mui/material";
import bbox from "@turf/bbox";
import { latLngBounds } from "leaflet";
import { useState } from "react";
import { SVGOverlay, useMapEvents } from "react-leaflet";
import { GridSquare } from "../../definitions";
import { getGridFeatures } from "../my-api";

export default function MergeMapGrid() {
  const [grid, setGrid] = useState<GridSquare[]>([]);

  const getGrid = () => {
    if (map.getZoom() > 11) {
      getGridFeatures(map.getBounds().toBBoxString()).then((grid) => {
        setGrid(grid);
      });
    }
  };
  const map = useMapEvents({
    layeradd: getGrid,
    moveend: getGrid,
  });

  const theme = useTheme();

  return (
    <>
      {grid.map((square) => {
        const featBbox = bbox(square);
        const bounds = latLngBounds([
          [featBbox[1], featBbox[0]],
          [featBbox[3], featBbox[2]],
        ]);
        return (
          <SVGOverlay bounds={bounds} key={square.properties.id}>
            <svg height="100%" width="100%">
              <rect
                width="100%"
                height="100%"
                fill="transparent"
                strokeWidth={1}
                stroke={theme.palette.primary.main}
              />
            </svg>
          </SVGOverlay>
        );
      })}
    </>
  );
}
