import { Close } from "@mui/icons-material";
import { Box, Dialog, Grid, IconButton, Link, Typography } from "@mui/material";
import { useUiStore } from "../stores/uiStore";
import FAQ from "./FAQ";
import NewsletterForm from "./NewsletterForm";

export default function NewsletterSignupModal() {
  const [open, setOpen] = useUiStore((state) => [
    state.showNewsletterSignup,
    state.setShowNewsletterSignup,
  ]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="lg"
      PaperProps={{
        sx: {
          m: { xs: 1, sm: 2 },
          p: 4,
          maxHeight: (theme) => ({
            xs: `calc(100% - ${theme.spacing(2)})`,
            sm: `calc(100% - ${theme.spacing(4)})`,
          }),
        },
      }}
      keepMounted={false}
    >
      <IconButton
        sx={{ position: "absolute", right: 0, mr: 2 }}
        onClick={() => setOpen(false)}
      >
        <Close />
      </IconButton>
      <Typography
        variant="h2"
        sx={{
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          textAlign: "center",
          mb: 8,
        }}
        className="gradient"
      >
        TURF
      </Typography>
      <Grid
        container
        spacing={4}
        sx={{
          // marginBottom: 6,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12} sx={{ textAlign: "center", mb: 2 }}>
          <Typography
            variant="h4"
            // fontWeight={100}
            fontFamily="Roboto"
            sx={{ mb: 2 }}
          >
            🌟 Minting the 150 most visited cities on August 24th, 7PM utc 🌟
          </Typography>
          <Typography
            variant="h5"
            fontWeight={400}
            fontFamily="Roboto"
            sx={{ mb: 4 }}
          >
            Using a random drop mechanic.
            <br />
            <Link
              href="https://twitter.com/TURF_NFT/status/1669661781076361218"
              target="_blank"
            >
              More info here
            </Link>
          </Typography>
          <Box sx={{ mb: 8 }}>
            <Typography sx={{ mb: 1 }}>
              Submit your email for first access to the mint!
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", mb: 8 }}>
              <NewsletterForm formId="1b07ebc4-1fc8-11ee-84c0-955d6ccc0666" />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Typography
        variant="h4"
        fontWeight={100}
        fontFamily="Roboto"
        textAlign="center"
        sx={{ marginBottom: 4 }}
        id="FAQ"
      >
        Frequently Asked Questions
      </Typography>
      <FAQ sx={{ mb: 8 }} />
    </Dialog>
  );
}
