import {
  createTheme,
  PaletteOptions,
  responsiveFontSizes,
  ThemeOptions,
} from "@mui/material";
import { WlcPalette } from "./config";

const lightPalette: PaletteOptions = {
  mode: "light",
  primary: { main: WlcPalette[0] },
  secondary: { main: WlcPalette[1] },

  background: { paper: "#F8F6F1", default: "#FBF3DF" },
  text: {
    primary: "#001e25",
  },
  common: { black: "#001e25", white: "#F8F6F1" },
};

const darkPalette: PaletteOptions = {
  mode: "dark",
  primary: { main: "#F8F6F1" },
  secondary: { main: WlcPalette[1] },
  success: { main: WlcPalette[0] },
  background: { default: "#001e25" },
  text: {
    primary: "#F8F6F1",
  },
};

const themeOptions: ThemeOptions = {
  typography: {
    h1: { fontFamily: "Bebas Neue", fontSize: 150 },
    h2: { fontFamily: "Bebas Neue" },
    h3: { fontFamily: "Bebas Neue" },
    h4: { fontFamily: "Bebas Neue" },
    h5: { fontFamily: "Roboto", fontWeight: 700 },
    body1: { fontWeight: 400 },
    caption: { color: "GrayText" },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        gutterBottom: { marginBottom: "1em" },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: { borderRadius: 8, textTransform: "none", fontWeight: 700 },
        // @ts-ignore
        "&.Mui-disabled": { cursor: "not-allowed" },
      },
      defaultProps: {
        variant: "contained",
      },
    },
    MuiAlert: { styleOverrides: { root: { borderRadius: 12 } } },
    MuiTooltip: {
      defaultProps: {
        enterTouchDelay: 50,
        leaveTouchDelay: 2000,
      },
      styleOverrides: {
        tooltip: {
          borderRadius: 12,
          padding: 8,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: { borderRadius: 12, paddingLeft: 8, paddingRight: 8 },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: { borderRadius: 8 },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          zIndex: 1501,
        },
      },
    },
  },
};

let lightTheme = createTheme({ ...themeOptions, palette: lightPalette });
lightTheme = responsiveFontSizes(lightTheme);

let darkTheme = createTheme({ ...themeOptions, palette: darkPalette });
darkTheme = responsiveFontSizes(darkTheme);

export { lightTheme, darkTheme };
