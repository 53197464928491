import { ArrowBackIosNew, Close, ZoomIn } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  MenuItem,
  MenuList,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useReservationStore } from "../../stores/reservationStore";
import { useUiStore } from "../../stores/uiStore";
import { useWalletStore } from "../../stores/walletStore";

export default function TurfDetail() {
  const [setMyTurfsContent, myTurfsState, setSidebar, setError] = useUiStore(
    (state) => [
      state.setMyTurfsContent,
      state.myTurfsState,
      state.setSidebar,
      state.setError,
    ]
  );

  const [search, setSearch] = useSearchParams();
  const navigate = useNavigate();
  const turfsInWallet = useWalletStore((state) => state.turfsInWallet);
  const turf = turfsInWallet.find((t) => t.assetId === myTurfsState?.assetId);
  const [createMergeReservation, addProductToCart, resetReservation] =
    useReservationStore((state) => [
      state.createMergeReservation,
      state.addProductToCart,
      state.resetReservation,
    ]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!turf) {
      setMyTurfsContent("LIST");
    } else {
      setSearch({ ...search, locationCode: turf.locationCode, zoom: "12" });
    }
  }, [setSearch, turf, search, setMyTurfsContent]);

  const startUpgrade = async () => {
    setLoading(true);
    resetReservation();
    if (!myTurfsState?.assetId) {
      return;
    }
    try {
      await createMergeReservation([myTurfsState.assetId]);
      setSidebar("CHECKOUT");
    } catch (e) {
      setError("Cannot upgrade this TURF");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          mb: 2,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={() => setMyTurfsContent("LIST")} sx={{ mr: 1 }}>
            <ArrowBackIosNew />
          </IconButton>
          <Typography variant="h5" fontWeight={700}>
            {turf?.placeName}
          </Typography>
        </Box>
        <IconButton onClick={() => setSidebar("")}>
          <Close />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          mb: 2,
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "50%",
            boxShadow: 5,
            lineHeight: 0,
            mb: 2,
            cursor: "pointer",
            position: "relative",
          }}
          onClick={() =>
            navigate(`/interactive/${turf?.assetId}${window.location.search}`)
          }
        >
          <ZoomIn
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              m: 1,
              color: (theme) => theme.palette.common.black,
            }}
          />
          <img src={`${turf?.artThumbUrl}`} alt={turf?.placeName} />
        </Box>
      </Box>
      <Divider />
      <MenuList>
        <Stack direction="column" divider={<Divider />}>
          <MenuItem
            disabled={loading}
            onClick={async () => {
              setLoading(true);
              resetReservation();
              turf && (await addProductToCart(turf.id, 1));
              setSidebar("CHECKOUT");
            }}
            sx={{ display: "block" }}
          >
            <Typography variant="body1">Get print(s)</Typography>
            <Typography variant="caption">
              Get a print for yourself or for other people
            </Typography>
          </MenuItem>
          {/* <MenuItem
            disabled={loading}
            onClick={() => setMyTurfsContent("SELL_PRINTS")}
            sx={{ display: "block" }}
          >
            <Typography variant="body1">Sell prints</Typography>
            <Typography variant="caption">
              Sell prints to others and earn royalties!
            </Typography>
          </MenuItem> */}
          <MenuItem
            disabled={loading}
            onClick={startUpgrade}
            sx={{ display: "block" }}
          >
            <Typography variant="body1">Upgrade your TURF</Typography>
            <Typography variant="caption">
              Change the name of your TURF or shuffle the colors.
            </Typography>
          </MenuItem>
          <MenuItem
            disabled={loading}
            onClick={() => setMyTurfsContent("MERGE_INSTRUCTION")}
            sx={{ display: "block" }}
          >
            <Typography variant="body1">Merge TURFs</Typography>
            <Typography variant="caption">
              Combine multiple adjecent TURFs into one big art piece.
            </Typography>
          </MenuItem>
        </Stack>
      </MenuList>
    </>
  );
}
