import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ReactElement, SyntheticEvent, useState } from "react";
import { useSearchParams } from "react-router-dom";

const items = [
  {
    summary: "How does the random drop work?",
    details: (
      <Box>
        Price starts low as in the beginning the square you pull within an
        unminted city is highly random. You might get Manhattan. You might get
        the Bronx. But as more parts of a prime city are minted, the
        predictability of your pull grows and price increases accordingly.
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "700" }}>Tier</TableCell>
              <TableCell sx={{ fontWeight: "700" }}>% Sold</TableCell>
              <TableCell sx={{ fontWeight: "700" }}>Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {new Array(10).fill(null).map((_v, index) => {
              const tier = index + 1;
              const percentage =
                index > 0 ? `${index}0% - ${tier}0%` : `0% - 10%`;
              const priceEur = 30 + index * 5;
              // const price = Math.floor(priceEur * (exchangeRate?.value || 1));
              return (
                <TableRow
                  key={tier}
                  sx={{
                    backgroundColor:
                      index % 2 === 0 ? "rgba(0,0,0,0.05)" : "transparent",
                  }}
                >
                  <TableCell>{tier}</TableCell>
                  <TableCell>{percentage}</TableCell>
                  <TableCell>€ {priceEur}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    ),
  },
  {
    summary: "What is TURF?",
    details: (
      <Box>
        <Typography gutterBottom component="div">
          TURF creates map art that you truly own as every square of earth is
          available only once. When you buy TURF map art, you receive 3 things:
          <ol>
            <li>An interactive 3D art experience</li>
            <li>
              A HQ file download of the image below that's ready to be printed
              on fine art paper
            </li>
            <li>An NFT certifying your ownership of your piece of the world</li>
          </ol>
          These collectibles are gorgeous maps of your favorite places on Earth,
          with each representing a specific location.
        </Typography>
        <Typography gutterBottom>
          Each TURF art is a 1/1, meaning that there is only one of each
          available. This means that if you purchase a TURF digital collectable
          of your neighborhood, you are the only one who can claim ownership of
          it.
        </Typography>
        <Typography gutterBottom>
          TURF provides a way for you to have a personal, special connection to
          your favorite locations on Earth through beautiful and unique
          cartographic artwork.
        </Typography>
      </Box>
    ),
  },
  {
    summary: "What is a wallet?",
    details: (
      <Box>
        <Typography gutterBottom>
          A digital wallet is a software application that allows you to securely
          store and manage your cryptocurrency and digital collectibles (also
          known as NFTs).
        </Typography>
        <Typography gutterBottom>
          We recommend{" "}
          <Link href="https://typhonwallet.io" target="_blank">
            Typhon
          </Link>{" "}
          for ease of use and security.
        </Typography>
      </Box>
    ),
  },
  {
    summary: "How do I access my TURF?",
    details: (
      <Box>
        <Typography gutterBottom>
          You can access it through your digital wallet where you are able to
          view and download the embedded 2D high quality art file, as well as
          access a 3D interactive experience of your TURF map.
        </Typography>
      </Box>
    ),
  },
  {
    summary: "What is an NFT?",
    details: (
      <Box>
        <Typography gutterBottom>
          An NFT is a non-fungible token, a unique digital asset that uses
          blockchain technology to verify ownership and authenticity.
        </Typography>
        <Typography gutterBottom>
          TURF leverages NFTs as digital collectibles to store stunning
          cartographic art. When you purchase a TURF, you become the sole owner
          of that particular artwork, making it a one-of-a-kind item.
        </Typography>
      </Box>
    ),
  },

  {
    summary: "What is a TURF token?",
    details: (
      <Box>
        <Typography gutterBottom>
          You can use your TURF token to claim a free print from the TURF store.
        </Typography>
      </Box>
    ),
  },
  {
    summary: "How can I order a print for my TURF?",
    details: (
      <Box>
        <Typography gutterBottom>
          After purchasing a TURF, you can order a physical print of your map
          through the TURF website.
        </Typography>
      </Box>
    ),
  },
  {
    summary: "What are the dimensions of the print?",
    details: (
      <Box>
        <Typography gutterBottom>
          The TURF prints are 40x60cm (16x24") in size.
        </Typography>
      </Box>
    ),
  },
  {
    summary: "What are the TURF prints printed on?",
    details: (
      <Box>
        <Typography gutterBottom>
          We use museum grade fine art paper. This ensures that your TURF map
          looks beautiful and lasts for years to come. The print size is perfect
          for displaying your TURF map in your home or office and showing off
          your unique corner of the world.
        </Typography>
      </Box>
    ),
  },
  {
    summary: "How long will my print take to arrive?",
    details: (
      <Box>
        <Typography gutterBottom>
          We ship worldwide! Most prints typically take 7-10 business days to
          arrive but could take a few weeks depending on location.
        </Typography>
      </Box>
    ),
  },

  // {
  //   summary: "What is a temporary wallet?",
  //   details: (
  //     <Box>
  //       <Typography gutterBottom>
  //         When you make a purchase with a credit card, you'll receive a
  //         temporary digital wallet that holds your TURF NFT. While this is a
  //         convenient way to store your NFT, we highly recommend moving it to a
  //         more secure wallet that you create.
  //       </Typography>
  //       <Typography gutterBottom>
  //         This will ensure that you have full control over your NFT. Creating
  //         your own wallet is easy and can be done through a number of different
  //         wallet providers, we recommend{" "}
  //         <Link href="https://typhonwallet.io" target="_blank">
  //           Typhon
  //         </Link>
  //         . If you need some help, we created a{" "}
  //         <Link
  //           href="https://turfnft.com/?faq=I+purchased+a+TURF+using+a+credit+card%2C+how+do+I+secure+my+NFT%3F"
  //           target="_blank"
  //         >
  //           step by step guide
  //         </Link>
  //         .
  //       </Typography>
  //       <Typography gutterBottom>
  //         Once you have a secure wallet, you can transfer your TURF NFT from
  //         your temporary digital wallet to your new wallet.
  //       </Typography>
  //     </Box>
  //   ),
  // },
  // {
  //   summary: "I purchased a TURF using a credit card, how do I secure my NFT?",
  //   details: (
  //     <Box>
  //       <p>
  //         When you purchase a TURF using a credit card, we generate a temporary
  //         wallet account that can be accessed by using the “seed phrase” shared
  //         with you via email. Please access this wallet account using Typhon by
  //         following the steps in the purchase email.
  //       </p>

  //       <p>
  //         As the seed phrase words arrive via email it may be susceptible to
  //         security breaches! Therefore it can be risky to use the Typhon
  //         temporary wallet for long term storage. Follow this guide to secure
  //         your TURF NFT.
  //       </p>
  //       <Box sx={{ textAlign: "center" }}>
  //         <img
  //           src={`${process.env.PUBLIC_URL}/temporary_wallet.png`}
  //           alt="temporary wallet"
  //           style={{ maxWidth: 600 }}
  //         />
  //       </Box>
  //       <ol>
  //         <li>Visit Typhon and click “Download Extension”</li>
  //         <li>Click “Create Wallet” and complete the setup</li>
  //         <li>
  //           This is going to be your new secure wallet so keep those seed word
  //           phrases safe!{" "}
  //         </li>
  //         We have no ability to recover lost keys.
  //         <li>
  //           Done! You are now the owner of a secure wallet. Open your secure
  //           wallet by clicking on the blue Typhon logo in the extensions list.
  //         </li>
  //         <li>
  //           Now we are going to visit the web interface for Typhon so we can
  //           access your temporary wallet account provided in the purchase
  //           email.. Visit Typhon but this time click “Access Typhon Wallet”
  //         </li>
  //         <li>
  //           In your TURF purchase email, please find the seed word phrase and
  //           type it in here.
  //         </li>
  //         <li>Unlock wallet!</li>
  //         <li>
  //           Click “assets” in the left hand navigation, select your TURF NFT and
  //           click send!
  //         </li>
  //         <li>
  //           Open the Typhon Wallet Extension (your new secure wallet) and click
  //           “Receive”.
  //         </li>
  //         <li>
  //           Copy the address and paste it into the Typhon web page for sending
  //           the NFT.{" "}
  //         </li>
  //         <li>Click send!</li>
  //       </ol>
  //       <p>
  //         Congratulations, you have now moved your TURF from the insecure
  //         temporary wallet to your new secure permanent wallet at Typhon!! This
  //         can be a lot of information to digest. If you run into any problems or
  //         require any assistance please reach out to hello@turfnft.com or join
  //         our discord server (discord.com/invite/H5EjT4jdWj).
  //       </p>
  //     </Box>
  //   ),
  // },
  {
    summary: "Can I change the name of my TURF?",
    details: (
      <Box>
        <Typography gutterBottom>
          Yes, you can use the upgrade tool to rename your TURF. You can choose
          from a list of predefined option, or fully customize the text shown on
          your TURF. Please note that in the case of a custom name, the 3d
          version of your TURF will show a small "personalized" tag.
        </Typography>
      </Box>
    ),
  },
  {
    summary:
      "Why do I need to share my identity to be able to purchase a TURF?",
    details: (
      <Box>
        <Typography gutterBottom>
          We take our legal and regulatory obligations seriously. One of those
          obligations is to comply with Know Your Customer (KYC) and Anti-Money
          Laundering (AML) laws. By verifying your identity, we ensure that we
          are not enabling any illegal activity.
        </Typography>
        <Typography gutterBottom>
          Your personal information is kept secure and confidential and will
          only be used for compliance purposes.
        </Typography>
      </Box>
    ),
  },
  {
    summary: "What is the TURF policy ID?",
    details: (
      <Box>
        <Typography gutterBottom>
          <Link
            href="https://cardanoscan.io/tokenPolicy/08745cbfeed4d42985b9fb6accd955514e21d9425e6746268c46360c"
            target="_blank"
          >
            08745cbfeed4d42985b9fb6accd955514e21d9425e6746268c46360c
          </Link>
        </Typography>
      </Box>
    ),
  },
];

const FAQItem = ({
  summary,
  details,
  expanded,
  onChange,
}: {
  summary: string | ReactElement;
  details: string | ReactElement;
  expanded: boolean;
  onChange: (e: SyntheticEvent, isExpanded: boolean) => void;
}) => (
  <Accordion
    sx={{
      boxShadow: "none",
      borderTop: (theme) => `1px solid ${theme.palette.divider}`,
      ":before": { backgroundColor: "transparent" },
    }}
    disableGutters
    expanded={expanded}
    onChange={onChange}
  >
    <AccordionSummary
      expandIcon={<ExpandMore />}
      sx={{
        typography: "h6",
        px: 0,
        my: 1.5,
        "&.Mui-expanded": { fontWeight: 700 },
      }}
      id={summary.toString()}
    >
      {summary}
    </AccordionSummary>
    <AccordionDetails sx={{ px: 0, color: "GrayText" }}>
      {details}
    </AccordionDetails>
  </Accordion>
);

export default function FAQ({ sx }: { sx?: SxProps }) {
  const [search, setSearch] = useSearchParams();
  const [panel, setPanel] = useState(search.get("faq") || "");

  return (
    <Box
      sx={{
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        ...sx,
      }}
    >
      {items.map((item) => (
        <FAQItem
          key={item.summary}
          summary={item.summary}
          details={item.details}
          expanded={item.summary === panel}
          onChange={(e, isExpanded) => {
            setPanel(isExpanded ? item.summary : "");
            search.set("faq", item.summary);
            setSearch(search);
          }}
        />
      ))}
    </Box>
  );
}
