import { Box, Button, Typography } from "@mui/material";
import { hexToUtf8 } from "lucid-cardano";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CS22_POLICY_ID,
  POLICY_ID,
  PRINT_CLAIM_TOKEN_UNIT,
  PRINT_PRICE_EUR,
  STORAGE_BUCKET,
} from "../../config";
import { getPrintOrder } from "../../my-api";
import { useUiStore } from "../../stores/uiStore";
import { useWalletStore } from "../../stores/walletStore";

export default function AssetList({
  onSelect,
}: {
  onSelect: ({
    assetId,
    policyId,
    freePrint,
  }: {
    assetId: string;
    policyId: string;
    freePrint: boolean;
  }) => void;
}) {
  const exchangeRates = useUiStore((state) => state.exchangeRates);
  const adaRate = exchangeRates.find((er) => er.target === "ADA")?.value || 1;

  const lucid = useWalletStore((state) => state.lucid);
  const assetsInWallet = useWalletStore((state) => state.assetsInWallet);
  const [freePrints, setFreePrints] = useState<string[]>([]);
  const [availableAssets, setAvailableAssets] = useState<
    { decoded: string; asset: string; policyId: string }[]
  >([]);

  const navigate = useNavigate();

  useEffect(() => {
    setAvailableAssets(
      Object.keys(assetsInWallet)
        .filter(
          (asset) =>
            asset.startsWith(CS22_POLICY_ID) || asset.startsWith(POLICY_ID)
        )
        .map((asset) => ({
          asset,
          policyId: asset.includes(POLICY_ID) ? POLICY_ID : CS22_POLICY_ID,
          decoded: hexToUtf8(
            asset.replace(POLICY_ID, "").replace(CS22_POLICY_ID, "")
          ),
        }))
    );
  }, [assetsInWallet]);

  useEffect(() => {
    Promise.all(
      availableAssets.map((asset) => getPrintOrder(asset.decoded))
    ).then((printOrders) => {
      setFreePrints(
        availableAssets
          .filter((asset) =>
            printOrders
              .filter((order) => order?.printOrder.status === "AVAILABLE")
              .map((order) => order?.id)
              .includes(asset.decoded)
          )
          .map((asset) => asset.decoded)
      );
    });
  }, [availableAssets]);

  const printClaimTokens = assetsInWallet[PRINT_CLAIM_TOKEN_UNIT];

  if (!lucid) {
    navigate("/claim-your-print");
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant={"h5"}>Choose the NFT you want to print</Typography>
      {printClaimTokens > 0 && (
        <Typography variant="h6" textAlign="center" sx={{ marginBottom: 2 }}>
          You hold {Number(printClaimTokens)} TURF tokens to claim free prints.
        </Typography>
      )}
      {availableAssets.length === 0 && (
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="body1" sx={{ marginTop: 4 }} gutterBottom>
            We didn't find any TURFs in this wallet 😓
          </Typography>
          <Button onClick={() => navigate("/claim-your-print/select-wallet")}>
            Select different wallet
          </Button>
        </Box>
      )}
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {availableAssets.map((asset) => {
          let assetId = asset.decoded;
          assetId = assetId.replace("TURF", "");
          const locationName = assetId.replace(/\d*/g, "");
          const locationCode =
            assetId.substring(0, 8) + "+" + assetId.substring(8, 10);
          const image =
            asset.policyId === CS22_POLICY_ID
              ? `https://storage.googleapis.com/mapmaker-330220.appspot.com/${locationName}.png`
              : `${STORAGE_BUCKET}/${locationCode}_art.png`;

          return (
            <Box
              onClick={() => {
                onSelect({
                  assetId: asset.decoded,
                  policyId: asset.policyId,
                  freePrint: freePrints.includes(asset.decoded),
                });
                navigate("/claim-your-print/shipping");
              }}
              key={asset.asset}
              sx={{
                margin: "1em",
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Box sx={{ width: "100px", position: "relative" }}>
                <img
                  src={image}
                  style={{ boxShadow: "4px 5px 8px rgba(0,0,0,0.3)" }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: -10,
                    right: -20,
                    background: "gold",
                    borderRadius: "50%",
                    height: "60px",
                    width: "60px",
                    lineHeight: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    fontSize: "0.7em",
                  }}
                >
                  {freePrints.includes(asset.decoded)
                    ? "Free!"
                    : `${Math.round(adaRate * PRINT_PRICE_EUR)} ADA`}
                </Box>
              </Box>
              <Typography variant="body1">
                {asset.decoded.replace("TURF", "")}
              </Typography>
            </Box>
          );
        })}
      </div>
    </Box>
  );
}
