import { Close, ZoomIn } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { useUiStore } from "../../stores/uiStore";
import { useWalletStore } from "../../stores/walletStore";

export default function TurfsList() {
  const turfsInWallet = useWalletStore((state) => state.turfsInWallet);
  const [setMyTurfsContent, setSidebar] = useUiStore((state) => [
    state.setMyTurfsContent,
    state.setSidebar,
  ]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          mb: 2,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" fontWeight={700}>
          My TURFs
        </Typography>
        <IconButton onClick={() => setSidebar("")}>
          <Close />
        </IconButton>
      </Box>

      {turfsInWallet.length === 0 && (
        <Typography>You don't own any TURFs yet :(</Typography>
      )}

      {turfsInWallet.map((turf) => (
        <Box
          key={turf.assetId}
          sx={{
            backgroundColor: "background.default",
            borderRadius: 3,
            display: "flex",
            p: 2,
            mb: 2,
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "50%",
              boxShadow: 5,
              lineHeight: 0,
              cursor: "pointer",
              position: "relative",
            }}
            onClick={() =>
              setMyTurfsContent("DETAIL", { assetId: turf.assetId })
            }
          >
            <ZoomIn
              sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
                zIndex: 1,
                m: 1,
                color: (theme) => theme.palette.common.black,
              }}
            />
            <Box sx={{ "&:hover": { opacity: 0.6 }, transition: "all 0.2s" }}>
              <img src={`${turf.artThumbUrl}`} alt={turf.placeName} />
            </Box>
          </Box>
        </Box>
      ))}
    </>
  );
}
