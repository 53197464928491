import { latLng } from "leaflet";
import "leaflet-geosearch/dist/geosearch.css";
import { useEffect } from "react";
import { TileLayer, useMapEvents } from "react-leaflet";
import { useSearchParams } from "react-router-dom";
import { MAP_CENTER } from "../config";
import { useUiStore } from "../stores/uiStore";
import { isTouchDevice } from "../utils";
import CurrentTurfs from "./CurrentTurfs";
import MergeMapGrid from "./MergeMapGrid";
import MintedTurfs from "./MintedTurfs";
import OpenLocationCode from "./OpenLocationCode";
import PrintMapGrid from "./PrintMapGrid";

export default function Map() {
  const [search, setSearch] = useSearchParams(window.location.search);
  const zoom = parseInt(search.get("zoom") || "12");
  const locationCode = search.get("locationCode") || MAP_CENTER;
  const mapMode = useUiStore((state) => state.mapMode);

  const map = useMapEvents({
    moveend: (e) => {
      const center = map.getCenter();
      const locationCode = OpenLocationCode.encode(center.lat, center.lng);
      search.set("zoom", map.getZoom().toString());
      search.set("locationCode", locationCode);
      setSearch(search);
    },
  });

  useEffect(() => {
    const location = OpenLocationCode.decode(locationCode);
    map.setView(
      latLng(location.latitudeCenter, location.longitudeCenter),
      zoom
    );
  }, [locationCode, zoom, map]);

  useEffect(() => {
    if (isTouchDevice()) {
      map.zoomControl.remove();
    } else {
      map.zoomControl.setPosition("bottomright");
    }
  }, [map.zoomControl]);

  return (
    <>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
      />
      {mapMode === "MERGE" && (
        <>
          <MergeMapGrid />
          <CurrentTurfs />
        </>
      )}
      {mapMode === "DEFAULT" && (
        <>
          {/* <MapGrid /> */}
          <MintedTurfs />
        </>
      )}
      {mapMode === "PRINT" && (
        <>
          <PrintMapGrid />
          <MintedTurfs opacity={0.3} />
        </>
      )}
    </>
  );
}
