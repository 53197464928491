import { getDocs, query, where } from "firebase/firestore";
import { xor } from "lodash";
import { Blockfrost, Lucid, SignedMessage, utf8ToHex } from "lucid-cardano";
import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";
import { Turf } from "../../definitions";
import {
  BLOCKFROST_NETWORK,
  BLOCKFROST_PROJECT_ID,
  BLOCKFROST_URL,
} from "../config";
import { turfsCol } from "../firebase.config";
import { getTurfAssetIdsFromAssets } from "../utils";

enum wallets {
  "nami" = "Nami",
  "yoroi" = "Yoroi",
  "eternl" = "Eternl",
  "flint" = "Flint",
  "typhoncip30" = "Typhon",
  "nufi" = "Nufi",
  "gerowallet" = "Gero",
  "lace" = "Lace",
}

interface WalletStore {
  lucid: Lucid | null;
  walletName: keyof typeof wallets | "";
  setWalletName: (walletName: keyof typeof wallets) => void;
  enableWallet: (
    w: keyof typeof wallets,
    onSuccess?: () => void
  ) => Promise<void>;
  assetsInWallet: { [unit: string]: bigint };
  selectedAssetIds: string[];
  toggleSelectedAssetId: (assetIds: string) => void;
  resetSelectedAssetIds: () => void;
  walletList: {
    [key in keyof typeof wallets]: { label: string; icon: string };
  };
  disconnect: () => void;
  turfsInWallet: (Turf & { id: string })[];
  signMessage: (
    payload: string
  ) => Promise<{ signedMessage: SignedMessage; address: string }>;
}

export const useWalletStore = create<WalletStore>()(
  subscribeWithSelector((set, get) => {
    const setWalletName = (walletName: keyof typeof wallets | "") => {
      set({ walletName });
    };

    return {
      walletName: "",
      setWalletName,
      lucid: null,
      enableWallet: async (walletName, onSuccess) => {
        // should move
        const lucid = await Lucid.new(
          new Blockfrost(BLOCKFROST_URL, BLOCKFROST_PROJECT_ID),
          BLOCKFROST_NETWORK
        );
        const browserWallet = window.cardano[walletName];
        const api = await browserWallet.enable();
        lucid.selectWallet(api);

        const utxos = await lucid.wallet.getUtxos();
        const assetsInWallet: { [unit: string]: bigint } = {};

        utxos.forEach((utxo) => {
          Object.keys(utxo.assets).forEach((unit) => {
            assetsInWallet[unit] = assetsInWallet[unit]
              ? assetsInWallet[unit] + utxo.assets[unit]
              : utxo.assets[unit];
          });
        });

        setWalletName(walletName);
        onSuccess && onSuccess();
        set((state) => ({
          lucid,
          assetsInWallet,
        }));
      },
      disconnect: () => {
        setWalletName("");
        set((state) => ({
          lucid: null,
        }));
      },
      assetsInWallet: {},
      selectedAssetIds: [],
      toggleSelectedAssetId: (turfAssetId) => {
        const { selectedAssetIds } = get();
        set((state) => ({
          selectedAssetIds: xor(selectedAssetIds, [turfAssetId]),
        }));
      },
      resetSelectedAssetIds: () => set({ selectedAssetIds: [] }),
      walletList: {
        nami: { label: "Nami", icon: "nami.svg" },
        yoroi: { label: "Yoroi", icon: "yoroi.svg" },
        eternl: { label: "Eternl", icon: "eternl.webp" },
        flint: { label: "Flint", icon: "flint.svg" },
        typhoncip30: { label: "Typhon", icon: "typhon.svg" },
        nufi: { label: "NuFi", icon: "nufi.svg" },
        gerowallet: { label: "GeroWallet", icon: "gerowallet.svg" },
        lace: { label: "Lace", icon: "lace.svg" },
      },
      turfsInWallet: [],
      signMessage: async (payload: string) => {
        const { lucid } = get();
        const address = await lucid?.wallet.address();

        if (!address) {
          throw new Error("No address found");
        }

        const signedMessage = await lucid
          ?.newMessage(address, utf8ToHex(payload))
          .sign();

        if (!signedMessage) {
          throw new Error("Could not sign message");
        }

        return { signedMessage, address };
      },
    };
  })
);

useWalletStore.subscribe(
  (state) => state.assetsInWallet,
  async (assetsInWallet) => {
    const assetIds = getTurfAssetIdsFromAssets(Object.keys(assetsInWallet));
    let turfsInWallet: (Turf & { id: string })[] = [];

    for (const assetId of assetIds) {
      const turfs = await getDocs(
        query(
          turfsCol,
          where("assetId", "==", assetId.replace("TURF", "")),
          where("mintedDate", "!=", null),
          where("burnedDate", "==", null)
        )
      );

      turfsInWallet = [
        ...turfsInWallet,
        ...turfs.docs.map((t) => ({ ...t.data(), id: t.id })),
      ];
    }

    useWalletStore.setState({ turfsInWallet });
  }
);
