import { doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { chunk, memoize } from "lodash";
import {
  GridSquare,
  RecipientDetails,
  Reservation,
  Turf,
} from "../definitions";
import firebaseTools, {
  printOrdersCol,
  reservationsCol,
  turfsCol,
} from "./firebase.config";

export const getGridFeatures = memoize(async (bounds: string) => {
  const gridJson = await getSquares({ bbox: bounds });
  return gridJson.data.map((square) => JSON.parse(square) as GridSquare);
});

const getSquares = httpsCallable<{ bbox: string }, string[]>(
  firebaseTools.functions,
  "getSquares"
);

export const updateArtThumb = httpsCallable<
  { turfId: string; addressKey: string; shuffleColors?: boolean },
  string
>(firebaseTools.functions, "updateArtThumb");

export const createMergeReservation = httpsCallable<
  { assetIds: string[] },
  string
>(firebaseTools.functions, "createMergeReservation");

export const addTurfToReservation = httpsCallable<
  { reservationId?: string; desiredSquares: string[] },
  { reservationId: string; turfId: string; freePrints: number }
>(firebaseTools.functions, "addTurfToReservation");

export const changeProductQuantity = httpsCallable<
  {
    reservationId?: string;
    turfId?: string;
    quantity: number;
    squares?: string[];
  },
  { reservationId: string; turfId: string }
>(firebaseTools.functions, "changeProductQuantity");

export const updatePrintOrderArt = httpsCallable<
  {
    turfId: string;
    productId: string;
    placeName: string;
    shuffleColors: boolean;
  },
  void
>(firebaseTools.functions, "updatePrintOrderArt");

export const removeTurfFromReservation = httpsCallable<
  { turfId: string },
  void
>(firebaseTools.functions, "removeTurfFromReservation");

export const confirmPlaceNames = httpsCallable<
  {
    reservationId: string;
    addressKeys: { [key: string]: string };
  },
  string
>(firebaseTools.functions, "confirmPlaceNames");

export const getCheckoutUrl = httpsCallable<{ reservationId: string }, string>(
  firebaseTools.functions,
  "getCheckoutUrl"
);

export const verifyBeneficiaryWallet = httpsCallable<
  {
    address: string;
    payload: string;
    signedMessage: { signature: string; key: string };
  },
  string
>(firebaseTools.functions, "verifyBeneficiaryWallet");

export const cancelReservation = httpsCallable<
  { reservationId: string },
  string
>(firebaseTools.functions, "cancelReservation");

export const claimFreeSummitPrint = httpsCallable<
  {
    address: string;
    assetId: string;
    txHash: string;
    recipientDetails: Partial<RecipientDetails>;
  },
  {
    assetId: string;
    success: boolean;
  }
>(firebaseTools.functions, "claimFreeSummitPrint");

export const addRecipient = httpsCallable<
  {
    address: string;
    assetId: string;
    policyId: string;
    txHash: string;
    recipientDetails: Partial<RecipientDetails>;
  },
  {
    assetId: string;
    success: boolean;
  }
>(firebaseTools.functions, "addRecipient");

export const addRecipientNew = httpsCallable<
  {
    recipientDetails: Partial<RecipientDetails>;
    reservationId: string;
    status: "AWAITING_CONFIRMATION" | "KYC";
  },
  {
    recipient: string;
    success: boolean;
  }
>(firebaseTools.functions, "addRecipientNew");

export const getBeneficiaryContractUrl = httpsCallable<
  {
    email: string;
    fullName: string;
    beneficiaryId: string;
  },
  {
    contractUrl: string;
  }
>(firebaseTools.functions, "getBeneficiaryContractUrl");

export const selectChain = httpsCallable<
  { reservationId: string; chain: "CARDANO" | "POLYGON" },
  {
    success: boolean;
  }
>(firebaseTools.functions, "selectChain");

export const createRandomReservation = httpsCallable<
  { placeName: string; quantity: number },
  Reservation & {
    id: string;
  }
>(firebaseTools.functions, "createRandomReservation");

export async function getReservation(reservationId: string) {
  const reservationRef = doc(reservationsCol, reservationId);
  const reservation = await getDoc(reservationRef);
  return { id: reservationId, reservation: reservation.data() };
}

export async function getPrintOrder(printOrderId: string) {
  const printOrderRef = doc(printOrdersCol, printOrderId);
  const printOrder = await getDoc(printOrderRef);
  if (printOrder.exists()) {
    return { id: printOrderId, printOrder: printOrder.data() };
  } else {
    return null;
  }
}

export async function getPrintOrders() {
  const printOrders = await getDocs(printOrdersCol);

  return printOrders.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
}

export async function getMintedTurfs() {
  const q = query(
    turfsCol,
    where("mintedDate", "!=", null),
    where("burnedDate", "==", null)
  );
  const res = await getDocs(q);
  return res.docs.map((doc) => doc.data());
}

export async function getTurfsById(ids: string[]) {
  // const q = query(turfsCol, where("mintedDate", "!=", null), where(''));

  const turfs = await Promise.all(ids.map((id) => getDoc(doc(turfsCol, id))));
  return turfs
    .filter((t) => t.exists)
    .map((doc) => ({ ...doc.data(), id: doc.id } as Turf & { id: string }));
}

export async function getTurfsByAssetIds(assetIds: string[]) {
  const chunks = chunk(assetIds, 10);
  const turfs: (Turf & { id: string })[] = [];
  for (let i = 0; i < chunks.length; i++) {
    const q = query(
      turfsCol,
      where("assetId", "in", chunks[i]),
      where("mintedDate", "!=", null)
    );
    const res = await getDocs(q);
    turfs.push(...res.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  }

  return turfs;
}
