import { Alert, Snackbar } from "@mui/material";
import { useEffect } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import "./App.css";
import { Basket, CheckoutSidebar, MapContainer } from "./components";
import ClaimPrint from "./components/ClaimPrint";
import InteractiveArtModal from "./components/InteractiveArtModal";
import MyTurfsSidebar from "./components/MyTurfsSidebar";
import NewsletterSignupModal from "./components/NewsletterSignupModal";
import RandomDropModal from "./components/RandomDropModal";
import Redirect from "./components/Redirect";
// import RoyaltiesModal from "./components/RoyaltiesModal";
// import RoyaltiesSidebar from "./components/RoyaltiesSidebar";
import SearchBar from "./components/SearchBar";
import Sidebar from "./components/Sidebar";
import TermsModal from "./components/TermsModal";
import WelcomeModal from "./components/WelcomeModal";
import { useReservationStore } from "./stores/reservationStore";
import { useUiStore } from "./stores/uiStore";

function App() {
  const [setReservationId] = useReservationStore((state) => [
    state.setReservationId,
  ]);
  const [
    error,
    setError,
    sidebar,
    setSidebar,
    setMapMode,
    setShowNewsletterSignup,
    setShowWelcomeScreen,
  ] = useUiStore((state) => [
    state.error,
    state.setError,
    state.sidebar,
    state.setSidebar,
    state.setMapMode,
    state.setShowNewsletterSignup,
    state.setShowWelcomeScreen,
  ]);

  const closeSidebar = () => setSidebar("");

  // Not sure if this is the best place for this logic, but it works
  useEffect(() => {
    const { search, pathname } = window.location;
    const params = new URLSearchParams(search);
    const reservationId = params.get("reservationId");
    if (reservationId) {
      setReservationId(reservationId);
      setSidebar("CHECKOUT");
    }

    if (pathname.startsWith("/prints") || pathname.startsWith("/posters")) {
      setMapMode("PRINT");
    }

    if (params.get("showSignup")) {
      setShowWelcomeScreen(false);
      setShowNewsletterSignup(true);
    }
    const faqPanel = params.get("faq");
    if (faqPanel) {
      setShowWelcomeScreen(true);
      setTimeout(
        () => document.getElementById(faqPanel)?.scrollIntoView(),
        150
      );
    }
  }, []);

  const mainElement = (
    <>
      <WelcomeModal />
      <NewsletterSignupModal />
      <SearchBar />
      <Basket />
      <MapContainer />
      <Outlet />
    </>
  );

  return (
    <>
      <Routes>
        <Route path="/posters/*" element={mainElement} />
        <Route path="/prints/*" element={mainElement} />
        <Route path="/claim-your-print/*" element={<ClaimPrint />} />
        <Route path=":locationCode" element={<Redirect />} />
        <Route path=":locationCode/:zoom" element={<Redirect />} />
        <Route path="/*" element={mainElement}>
          <Route
            path="interactive/:assetId"
            element={<InteractiveArtModal />}
          />
          <Route path="random-drop/:placeName" element={<RandomDropModal />} />
          <Route path="royalties" element={<Redirect />} />
          <Route path="terms-and-conditions" element={<TermsModal />} />
        </Route>

        <Route path="*" element={<Navigate to={`/`} replace />} />
      </Routes>

      <Sidebar
        element={<MyTurfsSidebar />}
        open={sidebar === "MY_TURFS"}
        onClose={closeSidebar}
      />
      <Sidebar
        element={<CheckoutSidebar />}
        open={sidebar === "CHECKOUT"}
        onClose={closeSidebar}
      />
      {/* <Sidebar
        element={<RoyaltiesSidebar />}
        open={sidebar === "ROYALTIES"}
        onClose={closeSidebar}
      /> */}

      <Snackbar
        open={Boolean(error)}
        autoHideDuration={3000}
        onClose={() => setError("")}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        sx={{ borderRadius: 8 }}
      >
        <Alert severity="error" style={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
}

export default App;
