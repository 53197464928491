import { Apps, Close, Devices, Map, Print } from "@mui/icons-material";
import Masonry from "@mui/lab/Masonry";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Link,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PrimeLocation } from "../../definitions";
import { primeLocationsCol } from "../firebase.config";
import { useUiStore } from "../stores/uiStore";
import FAQ from "./FAQ";
import NewsletterForm from "./NewsletterForm";

const PrimeLocationCaroussel = ({
  primeLocations,
}: {
  primeLocations: PrimeLocation[];
}) => {
  const [locationName, setLocationName] = useState("Paris");
  useEffect(() => {
    const timer = setInterval(() => {
      setLocationName(
        primeLocations[Math.floor(Math.random() * primeLocations.length)]
          ?.placeName
      );
    }, 300);

    return () => {
      clearInterval(timer);
    };
  }, [primeLocations]);
  return (
    <Typography variant="h2" sx={{ my: 2 }}>
      {locationName}
    </Typography>
  );
};

export default function WelcomeModal() {
  const [search, setSearch] = useSearchParams();
  const [open, setOpen, setMapMode, setShowNewsletterSignup] = useUiStore(
    (state) => [
      state.showWelcomeScreen,
      state.setShowWelcomeScreen,
      state.setMapMode,
      state.setShowNewsletterSignup,
    ]
  );

  const navigate = useNavigate();

  const [primeLocations, setPrimeLocations] = useState<PrimeLocation[]>([]);

  useEffect(() => {
    const panel = search.get("panel");
    panel &&
      setTimeout(
        () =>
          document
            .getElementById(panel)
            ?.scrollIntoView({ behavior: "smooth" }),
        0
      );
    return onSnapshot(primeLocationsCol, (snapshot) => {
      setPrimeLocations(snapshot.docs.map((p) => p.data()));
    });
  }, []);

  const uspStyle = {
    borderRadius: 2,
    padding: 3,
    boxShadow: "18px 15px 30px rgba(36, 56, 56, 0.1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const gallery = [
    {
      src: `${process.env.PUBLIC_URL}/utrecht.jpg`,
      alt: "Big Utrecht TURF",
    },
    { src: `${process.env.PUBLIC_URL}/paris.gif`, alt: "Paris interactive" },
    {
      src: `${process.env.PUBLIC_URL}/wedding.jpg`,
      alt: "Latina TURF wedding",
    },
    {
      src: "https://images.squarespace-cdn.com/content/v1/61fc236b241d0b679cd21290/9d172b99-bb5c-4f38-a568-8a5d50b163da/20220410_125826_2.jpg?format=1000w",
      alt: "Wijk Bij Duurstede TURF",
    },
    {
      src: "https://images.squarespace-cdn.com/content/v1/61fc236b241d0b679cd21290/745c555c-dbc4-42ce-b4f4-fb5ca479fb3c/Baltimore+-+huge.jpeg?format=1000w",
      alt: "Baltimore TURF",
    },
    {
      src: "https://images.squarespace-cdn.com/content/v1/61fc236b241d0b679cd21290/a0faf744-8e68-4742-a280-de189bbdc53a/FUGTGvrWYAI_zM9.jpeg?format=1000w",
      alt: "Charles Hoskinson showing his TURF of Boulder",
    },
    {
      src: "https://images.squarespace-cdn.com/content/v1/61fc236b241d0b679cd21290/71ff7b9a-570c-4932-a805-7658c53f2e46/WhatsApp+Image+2022-03-23+at+11.05.58+AM+%281%29.jpeg?format=1000w",
      alt: "Rotterdam TURF",
    },
    {
      src: `${process.env.PUBLIC_URL}/granada.jpg`,
      alt: "Granada TURF",
    },
    {
      src: `${process.env.PUBLIC_URL}/MXT.jpg`,
      alt: "MXT special edition TURF",
    },
  ];

  const CCLogos = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        width: "100%",
        mb: 8,
      }}
    >
      <Typography
        variant="caption"
        sx={{
          flexBasis: { xs: "100%", sm: "auto" },
          textAlign: "center",
          mb: { xs: 2, sm: 0 },
          mr: { xs: 0, sm: 2 },
        }}
      >
        1500+ pieces sold through
      </Typography>
      <Box>
        {/* <img src={`${process.env.PUBLIC_URL}/visa.svg`} width="50" />
        &nbsp;&nbsp;&nbsp;
        <img src={`${process.env.PUBLIC_URL}/mastercard.svg`} width="30" /> */}
        {/* &nbsp;&nbsp;&nbsp;
        <img
          src={`${process.env.PUBLIC_URL}/full-polygon-logo.svg`}
          width="90"
        /> */}
        {/* &nbsp;&nbsp;&nbsp; */}
        <img src={`${process.env.PUBLIC_URL}/cardano.svg`} width="100" />
      </Box>
    </Box>
  );

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
        search.delete("faq");
        setSearch(search);
      }}
      maxWidth="lg"
      PaperProps={{
        sx: {
          m: { xs: 1, sm: 2 },
          p: 4,
          maxHeight: (theme) => ({
            xs: `calc(100% - ${theme.spacing(2)})`,
            sm: `calc(100% - ${theme.spacing(4)})`,
          }),
        },
      }}
      keepMounted={false}
    >
      <IconButton
        sx={{ position: "absolute", right: 0, mr: 2 }}
        onClick={() => setOpen(false)}
      >
        <Close />
      </IconButton>
      <Typography
        variant="h2"
        sx={{
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          textAlign: "center",
          mb: 8,
        }}
        className="gradient"
      >
        TURF
      </Typography>
      <Grid
        container
        spacing={4}
        sx={{
          // marginBottom: 6,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12} sx={{ textAlign: "center", mb: 2 }}>
          <Typography
            variant="h3"
            // fontWeight={100}
            fontFamily="Roboto"
            sx={{ mb: 1 }}
          >
            Map art you truly own
          </Typography>
          <Typography
            variant="h6"
            // fontWeight={300}
            fontFamily="Roboto"
          >
            Every square of earth is available only once
          </Typography>
        </Grid>
        <Grid item xs={12} lg={8} sx={{ textAlign: "center" }}>
          {/* <Box
            sx={{
              color: (t) => t.palette.common.white,
              borderRadius: 3,
              padding: 4,
              textAlign: "center",
            }}
            className="gradient"
          >
            <ThemeProvider theme={darkTheme}>
              <Typography variant="h5" fontWeight="400">
                The 150 most 🌟 visited cities 🌟 minting now:
              </Typography>
              <PrimeLocationCaroussel primeLocations={primeLocations} />
              <Typography sx={{ mb: 4 }}>
                Choose your favorite city, mint and get a random square within
                it!
              </Typography>
              <Button
                variant="contained"
                size="large"
                onClick={() => {
                  setOpen(false);
                  navigate(`random-drop/New York City`);
                }}
                sx={{
                  // background: (theme) => theme.palette.common.white,
                  padding: "0.7em 1.5em",
                  mb: 1,
                }}
              >
                Choose a city to mint
              </Button>
              <Typography sx={{ fontStyle: "italic" }}>
                {primeLocations.reduce(
                  (prev, curr) => (prev += curr.availableSquares),
                  0
                )}
                /9000 squares available
              </Typography>
              <br />
              <Typography variant="caption" color="white">
                Mint prices range from €30 to €75, depending on the % of the
                city that has been minted{" "}
                <InfoRounded
                  fontSize="inherit"
                  onClick={() => navigate("/random-drop/New York City")}
                  sx={{ cursor: "pointer" }}
                />
              </Typography>
            </ThemeProvider>
          </Box> */}
          <Button
            variant="text"
            className="gradient"
            size="large"
            onClick={() => setOpen(false)}
            sx={{
              color: (theme) => theme.palette.common.white,
              padding: "0.7em 1.5em",
              mb: 1,
            }}
          >
            Find your TURF NFT
          </Button>
          <br />
          <Link
            component="button"
            onClick={() => {
              setMapMode("PRINT");
              setOpen(false);
            }}
            sx={{ mb: 2 }}
          >
            Or get a TURF fine art print
          </Link>

          {/* <Box
            sx={{
              // color: (t) => t.palette.common.white,
              borderRadius: 3,
              padding: 2,
              textAlign: "center",
              // backgroundColor: (t) => t.palette.grey[300],
              border: (t) => `1px solid ${t.palette.primary.main}`,
            }}
            // className="gradient"
          >
            <Typography variant="body1" fontWeight="400" sx={{ mb: 2 }}>
              The 150 most 🌟 visited cities 🌟 minting August 24th, 7PM UTC!
            </Typography>

            <Button
              variant="outlined"
              // size="large"
              onClick={() => {
                setOpen(false);
                setShowNewsletterSignup(true);
              }}
              sx={
                {
                  // background: (theme) => theme.palette.common.white,
                  // padding: "0.7em 1.5em",
                  // mb: 1,
                }
              }
            >
              More info
            </Button>
          </Box> */}
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <CCLogos />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginBottom: 8 }}>
        <Grid item xs={12} sm={6}>
          <Paper sx={uspStyle} elevation={0}>
            <Typography variant="body1" sx={{ width: "60%", marginRight: 2 }}>
              Own the artwork{" "}
              <Box
                sx={{ display: { xs: "inline", md: "block" } }}
                component="span"
              />
              based on your favorite location
            </Typography>
            <Avatar sx={{ background: "#EFEDE7", width: 60, height: 60 }}>
              <Map sx={{ color: "#CFCABE" }} fontSize="large" />
            </Avatar>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Paper sx={uspStyle} elevation={0}>
            <Typography variant="body1" sx={{ width: "60%", marginRight: 2 }}>
              Order prints for yourself{" "}
              <Box
                sx={{ display: { xs: "inline", sm: "block" } }}
                component="span"
              />
              or to sell
            </Typography>
            <Avatar sx={{ background: "#EFEDE7", width: 60, height: 60 }}>
              <Print sx={{ color: "#CFCABE" }} fontSize="large" />
            </Avatar>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper sx={uspStyle} elevation={0}>
            <Typography variant="body1" sx={{ width: "60%", marginRight: 2 }}>
              Display digital interactive art{" "}
              <Box
                sx={{ display: { xs: "inline", sm: "block" } }}
                component="span"
              />
              on any screen
            </Typography>
            <Avatar sx={{ background: "#EFEDE7", width: 60, height: 60 }}>
              <Devices sx={{ color: "#CFCABE" }} fontSize="large" />
            </Avatar>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper sx={uspStyle} elevation={0}>
            <Typography variant="body1" sx={{ width: "60%", marginRight: 2 }}>
              Get a free print with{" "}
              <Box
                sx={{ display: { xs: "inline", sm: "block" } }}
                component="span"
              />
              every 3x3 or larger TURF
            </Typography>
            <Avatar sx={{ background: "#EFEDE7", width: 60, height: 60 }}>
              <Apps sx={{ color: "#CFCABE" }} fontSize="large" />
            </Avatar>
          </Paper>
        </Grid>
      </Grid>

      <Typography
        variant="h4"
        fontWeight={100}
        fontFamily="Roboto"
        textAlign="center"
        sx={{ marginBottom: 4 }}
      >
        People love their TURF
      </Typography>
      <Masonry
        columns={{ xs: 1, sm: 2, md: 3 }}
        spacing={2}
        sx={{ marginBottom: 8 }}
      >
        {gallery.map((img) => (
          <Box
            key={img.alt}
            sx={{
              borderRadius: 2,
              lineHeight: 0,
              overflow: "hidden",
              boxShadow: "18px 15px 30px rgba(36, 56, 56, 0.1)",
            }}
          >
            <img {...img} />
          </Box>
        ))}
      </Masonry>
      <Typography
        variant="h4"
        fontWeight={100}
        fontFamily="Roboto"
        textAlign="center"
        sx={{ marginBottom: 4 }}
        id="FAQ"
      >
        Join our newsletter
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", mb: 8 }}>
        <NewsletterForm formId="1b07ebc4-1fc8-11ee-84c0-955d6ccc0666" />
      </Box>
      <Typography
        variant="h4"
        fontWeight={100}
        fontFamily="Roboto"
        textAlign="center"
        sx={{ marginBottom: 4 }}
        id="FAQ"
      >
        Frequently Asked Questions
      </Typography>
      <FAQ sx={{ mb: 8 }} />
      <Box
        sx={{
          overflow: "hidden",
          paddingBottom: "56.25%",
          position: "relative",
          height: 0,
          mb: 4,
        }}
      >
        <iframe
          style={{
            left: 0,
            top: 0,
            height: "100%",
            width: "100%",
            position: "absolute",
          }}
          width="560"
          height="315"
          src="https://www.youtube.com/embed/H8YG-rQg0L4"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          key="turf-video"
        ></iframe>
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="caption" component="div">
          as seen on:
        </Typography>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="center"
        >
          <Link href="https://nftdroops.com/" target="_blank">
            <img
              src={`${process.env.PUBLIC_URL}/nftdroops-logo-dark.png`}
              alt="nftdroops"
              width="100"
            />
          </Link>
          <Link href="https://nftcalendar.io" target="_blank">
            <img
              src={`${process.env.PUBLIC_URL}/nft_calendar.svg`}
              alt="nftcalendar"
              width="100"
            />
          </Link>
        </Stack>
      </Box>
    </Dialog>
  );
}
