import { InfoRounded, Twitter } from "@mui/icons-material";
import { Box, Button, Container, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import { MERGE_ADDRESS, PRINT_PRICE_EUR } from "../../config";
import { addRecipient, claimFreeSummitPrint } from "../../my-api";
import { useUiStore } from "../../stores/uiStore";
import { useWalletStore } from "../../stores/walletStore";
import {
  submitBuyPrintTransaction,
  submitClaimSummitPrintTransaction,
} from "../../utils";
import AssetList from "./AssetList";
import ClaimedPrints from "./ClaimedPrints";
import Form from "./Form";
import WalletList from "./WalletList";

export default function ClaimPrint() {
  const navigate = useNavigate();
  const lucid = useWalletStore((state) => state.lucid);
  const assetsInWallet = useWalletStore((state) => state.assetsInWallet);
  const exchangeRates = useUiStore((state) => state.exchangeRates);
  const adaRate = exchangeRates.find((er) => er.target === "ADA")?.value || 1;

  const [selectedAsset, setSelectedAsset] = useState<{
    assetId: string;
    policyId: string;
    freePrint: boolean;
  }>({ assetId: "", policyId: "", freePrint: false });
  const tweet = `I just ordered a print of my TURF NFT!%0a%0aGet your own now!%0a%0a`;

  return (
    <Container sx={{ marginTop: 6 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 8,
        }}
      >
        <Typography
          variant="h2"
          className="gradient"
          sx={{
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            textAlign: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            window.location.href = "/";
          }}
        >
          TURF
        </Typography>
        <img
          src={`${process.env.PUBLIC_URL}/cs22logo_alt_star_w.svg`}
          style={{ width: 180 }}
        />
      </Box>
      <Routes>
        <Route path={`claimed`} element={<ClaimedPrints />} />
        <Route path={`claimed/:id`} element={<ClaimedPrints />} />
        <Route path={`select-wallet`} element={<WalletList />} />
        <Route
          path="assets"
          element={<AssetList onSelect={setSelectedAsset} />}
        />
        <Route
          path={`shipping`}
          element={
            <Form
              selectedAsset={selectedAsset}
              onSubmit={async (recipientDetails) => {
                const address = await lucid!.wallet.address();
                const { assetId, freePrint, policyId } = selectedAsset;
                if (freePrint) {
                  const txHash = await submitClaimSummitPrintTransaction(
                    lucid!,
                    assetId
                  );
                  await claimFreeSummitPrint({
                    address,
                    assetId,
                    txHash,
                    recipientDetails,
                  });
                } else {
                  const txHash = await submitBuyPrintTransaction(
                    lucid!,
                    assetsInWallet,
                    MERGE_ADDRESS,
                    parseInt(recipientDetails.quantity || "1"),
                    Math.floor(PRINT_PRICE_EUR * adaRate * 1000000)
                  );
                  await addRecipient({
                    address,
                    assetId,
                    policyId,
                    txHash,
                    recipientDetails,
                  });
                }

                navigate("/claim-your-print/success");
              }}
            />
          }
        />
        <Route
          path={`success`}
          element={
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant={"h5"} gutterBottom>
                Success 🎉
              </Typography>
              <Typography variant={"body1"} gutterBottom>
                Your print is on its way! Shipping may take a few weeks, so
                please be patient.
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginTop: 3, marginBottom: 3 }}
              >
                In the mean time, please consider sharing that you got a print!
              </Typography>
              <Button
                href={`https://twitter.com/intent/tweet?url=https%3A%2F%2Fmint.turfnft.com/claim-your-print%2F&via=TURF_NFT&text=${tweet}&hashtags=CardanoSummit2022,SummitCNFT22,CNFT`}
                startIcon={<Twitter />}
                target="_blank"
                size="large"
                sx={{
                  color: (theme) => theme.palette.common.white,
                  marginBottom: 2,
                }}
              >
                Tweet about it
              </Button>
              <Link to="/">Get another print</Link>
            </Box>
          }
        />

        <Route
          path="*"
          element={
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h3"
                textAlign="center"
                sx={{ marginBottom: 2, position: "relative", display: "flex" }}
                component="div"
              >
                Order a print of your TURF NFT{" "}
                <Typography
                  variant="caption"
                  component="span"
                  sx={{ position: "absolute", top: -24 }}
                >
                  Beta release{" "}
                  <Tooltip title="If you experience any problems, please reach out to us! hello@turfnft.com">
                    <InfoRounded fontSize="inherit" />
                  </Tooltip>
                </Typography>
              </Typography>
              <Typography variant="h5" textAlign="center">
                {Math.floor(PRINT_PRICE_EUR * adaRate)} ADA
              </Typography>
              <Typography variant="h6" textAlign="center">
                Got a TURF token or a Gold Summit NFT? One print for free!*
              </Typography>
              <Typography
                variant="body1"
                textAlign="center"
                fontStyle="italic"
                sx={{ marginBottom: 4 }}
              >
                Printed on museum grade fine art paper, 40x60cm (16x24")
              </Typography>
              <Typography textAlign="center" component="div">
                <Button
                  size="large"
                  variant="text"
                  className="gradient"
                  sx={{
                    color: (theme) => theme.palette.common.white,
                    fontWeight: "bold",
                  }}
                  onClick={() => navigate("/claim-your-print/select-wallet")}
                >
                  Let's go!
                </Button>
              </Typography>
              <Typography
                variant="body1"
                textAlign="center"
                fontWeight="700"
                sx={{ marginTop: 4 }}
              >
                The 4 steps to order a print:
              </Typography>
              <Typography variant="body1" component="div">
                <ol>
                  <li>Choose the wallet that holds your TURF NFTs.</li>
                  <li>Choose the NFT that you want to print.</li>
                  <li>Provide your shipping information.</li>
                  <li>Submit transaction</li>
                </ol>
              </Typography>

              <Box sx={{ display: "flex", marginTop: 4 }}>
                <img
                  src={`${process.env.PUBLIC_URL}/print_example.jpg`}
                  style={{ width: 300 }}
                />
                <img
                  src={`${process.env.PUBLIC_URL}/print_2.jpg`}
                  style={{ width: 300 }}
                />
              </Box>
            </Box>
          }
        />
      </Routes>
      <Typography
        variant="caption"
        component="div"
        sx={{ marginTop: 4 }}
        textAlign="center"
      >
        * Transaction fees apply. If your wallet address holds any TURF tokens
        we will automatically detect them once you submit your recipient
        details.
        <br />
        Having trouble? Please reach out to us on Discord or Twitter
        <Box sx={{ marginTop: 1 }}>
          <img
            alt="discord"
            src={`${process.env.PUBLIC_URL}/discord.svg`}
            style={{ cursor: "pointer", marginRight: 8 }}
            width="20"
            onClick={() =>
              window.open("https://discord.com/invite/H5EjT4jdWj", "_blank")
            }
          />
          {"  "}
          <img
            alt="twitter"
            src={`${process.env.PUBLIC_URL}/twitter.svg`}
            style={{ cursor: "pointer" }}
            width="20"
            onClick={() =>
              window.open("https://twitter.com/TURF_NFT", "_blank")
            }
          />
        </Box>
      </Typography>
    </Container>
  );
}
