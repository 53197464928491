import { Close } from "@mui/icons-material";
import { Dialog, Grid, IconButton, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUiStore } from "../stores/uiStore";

export default function TermsModal() {
  const navigate = useNavigate();

  const [setShowWelcomeScreen, setSidebar] = useUiStore((state) => [
    state.setShowWelcomeScreen,
    state.setSidebar,
  ]);

  useEffect(() => setShowWelcomeScreen(false), [setShowWelcomeScreen]);

  const onClose = () => navigate(`/${window.location.search}`);
  return (
    <Dialog
      open={true}
      onClose={onClose}
      maxWidth="lg"
      PaperProps={{
        sx: {
          m: { xs: 1, sm: 2 },
          p: 4,
          maxHeight: (theme) => ({
            xs: `calc(100% - ${theme.spacing(2)})`,
            sm: `calc(100% - ${theme.spacing(4)})`,
          }),
        },
      }}
      closeAfterTransition={true}
      keepMounted={false}
    >
      <IconButton
        sx={{ position: "absolute", right: 0, mr: 4 }}
        onClick={onClose}
      >
        <Close />
      </IconButton>
      <Typography
        variant="h2"
        sx={{
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          textAlign: "center",
          mb: 8,
        }}
        className="gradient"
      >
        TURF
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <div>
            <div>
              <div>
                <p>Last updated on May 12th 2023</p>
                <p>
                  These terms and conditions constitute a legally binding
                  agreement (the &ldquo;Agreement&rdquo;) between the
                  purchaser/user (also referred to herein as &ldquo;You&rdquo;,
                  &ldquo;Your&rdquo; or &ldquo;User&rdquo;) and Turf (
                  &ldquo;we&rdquo; , &ldquo;us&rdquo; or &ldquo;our&rdquo;),
                  governing your purchase and use of Turf NFTs as part of the
                  Turf Offering (the &ldquo;Offering&rdquo;). BY PARTICIPATING
                  IN THIS OFFERING AND USE OF A Turf NFT, YOU AGREE TO BE BOUND
                  BY THESE TERMS AND ALL OF THE TERMS INCORPORATED HEREIN. IF
                  YOU DO NOT AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF
                  THIS AGREEMENT, YOU MAY NOT PARTICIPATE IN THE OFFERING AND
                  MUST DISCONTNUE USE OF YOUR TURF NFT.
                </p>
                <p>
                  By entering into this Agreement, and/or by you participating
                  in the Turf Offering, and/or using your Turf NFT, you
                  expressly acknowledge that you understand this Agreement and
                  accept all of its terms. IF YOU DO NOT AGREE TO BE BOUND BY
                  THE TERMS AND CONDITIONS OF THIS AGREEMENT, YOU MAY NOT
                  PARTICIPATE IN THE OFFERING AND MUST DISCONTINUE USE OF YOUR
                  TURF NFT.
                </p>
                <p>
                  TURF BV creates cartographic art. TURF Company has divided the
                  surface of the earth in 2x2 kilometer squares. Each single
                  cartographic art concerns a 1/1 map of a certain 2x2 km square
                  position on earth (a "Turf Art"). Each Turf is connected to a
                  unique Non-Fungible Token ("NFT"). In a nutshell, each NFT is
                  a one of a kind token attached to a digital asset, being a
                  specific, unique Turf Art. These NFTs, known as a &ldquo;Turf
                  NFTs&rdquo;, are made available for purchase through the
                  turfnft.com website (the &ldquo;Site&rdquo;). The Turf NFTs
                  and the Site are collectively referred to in these T&amp;C as
                  &ldquo;Turf&rdquo;.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>Requirements for Purchase and Use.</strong>
                </p>
                <ul data-rte-list="default">
                  <li>
                    <p>
                      Participation in the Turf Offering and its continued use
                      is open only to individuals who have the right and
                      authority to enter into this Agreement, are fully able and
                      competent to satisfy the terms, conditions, and
                      obligations herein.
                    </p>
                  </li>
                  <li>
                    <p>
                      You represent and warrant that you are at least 18 years
                      old.
                    </p>
                  </li>
                  <li>
                    <p>
                      If You wish to place an Order for Turf NFTs available on
                      the Site, You may be asked to supply certain information
                      relevant to Your Order including, without limitation, Your
                      name, Your email, Your phone number, Your billing address,
                      and Your shipping information.
                    </p>
                  </li>
                  <li>
                    <p>
                      By submitting such information, You grant us the right to
                      provide the information to payment processing third
                      parties for purposes of facilitating the completion of
                      Your Order.
                    </p>
                  </li>
                  <li>
                    <p>
                      We reserve the right to refuse or cancel Your Order at any
                      time for certain reasons including but not limited to:
                    </p>
                  </li>
                  <li>
                    <p>
                      We reserve the right to refuse or cancel Your Order if
                      fraud or an unauthorized or illegal transaction is
                      suspected.
                    </p>
                  </li>
                  <li>
                    <p>
                      Due to the nature of the digital items for sale, We are
                      unable to accept cancellations returns.
                    </p>
                  </li>
                  <li>
                    <p>
                      We are constantly updating Our offerings on the Site. The
                      Turf NFTs available on The Site may be mispriced,
                      described inaccurately, or unavailable, and We may
                      experience delays in updating information regarding our
                      Turf NFTs on the Site and in Our advertising on other
                      websites.
                    </p>
                  </li>
                  <li>
                    <p>
                      We cannot and do not guarantee the accuracy or
                      completeness of any information, including prices, product
                      images, specifications, availability, and services. We
                      reserve the right to change or update information and to
                      correct errors, inaccuracies, or omissions at any time
                      without prior notice.
                    </p>
                  </li>
                  <li>
                    <p>
                      All Goods purchased are subject to a one-time payment.
                      Payment can be made using ADA cryptocurrency on the
                      Cardano platform. If We do not receive the required
                      payment, We will not be liable for any delay or
                      non-delivery of Your Order.
                    </p>
                  </li>
                  <li>
                    <p>
                      In the event you wish to cancel your order prior to
                      completing the purchase process, you can do so by clicking
                      the trash can icon on the card showing a preview of the
                      TURF art you selected.
                    </p>
                  </li>
                  <li>
                    <p>
                      By purchasing a Turf you agree to the immediate provision
                      of the contractual service and agree to be informed that
                      you lose the right of withdrawal
                    </p>
                  </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                  <strong>Indemnity</strong>
                </p>
                <p>
                  You agree to defend, indemnify, and hold harmless Turf,
                  including each of our respective affiliates, subsidiaries,
                  parents, successors and assigns, and each of our respective
                  officers, directors, employees, agents, or shareholders, from
                  any claims, actions, suits, losses, costs, liabilities and
                  expenses (including reasonable attorneys&rsquo; fees) relating
                  to or arising out of your sale, possession, or use of your
                  Turf NFT(s) and/or Your participation in the Offering,
                  including: (1) Your breach of this Agreement or the documents
                  it incorporates by reference; (2) Your violation of any law or
                  the rights of a third-party as a result of your own actions or
                  interaction with such third party; (3) any and all failed
                  transactions related to your Turf NFT, and/or (5) any other
                  activities in connection with the Offering or the Turf NFT.
                  This indemnity shall be applicable without regard to the
                  negligence of any party, including any indemnified person.
                </p>
                <p>
                  <strong>Rights</strong>
                </p>
                <ul data-rte-list="default">
                  <li>
                    <p>
                      You are allowed to distribute, modify, and publicly
                      display or perform the Turf Art ("Use"). We do not permit
                      any type of Use related to porn, full on nudity, illegal
                      drugs, pharmaceuticals, weapons or fake news. Furthermore,
                      anything that could be construed as offensive, racist,
                      religious or politically motived is also not permitted.
                      This rule also applies to us. We can add additional
                      categories (as included on our Website) if required.
                    </p>
                  </li>
                  <li>
                    <p>
                      You only purchase an NFT, not the underlying Turf Art
                      itself. Copyright relating to the Turf Art does not
                      transfer to you with the purchase of the NFT. TURF Company
                      retains the right to copy, distribute, modify, and
                      publicly display or perform the Turf.
                    </p>
                  </li>
                  <li>
                    <p>
                      Free and Clear Ownership of your Turf NFT.&nbsp; Once
                      successfully purchased, you are the sole owner of the Turf
                      NFT. Turf retains no rights or controls over the Turf NFT
                      after the NFT has been transmitted.&nbsp; As a result,
                      once purchased, you are solely responsible for the
                      maintenance of the Turf NFT and its lawful use.
                    </p>
                  </li>
                  <li>
                    <p>
                      Transfers of Turf NFT. You have the unlimited right to
                      transfer the Turf NFT.&nbsp; However, the use of the Turf
                      NFT by any successor transferee is an agreement by that
                      transferee to these terms and conditions.&nbsp; IF YOU ARE
                      A TRANSFEREE OF A TURF NFT AND DO NOT AGREE TO THESE TERMS
                      AND CONDITIONS YOU SHALL DISCONTINUE USE OF THE TURF NFT.
                    </p>
                  </li>
                  <li>
                    <p>
                      To support the continued use and development of the
                      platform, Turf may collect royalties equal to two percent
                      (2%) of the sale price from subsequent sales of Turf NFTs
                      when such sales occur within a marketplace which supports
                      the{" "}
                      <a href="https://github.com/cardano-foundation/CIPs/pull/116/files">
                        CIP-0027 proposal
                      </a>
                      .
                    </p>
                  </li>
                  <li>
                    <p>
                      Turf NFT may choose to gift you a $TURF fungible token in
                      certain circumstances. $TURF fungible tokens do not
                      provide any claim or right on any goods or services, nor
                      do $TURF fungible tokens represent any monetary value and
                      thus cannot be redeemed for cash or cash equivalents. At
                      the discretion of Turf NFT, Turf NFT may choose to accept
                      $TURF fungible tokens in exchange for any goods or
                      services it offers. No other company, organization or
                      third party of any kind is allowed to accept $TURF
                      fungible tokens in exchange for goods or services.
                    </p>
                  </li>
                </ul>
                <p>
                  <strong>
                    &ldquo;AS IS&rdquo; and &ldquo;AS AVAILABLE&rdquo;
                    Disclaimer
                  </strong>
                </p>
                <p>
                  The Service is provided to You &ldquo;AS IS&rdquo; and
                  &ldquo;AS AVAILABLE&rdquo; and with all faults and defects
                  without warranty of any kind. To the maximum extent permitted
                  under applicable law, the Company, on its own behalf and on
                  behalf of its Affiliates and its and their respective
                  licensors and service providers, expressly disclaims all
                  warranties, whether express, implied, statutory or otherwise,
                  with respect to the Service, including all implied warranties
                  of merchantability, fitness for a particular purpose, title
                  and non-infringement, and warranties that may arise out of
                  course of dealing, course of performance, usage or trade
                  practice. Without limitation to the foregoing, the Company
                  provides no warranty or undertaking, and makes no
                  representation of any kind that the Service will meet Your
                  requirements, achieve any intended results, be compatible or
                  work with any other software, applications, systems or
                  services, operate without interruption, meet any performance
                  or reliability standards or be error free or that any errors
                  or defects can or will be corrected.
                </p>
                <p>
                  Without limiting the foregoing, neither the Company nor any of
                  the company&rsquo;s provider makes any representation or
                  warranty of any kind, express or implied: (i) as to the
                  operation or availability of the Service, or the information,
                  content, and materials or products included thereon; (ii) that
                  the Service will be uninterrupted or error-free; (iii) as to
                  the accuracy, reliability, or currency of any information or
                  content provided through the Service; or (iv) that the
                  Service, its servers, the content, or e-mails sent from or on
                  behalf of the Company are free of viruses, scripts, trojan
                  horses, worms, malware, timebombs or other harmful components.
                </p>
                <p>
                  Some jurisdictions do not allow the exclusion of certain types
                  of warranties or limitations on applicable statutory rights of
                  a consumer, so some or all of the above exclusions and
                  limitations may not apply to You. But in such a case the
                  exclusions and limitations set forth in this section shall be
                  applied to the greatest extent enforceable under applicable
                  law.
                </p>
                <p>
                  <strong>Assumption Of Risk</strong>
                </p>
                <p>
                  As noted above, the Turf NFT is made available by us solely to
                  preserve digital art on the Cardano Blockchain. You agree that
                  You assume the following risks: (A) To the extent there is a
                  price or market for a blockchain asset such as a Turf NFT,
                  such markets and prices are extremely volatile, and variations
                  in the price of other digital assets could materially and
                  adversely affect the value of any digital asset(s) You own,
                  including Your Turf NFT, and there is no guarantee that Your
                  Turf NFT will have or retain any value; (B) the commercial or
                  market value on a Turf NFT that You purchase may materially
                  diminish in value as a result of a variety of things such as
                  negative publicity ; (C) there are risks associated with using
                  an Internet-native assets (e.g., non-fungible tokens,
                  cryptocurrencies, etc.) including, but not limited to, risks
                  associated with hardware, software and Internet connections
                  and/or their failure to function properly as you anticipated,
                  the risk of malicious software introduction, and the risk that
                  third parties may obtain unauthorized access to information
                  stored within your digital &ldquo;wallet&rdquo; or elsewhere.
                  Turf will not be responsible for any damages associated with
                  these risks ,however caused; (D) Turf does not make any
                  promises or guarantees about the availability of the Turf NFT
                  or that they will host the Turf NFT at any specific location
                  and/or for any specific period of time; (E) upgrades to the
                  Cardano platform, a hard fork or other change in the Cardano
                  platform, a failure or cessation of Cardano, or a change in
                  how transactions are confirmed on the Cardano platform may
                  have unintended, adverse effects on all blockchains using such
                  technologies, including without limitation, your Turf NFT.
                  Turf does not make any promises or guarantees related to
                  Blockchain or any other third parties related to this offering
                  and each of their applications and/or services, including but
                  not limited to the continued availability of either and/or the
                  protection and/or storage of any data you provide to those
                  parties. the risk of losing access to a Turf NFT due to loss
                  of private key(s), custodial error or purchaser error; (F) the
                  risk of mining attacks; (G) the risk of hacking, security
                  weaknesses, fraud, counterfeiting, cyber-attacks and other
                  technological difficulties. Turf cannot and does not represent
                  or warrant that any Turf NFT, or its supporting systems or
                  technology, is reliable, current or error-free, meets Your
                  requirements, or that defects in the Turf NFT, or its
                  supporting systems or technology, will be corrected. Turf
                  cannot and does not represent or warrant that the Turf NFT or
                  the delivery mechanism for it are free of viruses or other
                  harmful components. You accept and acknowledge that Turf will
                  not be responsible for any communication failures,
                  disruptions, errors, distortions or delays You may experience
                  related to the Offering.
                </p>
                <p>
                  <strong>Governing Law</strong>
                </p>
                <p>
                  This Agreement and all matters related to it shall be governed
                  by, construed, and enforced in accordance with the laws of the
                  Netherlands without regard to conflict of law principles,
                  except to the extent that law is inconsistent with or
                  preempted by federal law.
                </p>
                <p>
                  <strong>Changes to this Agreement</strong>
                </p>
                <p>
                  Turf may make changes to this Agreement from time to time.
                  When Turf makes such changes, we will make the updated
                  Agreement available on this website and update the &ldquo;Last
                  Updated&rdquo; date at the beginning of the Agreement
                  accordingly. Please check this page periodically for changes.
                  Your continued use of the Turf NFT after the Agreement has
                  been updated will constitute your binding acceptance of the
                  updated terms of the Agreement.
                </p>
                <p>
                  <strong>Translation</strong>
                </p>
                <p>
                  If you are provided a translation of this Agreement, the
                  original version in English will be used in deciding any
                  issues or disputes which arise under this Agreement.
                </p>
                <p>
                  <strong>Severability</strong>
                </p>
                <p>
                  If any term or provision of this Agreement is invalid,
                  illegal, or unenforceable in any jurisdiction, such
                  invalidity, illegality, or unenforceability shall not affect
                  any other term or provision of this Agreement or invalidate or
                  render unenforceable such term or provision in any other
                  jurisdiction.
                </p>
                <p>
                  <strong>Contact Us</strong>
                </p>
                <p>
                  If you have any questions about these Terms and Conditions, we
                  encourage You to contact us via e-mail at hello@turfnft.com.
                </p>
              </div>
            </div>
          </div>
          <div id="block-yui_3_17_2_1_1682079752210_5318" data-block-type="2">
            <div>
              <div>
                <p>
                  <strong>Risk warning</strong>
                </p>
                <p>
                  If you consider to buy our NFT&rsquo;s, please carefully read
                  our{" "}
                  <a href="https://turfnft.com/TURF%20risk%20warning.pdf">
                    risk disclaimer
                  </a>{" "}
                  before you decide to purchase the NFT&rsquo;s .
                </p>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Dialog>
  );
}
