import bbox from "@turf/bbox";
import { featureCollection, lineString, point } from "@turf/helpers";
import length from "@turf/length";
import { inRange } from "lodash";
import { Lucid, hexToUtf8, utf8ToHex } from "lucid-cardano";
import { GridSquare } from "../definitions";
import {
  CS22_POLICY_ID,
  ENVIRONMENT,
  MERGE_PRICE_LOVELACE,
  POLICY_ID,
  PRINT_CLAIM_TOKEN_UNIT,
} from "./config";

const ERROR_MARGIN = 0.1;

const isInValidRange = (val: number, expected: number) =>
  inRange(val, expected * (1 - ERROR_MARGIN), expected * (1 + ERROR_MARGIN));

export const isValidSquareSelection = (selectedSquares: GridSquare[]) => {
  const squareCount = selectedSquares.length;
  if (![1, 4, 9, 16, 25].includes(squareCount)) {
    return false;
  }

  if (squareCount === 1) return true;

  const points = selectedSquares.flatMap((sd) =>
    sd.geometry.coordinates.flat()
  );
  const bounds = bbox(featureCollection(points.map((p) => point(p))));
  const height = length(
    lineString([
      [bounds[0], bounds[1]],
      [bounds[0], bounds[3]],
    ])
  );
  const width = length(
    lineString([
      [bounds[0], bounds[1]],
      [bounds[2], bounds[1]],
    ])
  );

  const sideLength = Math.sqrt(squareCount) * 2;
  return (
    isInValidRange(width, sideLength) && isInValidRange(height, sideLength)
  );
};

export const getTurfAssetIdsFromAssets = (assets: string[]) =>
  assets
    .filter((assetId) => assetId.startsWith(POLICY_ID))
    .map((assetId) =>
      hexToUtf8(assetId.replace(POLICY_ID, "")).replace("TURF", "")
    );

export const getCs22AssetIdsFromAssets = (assets: string[]) =>
  assets
    .filter((assetId) => assetId.startsWith(CS22_POLICY_ID))
    .map((assetId) => hexToUtf8(assetId.replace(CS22_POLICY_ID, "")));

export const locationCodeFromAssetId = (assetId: string) => {
  const firstPart = assetId.slice(0, 8);
  const secondPart = assetId.slice(8, 10);
  return `${firstPart}+${secondPart}`;
};

export const submitMergeTransaction = async (
  lucid: Lucid,
  assetIds: string[],
  address: string
) => {
  const assets: { [assetId: string]: bigint } = {
    lovelace: BigInt(MERGE_PRICE_LOVELACE),
  };

  for (const assetId of assetIds) {
    assets[
      `${POLICY_ID}${utf8ToHex(
        ENVIRONMENT === "production" ? `TURF${assetId}` : assetId
      )}`
    ] = BigInt(1);
  }

  const tx = await lucid.newTx().payToAddress(address, assets).complete();
  const signedTx = await tx.sign().complete();
  return await signedTx.submit();
};

export const submitClaimSummitPrintTransaction = async (
  lucid: Lucid,
  assetId: string
) => {
  const assetHex = `${CS22_POLICY_ID}${utf8ToHex(assetId)}`;
  const assets = {
    lovelace: BigInt(2),
    [assetHex]: BigInt(1),
  };
  const address = await lucid.wallet.address();

  const tx = await lucid
    .newTx()
    .attachMetadata(2722, {
      assetId,
      status: "CONFIRMED",
      website: "https://mint.turfnft.com/claim-your-print/claimed",
    })
    .payToAddress(address, assets)
    .complete();
  const signedTx = await tx.sign().complete();
  return signedTx.submit();
};

export const submitBuyPrintTransaction = async (
  lucid: Lucid,
  assetsInWallet: { [unit: string]: bigint },
  address: string,
  quantity = 1,
  printPriceLovelace: number
) => {
  const assets: { [unit: string]: bigint } = {};

  if (Object.keys(assetsInWallet).includes(PRINT_CLAIM_TOKEN_UNIT)) {
    assets[PRINT_CLAIM_TOKEN_UNIT] = BigInt(
      Math.min(quantity, Number(assetsInWallet[PRINT_CLAIM_TOKEN_UNIT]))
    );
  }

  if (
    !assets[PRINT_CLAIM_TOKEN_UNIT] ||
    assets[PRINT_CLAIM_TOKEN_UNIT] < quantity
  ) {
    const tokensUsed = assets[PRINT_CLAIM_TOKEN_UNIT]
      ? Number(assets[PRINT_CLAIM_TOKEN_UNIT])
      : 0;
    assets.lovelace = BigInt(printPriceLovelace * (quantity - tokensUsed));
  }

  const tx = await lucid.newTx().payToAddress(address, assets).complete();
  const signedTx = await tx.sign().complete();
  return signedTx.submit();
};

export const isTouchDevice = () => {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    // @ts-ignore
    navigator.msMaxTouchPoints > 0
  );
};
