import { Close, Info } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { FREE_PRINT_EUR, PRINT_PRICE_EUR } from "../../config";
import { useReservationStore } from "../../stores/reservationStore";
import { useUiStore } from "../../stores/uiStore";
import BurnAddress from "./BurnAddress";
import Empty from "./Empty";
import PreviewList from "./PreviewList";
import ProcessingStep from "./ProcessingStep";
import ProductList from "./ProductList";
import ReadyStep from "./ReadyStep";
import RecipientForm from "./RecipientForm";
import Success from "./Success";

const CheckoutFooter = () => {
  const setError = useUiStore((state) => state.setError);
  const [
    reservationId,
    cancelReservation,
    turfs,
    products,
    currentReservation,
  ] = useReservationStore((state) => [
    state.reservationId,
    state.cancelReservation,
    state.turfs,
    state.products,
    state.currentReservation,
  ]);

  const [exchangeRates, currency] = useUiStore((state) => [
    state.exchangeRates,
    state.currency,
  ]);
  const exchangeRate = exchangeRates.find((er) => er.target === currency);
  const productQuantity = products.reduce(
    (prev, curr) => prev + curr.quantity,
    0
  );

  const [cancelling, setCancelling] = useState(false);

  const totalTurfPrice = turfs.reduce((prev, curr) => prev + curr.priceEur, 0);
  const totalProducts = products.reduce(
    (prev, curr) => prev + curr.quantity,
    0
  );
  const totalProductPrice = products.reduce(
    (prev, curr) => prev + curr.quantity * curr.priceEur,
    0
  );
  const totalDiscount =
    Math.floor(totalTurfPrice / FREE_PRINT_EUR) *
    PRINT_PRICE_EUR *
    totalProducts;

  const tokens = Math.floor(totalTurfPrice / FREE_PRINT_EUR) - totalProducts;

  return (
    <div style={{ textAlign: "center", marginTop: "3em" }}>
      <Typography variant="caption">
        Order total:{" "}
        <b>
          {exchangeRate?.element ? (
            <exchangeRate.element />
          ) : (
            exchangeRate?.sign
          )}{" "}
          {(
            (currentReservation?.randomReservationPlaceName
              ? currentReservation.priceEur
              : totalTurfPrice + totalProductPrice - totalDiscount) *
            (exchangeRate?.value || 1)
          ).toFixed(0)}
        </b>{" "}
        {!currentReservation?.randomReservationPlaceName && (
          <>
            ({turfs.length > 0 ? `${turfs.length} TURFs` : ""}
            {turfs.length > 0 && (productQuantity > 0 || tokens > 0) && `, `}
            {productQuantity > 0 ? `${productQuantity} prints` : ""}
            {productQuantity > 0 && tokens > 0 && `, `}
            {tokens > 0 ? `${tokens} tokens` : ""})
          </>
        )}
      </Typography>
      <br />
      <Typography variant="caption">
        Reservation id: {reservationId}{" "}
        <Tooltip
          title="If you encounter any problems during minting, you can contact us on
    discord with your reservation number "
        >
          <Info fontSize="inherit" />
        </Tooltip>
        <br />
        <LoadingButton
          size="small"
          onClick={async () => {
            setCancelling(true);
            try {
              await cancelReservation();
            } catch (e: any) {
              setError(e.message);
            } finally {
              setCancelling(false);
            }
          }}
          variant="text"
          loading={cancelling}
        >
          cancel your reservation
        </LoadingButton>
      </Typography>
    </div>
  );
};

const CheckoutError = () => {
  const [reservationId, reservationErrors] = useReservationStore((state) => [
    state.reservationId,
    state.reservationErrors,
  ]);
  return (
    <div style={{ textAlign: "center" }}>
      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        Oops, something went wrong..
      </Typography>
      <img src={`${process.env.PUBLIC_URL}/error.jpg`} alt="error" />
      <Typography variant="body1" sx={{ marginBottom: 3 }}>
        Please contact us on Twitter or Discord with your reservation number:{" "}
        <b>{reservationId}</b>
      </Typography>

      <code>
        {reservationErrors?.map((error, index) => (
          <div key={index}>{error.message}</div>
        ))}
      </code>
    </div>
  );
};

export default function CheckoutSidebar() {
  const [reservationId, checkoutStep] = useReservationStore((state) => [
    state.reservationId,
    state.checkoutStep,
  ]);

  const setSidebar = useUiStore((state) => state.setSidebar);

  const checkoutStepMap = {
    "": <Empty />,
    PREVIEW: <PreviewList />,
    PROCESSING: <ProcessingStep />,
    READY: <ReadyStep />,
    COMPLETE_MERGE: <BurnAddress />,
    ERROR: <CheckoutError />,
    RECIPIENT_FORM: <RecipientForm />,
    // SELECT_CHAIN: <SelectChain />,
    PRODUCT_LIST: <ProductList />,
    SUCCESS: <Success />,
  };

  return (
    <>
      <Box>
        <IconButton onClick={() => setSidebar("")} sx={{ float: "right" }}>
          <Close />
        </IconButton>
      </Box>
      {checkoutStepMap[checkoutStep]}
      {!!reservationId && <CheckoutFooter />}
    </>
  );
}
