import { Button, Stack } from "@mui/material";
import { useState } from "react";
import { useWalletStore } from "../stores/walletStore";

export default function Wallets({ onSuccess }: { onSuccess?: () => void }) {
  const [enableWallet, walletList] = useWalletStore((state) => [
    state.enableWallet,
    state.walletList,
  ]);
  const [loading, setLoading] = useState(false);

  return (
    <Stack
      spacing={2}
      direction={{ xs: "column", sm: "row" }}
      sx={{ textAlign: "center", marginTop: 2, marginBottom: 2 }}
    >
      {Object.keys(walletList).map(
        (wallet) =>
          Boolean(window.cardano) &&
          Boolean(window.cardano[wallet]) && (
            <Button
              variant="contained"
              key={wallet}
              size="small"
              disabled={loading}
              sx={{ fontSize: 14 }}
              onClick={() => {
                setLoading(true);
                enableWallet(wallet as keyof typeof walletList)
                  .then(() => {
                    setLoading(false);
                    onSuccess && onSuccess();
                  })
                  .catch(() => setLoading(false));
              }}
            >
              {walletList[wallet as keyof typeof walletList].label}
            </Button>
          )
      )}
    </Stack>
  );
}
