import { LoadingButton } from "@mui/lab";
import { Box, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Lucid } from "lucid-cardano";
import { useState } from "react";
import { PRINT_PRICE_EUR } from "../../config";
import { useMapStore } from "../../stores/mapStore";
import { useReservationStore } from "../../stores/reservationStore";
import { useUiStore } from "../../stores/uiStore";
import { useWalletStore } from "../../stores/walletStore";
import { submitBuyPrintTransaction } from "../../utils";
import { WalletList } from "../Basket";

export default function ReadyStep() {
  const [setSidebar, setError, exchangeRates] = useUiStore((state) => [
    state.setSidebar,
    state.setError,
    state.exchangeRates,
  ]);
  const adaRate = exchangeRates.find((er) => er.target === "ADA")?.value || 1;
  const [lucid, assetsInWallet] = useWalletStore((state) => [
    state.lucid,
    state.assetsInWallet,
  ]);
  const [currentReservation, resetReservation, products, setCheckoutStep] =
    useReservationStore((state) => [
      state.currentReservation,
      state.resetReservation,
      state.products,
      state.setCheckoutStep,
    ]);
  const resetSelectedSquares = useMapStore(
    (state) => state.resetSelectedSquares
  );
  const [loading, setLoading] = useState(false);
  const [showWalletList, setShowWalletList] = useState(false);
  const openPaymentUrl = () => {
    resetReservation();
    resetSelectedSquares();
    setSidebar("");
    window.open(currentReservation?.fungCheckout, "_blank");
  };

  const payWithTokens = async () => {
    setLoading(true);
    if (!lucid || !currentReservation?.paymentAddress) {
      setError("No wallet instance or payment address found!");
      setLoading(false);
      return;
    }

    try {
      await submitBuyPrintTransaction(
        lucid,
        assetsInWallet,
        currentReservation?.paymentAddress,
        products.reduce((p, c) => p + c.quantity, 0),
        Math.floor(adaRate * PRINT_PRICE_EUR * 1000000)
      );
      setCheckoutStep("SUCCESS");
    } catch (e: any) {
      if (e === "InputsExhaustedError")
        e = "Not enough funds to complete the transaction.";
      setError(e.message || e.info || e);
    } finally {
      setLoading(false);
    }
  };

  const submitPayment = async (
    lucid: Lucid,
    address: string,
    lovelace: bigint
  ) => {
    console.log("NO LUCID??");
    console.log(lucid);
    const tx = await lucid
      .newTx()
      .payToAddress(address, { lovelace })
      .complete();
    const signedTx = await tx.sign().complete();
    await signedTx.submit();
    setCheckoutStep("SUCCESS");
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "2em",
        }}
      >
        <Typography variant="h5" fontFamily="Roboto" fontWeight={700}>
          Your TURF is ready!
        </Typography>
      </Box>
      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        Woohoo generating your TURF was successful! You can now finish your
        order by sending the ADA amount to the correct address:
      </Typography>
      <div style={{ textAlign: "center" }}>
        {!lucid && (
          <Button
            size="large"
            onClick={async () => {
              setShowWalletList(true);
            }}
            sx={{ mt: 2, fontWeight: 700 }}
            fullWidth
            className="gradient"
            disabled={loading}
          >
            Connect wallet
          </Button>
        )}
        {!!lucid && (
          <Button
            size="large"
            onClick={async () => {
              await submitPayment(
                lucid,
                currentReservation?.paymentAddress!,
                BigInt(currentReservation?.priceLovelace!)
              );
            }}
            sx={{ mt: 2, fontWeight: 700 }}
            fullWidth
            className="gradient jump"
            disabled={loading}
          >
            Submit transaction
          </Button>
        )}
        <WalletList
          open={showWalletList}
          onClose={async () => {
            setShowWalletList(false);
          }}
        />
        <Typography variant="caption" sx={{ mt: 2 }}>
          Or manually transfer{" "}
          <b>{currentReservation?.priceLovelace! / 1000000}</b> ADA to{" "}
          <b style={{ wordWrap: "break-word" }}>
            {currentReservation?.paymentAddress}
          </b>
        </Typography>
        {currentReservation?.type === "PRODUCT" && (
          <LoadingButton
            variant="text"
            onClick={payWithTokens}
            loading={loading}
          >
            Or use TURF tokens
          </LoadingButton>
        )}
      </div>
    </div>
  );
}
