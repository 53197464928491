import { useEffect, useState } from "react";
import { PricedGridSquare } from "../definitions";
import { UNBLOCK_ALL_SQUARES_NAME, currentPriceEur } from "./config";
import { getGridFeatures } from "./my-api";
import { useMapStore } from "./stores/mapStore";
import { useReservationStore } from "./stores/reservationStore";
import { useUiStore } from "./stores/uiStore";

export const usePricedSquares = (
  bounds: string,
  zoom: number
): PricedGridSquare[] => {
  const [squares, setSquares] = useState<PricedGridSquare[]>([]);
  const [selectedSquares, prices, loadingPrices] = useMapStore((state) => [
    state.selectedSquares,
    state.prices,
    state.loadingPrices,
  ]);

  const [reservations, loadingReservations] = useReservationStore((state) => [
    state.reservations,
    state.loadingReservations,
  ]);

  const [setGridError, setLoadingGridSquares] = useUiStore((state) => [
    state.setGridError,
    state.setLoadingGridSquares,
  ]);
  useEffect(() => {
    if (zoom > 11) {
      setLoadingGridSquares(true);
      getGridFeatures(bounds)
        .then((res) => {
          setGridError("");
          if (res.length === 0) {
            return;
          }
          const squares = res.map((square) => {
            const priceData =
              prices?.find((price) =>
                price.squares.includes(square.properties.id)
              ) ||
              prices.find((price) => price.id === UNBLOCK_ALL_SQUARES_NAME);

            return {
              ...square,
              isSelected: selectedSquares
                .map((s) => s.properties.id)
                .includes(square.properties.id),
              isReserved:
                loadingReservations ||
                reservations?.some((reservation) =>
                  reservation.desiredSquares.includes(square.properties.id)
                ),
              isMinted:
                loadingReservations ||
                reservations
                  ?.filter((reservation) => reservation.minted)
                  .some((reservation) =>
                    reservation.desiredSquares.includes(square.properties.id)
                  ),
              priceEur: loadingPrices
                ? Infinity
                : (square.properties.blocked && priceData?.priceEur) ||
                  currentPriceEur,
              unblocked: square.properties.blocked && !!priceData,
              randomDrop:
                priceData?.name === "RANDOM_DROP" && square.properties.blocked,
              color: priceData?.color,
            };
          });
          setSquares(squares);
        })
        .finally(() => setLoadingGridSquares(false));
    } else {
      setGridError("Zoom in to see squares");
      setSquares([]);
    }
  }, [
    bounds,
    zoom,
    loadingPrices,
    loadingReservations,
    prices,
    reservations,
    selectedSquares,
    setGridError,
  ]);

  return squares;
};
