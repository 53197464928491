import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { MERGE_PRICE_LOVELACE } from "../../config";
import { useReservationStore } from "../../stores/reservationStore";
import { useUiStore } from "../../stores/uiStore";
import { useWalletStore } from "../../stores/walletStore";
import { submitMergeTransaction } from "../../utils";

export default function BurnAddress() {
  const lucid = useWalletStore((state) => state.lucid);
  const setMapMode = useUiStore((state) => state.setMapMode);
  const [currentReservation, resetReservation] = useReservationStore(
    (state) => [state.currentReservation, state.resetReservation]
  );
  const { assetIds = [], paymentAddress = "" } = currentReservation || {};
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "2em",
        }}
      >
        <Typography variant="h5">Complete the transaction</Typography>
      </Box>
      {success && (
        <>
          <Typography
            style={{
              margin: "1em auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            variant="body1"
          >
            Success! You will receive your merged Turf within a few minutes 👏
            👏
          </Typography>
        </>
      )}
      {!success && (
        <>
          <Typography
            style={{
              margin: "1em auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            variant="body1"
          >
            You can now complete the process by clicking the button below. It
            will send all the assets and {MERGE_PRICE_LOVELACE / 1000000} ADA to
            our TURF burning address. You will automatically receive the
            upgraded TURF in your wallet once the transaction is complete!
          </Typography>
          <LoadingButton
            fullWidth
            sx={{ color: (theme) => theme.palette.common.white }}
            className="gradient"
            loading={loading}
            onClick={async () => {
              setLoading(true);
              try {
                await submitMergeTransaction(lucid!, assetIds, paymentAddress);
                setSuccess(true);
                setMapMode("DEFAULT");
                resetReservation();
              } finally {
                setLoading(false);
              }
            }}
          >
            Submit
          </LoadingButton>
        </>
      )}
    </div>
  );
}
