import { useEffect, useRef } from "react";

export default ({ formId }: { formId: string }) => {
  const myRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const script = document.createElement("script");

    script.src = `https://eomail6.com/form/${formId}.js`;
    script.async = true;
    script.setAttribute("data-form", formId);

    const myRefNode = myRef.current;

    myRefNode?.appendChild(script);

    return () => {
      const nodes = document.querySelectorAll(`[data-form="${formId}"]`);
      nodes.forEach(function (node) {
        node.parentNode?.removeChild(node);
      });
    };
  }, [formId]);

  return <div ref={myRef}></div>;
};
