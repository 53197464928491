import { ArrowBackIosNew, Close } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useReservationStore } from "../../stores/reservationStore";
import { useUiStore } from "../../stores/uiStore";

export default function MergeInstruction() {
  const [setMyTurfsContent, setSidebar, setMapMode] = useUiStore((state) => [
    state.setMyTurfsContent,
    state.setSidebar,
    state.setMapMode,
  ]);

  const resetReservation = useReservationStore(
    (state) => state.resetReservation
  );

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          mb: 2,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            onClick={() => setMyTurfsContent("DETAIL")}
            sx={{ mr: 1 }}
          >
            <ArrowBackIosNew />
          </IconButton>
          <Typography variant="h5" fontWeight={700}>
            Merge your TURFs
          </Typography>
        </Box>
        <IconButton onClick={() => setSidebar("")}>
          <Close />
        </IconButton>
      </Box>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Instructions
      </Typography>
      <Typography sx={{ mb: 2 }}>
        To combine multiple TURFs into one, please select them on the map and
        click the <b>"Merge"</b> in the basket. Valid dimensions are 2x2, 3x3,
        4x4 and 5x5 individual TURF squares.
      </Typography>
      <Typography sx={{ mb: 4 }}>
        Any items you currently have in your basket will be removed.
      </Typography>
      <Button
        className="gradient"
        onClick={() => {
          resetReservation();
          setMapMode("MERGE");
          setSidebar("");
        }}
        fullWidth
      >
        Start merge
      </Button>
    </Box>
  );
}
