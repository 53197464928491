import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Grid,
  MenuItem,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import * as yup from "yup";
import { RecipientDetails } from "../../../definitions";
import { COUNTRIES, CS22_POLICY_ID, STORAGE_BUCKET } from "../../config";
import { useWalletStore } from "../../stores/walletStore";

const recipientDetailsInfo: {
  key: keyof RecipientDetails;
  validation: yup.AnySchema;
  label: string;
}[] = [
  {
    key: "recipientName",
    validation: yup.string().min(3).required(),
    label: "Full name",
  },
  {
    key: "recipientEmail",
    validation: yup.string().email().required(),
    label: "Email address",
  },
  {
    key: "address1",
    validation: yup.string().min(5).required(),
    label: "Address 1",
  },
  { key: "address2", validation: yup.string(), label: "Addres 2" },
  {
    key: "town",
    validation: yup.string().min(3).required(),
    label: "City/Town",
  },
  { key: "stateCounty", validation: yup.string(), label: "State/County" },
  {
    key: "countryCode",
    validation: yup
      .string()
      .default("NL")
      .oneOf(
        COUNTRIES.map((c) => c.code),
        "Value is not a valid option"
      ),
    label: "Country",
  },
  { key: "postcode", validation: yup.string().required(), label: "Postcode" },
  {
    key: "quantity",
    validation: yup.number().default(1).min(1).max(10),
    label: "Quantity",
  },
];

const validateField = (validation: yup.AnySchema, val: any) => {
  let message = "";
  try {
    validation.validateSync(val);
  } catch (error: any) {
    message = error.message;
  }
  return message;
};

const Form = ({
  onSubmit,
  selectedAsset,
}: {
  onSubmit: (recipientDetails: Partial<RecipientDetails>) => Promise<void>;
  selectedAsset: { assetId: string; freePrint: boolean; policyId: string };
}) => {
  const [error, setError] = useState("");
  const lucid = useWalletStore((state) => state.lucid);
  const [recipientDetails, setRecipientDetails] = useState(
    Object.fromEntries(
      recipientDetailsInfo.map((r) => [r.key, r.validation.getDefault() || ""])
    )
  );

  const [recipientDetailsErrors, setRecipientDetailsErrors] =
    useState<RecipientDetails>({});

  const [loading, setLoading] = useState(false);

  const usedFields = recipientDetailsInfo.filter(
    (info) => info.key !== "quantity" || !selectedAsset.freePrint
  );

  let assetId = selectedAsset.assetId;
  assetId = assetId.replace("TURF", "");
  const locationName = assetId.replace(/\d*/g, "");
  const locationCode = assetId.substring(0, 8) + "+" + assetId.substring(8, 10);
  const image =
    selectedAsset.policyId === CS22_POLICY_ID
      ? `https://storage.googleapis.com/mapmaker-330220.appspot.com/${locationName}.png`
      : `${STORAGE_BUCKET}/${locationCode}_art.png`;

  if (!lucid) {
    return <Navigate to="/claim-your-print" />;
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <img src={image} style={{ boxShadow: "4px 5px 8px rgba(0,0,0,0.3)" }} />
      </Grid>
      <Grid item xs={12} sm={8}>
        <Typography variant={"h5"}>
          Provide your shipping information for {selectedAsset.assetId}
        </Typography>
        {selectedAsset.freePrint && (
          <Typography variant={"body1"}>
            On submitting the form we will ask you to submit a wallet
            transaction. This is to verify that you actually own the NFT,{" "}
            <i>
              No funds will be transferred, only transaction costs are charged.
            </i>
          </Typography>
        )}
        {usedFields.map((info) => {
          if (info.key === "quantity" && selectedAsset.freePrint) {
            return null;
          }
          return (
            <Box key={info.key}>
              <TextField
                label={info.label}
                variant="filled"
                margin="normal"
                fullWidth
                select={info.key === "countryCode"}
                onBlur={(e) => {
                  const message = validateField(
                    info.validation,
                    e.target.value
                  );
                  setRecipientDetailsErrors({
                    ...recipientDetailsErrors,
                    [info.key]: message,
                  });
                }}
                onChange={(e) => {
                  setRecipientDetails({
                    ...recipientDetails,
                    [info.key]: e.target.value,
                  });

                  if (recipientDetailsErrors[info.key]) {
                    const message = validateField(
                      info.validation,
                      e.target.value
                    );
                    setRecipientDetailsErrors({
                      ...recipientDetailsErrors,
                      [info.key]: message,
                    });
                  }
                }}
                error={!!recipientDetailsErrors[info.key]}
                helperText={recipientDetailsErrors[info.key]}
                value={recipientDetails[info.key]}
              >
                {info.key === "countryCode" &&
                  COUNTRIES.map((country) => (
                    <MenuItem key={country.code} value={country.code}>
                      {country.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Box>
          );
        })}
        <LoadingButton
          onClick={async () => {
            const errors: RecipientDetails = {};
            setLoading(true);
            usedFields.forEach((info) => {
              const error = validateField(
                info.validation,
                recipientDetails[info.key]
              );
              if (error) errors[info.key] = error;
            });

            if (Object.keys(errors).length > 0) {
              setRecipientDetailsErrors(errors);
              setLoading(false);
              return;
            }

            try {
              await onSubmit(recipientDetails);
            } catch (e: any) {
              if (e === "InputsExhaustedError")
                e = "Not enough funds to complete the transaction.";

              setError(e.message || e.info || e);
            } finally {
              setLoading(false);
            }
          }}
          variant="contained"
          size="large"
          loading={loading}
        >
          Submit
        </LoadingButton>
        <Snackbar
          onClose={() => setError("")}
          autoHideDuration={5000}
          open={!!error}
        >
          <Alert severity="error">{error}</Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
};

export default Form;
