import { Twitter } from "@mui/icons-material";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";

export default function Success() {
  const tweet = `I just ordered a print of my TURF NFT!%0a%0aGet your own now!%0a%0a`;
  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Success!
      </Typography>
      <Typography sx={{ mb: 2 }}>
        You will receive an email confirmation of your order soon. In the mean
        time consider letting the world know you got a TURF!
      </Typography>
      <Button
        href={`https://twitter.com/intent/tweet?url=https%3A%2F%2Fmint.turfnft.com/claim-your-print%2F&via=TURF_NFT&text=${tweet}&hashtags=CardanoSummit2022,SummitCNFT22,CNFT`}
        startIcon={<Twitter />}
        target="_blank"
        size="large"
        sx={{
          color: (theme) => theme.palette.common.white,
          marginBottom: 2,
        }}
      >
        Tweet about it
      </Button>
    </>
  );
}
