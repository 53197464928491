import { Close, Error, Menu, Search } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { RawResult } from "leaflet-geosearch/dist/providers/openStreetMapProvider";
import { SearchResult } from "leaflet-geosearch/dist/providers/provider";
import { debounce, uniqBy } from "lodash";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { WlcPalette } from "../config";
import { useUiStore } from "../stores/uiStore";
import LinkList from "./LinkList";
import OpenLocationCode from "./OpenLocationCode";

const barHeight = 67;

export default function SearchBar() {
  const [search, setSearch] = useSearchParams();
  const [
    menuOpen,
    setMenuOpen,
    gridError,
    loadingGridSquares,
    setShowWelcomeScreen,
  ] = useUiStore((state) => [
    state.menuOpen,
    state.setMenuOpen,
    state.gridError,
    state.loadingGridSquares,
    state.setShowWelcomeScreen,
  ]);
  const [searchResult, setSearchResult] = useState<SearchResult<RawResult>[]>(
    []
  );
  const [hoveredResult, sethoveredResult] = useState<number>();
  const [loadingResults, setLoadingResults] = useState(false);
  const provider = new OpenStreetMapProvider();

  return (
    <Box
      sx={{
        position: "absolute",
        zIndex: 99,
        m: {
          xs: 1,
          sm: 2,
        },
        maxWidth: 400,
      }}
    >
      <Box sx={{ position: "relative", zIndex: 9 }}>
        <Box
          sx={{
            backgroundColor: "background.paper",
            display: "flex",
            borderRadius: 3,
            width: "100%",
            alignItems: "center",
            boxShadow: 3,
            height: barHeight,
          }}
        >
          <IconButton
            sx={{ m: 2, color: "text.primary" }}
            disableRipple={true}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <Close
              sx={{
                position: "absolute",
                opacity: menuOpen ? 1 : 0,
                transform: menuOpen ? "scale(1)" : "scale(0)",
                transition: "all 0.3s",
              }}
            />
            <Menu
              sx={{
                position: "absolute",
                opacity: menuOpen ? 0 : 1,
                transform: menuOpen ? "scale(0)" : "scale(1)",
                transition: "all 0.3s",
              }}
            />
          </IconButton>
          <Box
            sx={{
              typography: "h3",
              lineHeight: 1,
              borderRight: "1px solid",
              borderLeft: "1px solid",
              borderColor: "divider",
              px: 2,
              pt: 1.5,
              pb: 1,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              cursor: "pointer",
              height: barHeight,
              display: "flex",
              alignItems: "center",
            }}
            className="gradient"
            onClick={() => setShowWelcomeScreen(true)}
          >
            TURF
          </Box>
          <Box
            sx={{
              display: "flex",
              mx: 2,
              alignItems: "center",
              position: "relative",
              width: "100%",
            }}
          >
            <Search sx={{ fill: "text.primary" }} />
            <Box
              sx={{
                ml: 1,
                width: "100%",
                overflow: "hidden",
                border: 0,
                outline: "none",
                height: "2em",
                typography: "body1",
                backgroundColor: "background.paper",
              }}
              component="input"
              onChange={debounce(async (e) => {
                setLoadingResults(true);
                try {
                  const result = await provider.search({
                    query: e.target.value,
                  });
                  setSearchResult(result);
                } finally {
                  setLoadingResults(false);
                }
              }, 300)}
              placeholder="Find your TURF"
            />
            {(loadingGridSquares || loadingResults) && (
              <CircularProgress
                sx={{ position: "absolute", right: 0 }}
                size={20}
              />
            )}
            {gridError && !loadingGridSquares && (
              <Tooltip
                style={{
                  position: "absolute",
                  right: 0,
                  color: WlcPalette[1],
                }}
                title={gridError}
              >
                <Error color="inherit" />
              </Tooltip>
            )}
          </Box>

          {searchResult.length > 0 && (
            <Box
              sx={{
                position: "absolute",
                top: "100%",
                backgroundColor: "background.paper",
                borderRadius: "1em",
                marginTop: "1em",
                padding: "1em",
                maxWidth: "100%",
              }}
            >
              {Boolean(searchResult) &&
                uniqBy(searchResult, (res) => res.label).map((res, index) => (
                  <Box key={res.raw.osm_id}>
                    <Box
                      onClick={() => {
                        res.bounds &&
                          setSearch({
                            ...search,
                            locationCode: OpenLocationCode.encode(res.y, res.x),
                            zoom: "12",
                          });
                        setSearchResult([]);
                      }}
                      sx={{
                        padding: "1em",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        color:
                          hoveredResult === index ? "primary.main" : "inherit",
                        cursor: "pointer",
                      }}
                      onMouseEnter={(e) => sethoveredResult(index)}
                      onMouseLeave={() => sethoveredResult(undefined)}
                    >
                      {res.label}
                    </Box>
                    <Divider />
                  </Box>
                ))}
            </Box>
          )}
        </Box>
      </Box>
      <LinkList />
    </Box>
  );
}
