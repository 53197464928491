import Typography from "@mui/material/Typography";

export default function Empty() {
  return (
    <>
      <img src={`${process.env.PUBLIC_URL}/empty.jpg`} />
      <Typography variant="body1" sx={{ marginTop: 2 }}>
        Your cart is empty! Please add some TURFs to continue.
      </Typography>
    </>
  );
}
