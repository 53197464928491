import { Close } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { doc, onSnapshot } from "firebase/firestore";
import { utf8ToHex } from "lucid-cardano";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Turf } from "../../definitions";
import { IPFS_GATEWAY, POLICY_ID, POLYGON_CONTRACT_ADDRESS } from "../config";
import { productPermissionsCol } from "../firebase.config";
import { getTurfsByAssetIds } from "../my-api";
import { useReservationStore } from "../stores/reservationStore";
import { useUiStore } from "../stores/uiStore";

const setPageMeta = (turf: Turf) => {
  const oldValues = {
    title: document.querySelector("title")?.innerText || "",
    description:
      document
        .querySelector('meta[name="description"]')
        ?.getAttribute("content") || "",
    image:
      document
        .querySelector('meta[name="og:image"]')
        ?.getAttribute("content") || "",
    url:
      document.querySelector('meta[name="og:url"]')?.getAttribute("content") ||
      "",
  };

  const newValues = {
    title: turf.nftName || oldValues.title,
    description: `Beautiful cartographic art of ${turf.placeName}, ${turf.countryName}.`,
    image: turf.artThumbUrl || oldValues.image,
    url: window.location.href.split("?")[0],
  };

  const tags: { property: string; content: keyof typeof newValues }[] = [
    { property: "og:url", content: "url" },
    { property: "og:description", content: "description" },
    { property: "og:image", content: "image" },
    { property: "twitter:url", content: "url" },
    { property: "twitter:title", content: "title" },
    { property: "twitter:description", content: "description" },
    { property: "twitter:image", content: "image" },
  ];

  document.title = newValues.title;
  document
    .querySelector('meta[name="description"]')
    ?.setAttribute("content", newValues.description);

  for (const tag of tags) {
    document
      .querySelector(`meta[name="${tag.property}"]`)
      ?.setAttribute("content", newValues[tag.content]);
  }

  return () => {
    document.title = oldValues.title;
    document
      .querySelector('meta[name="description"]')
      ?.setAttribute("content", oldValues.description);

    for (const tag of tags) {
      document
        .querySelector(`meta[name="${tag.property}"]`)
        ?.setAttribute("content", oldValues[tag.content]);
    }
  };
};

const ArtPreviewImage = ({
  bg,
  fg,
  x,
  y,
  scale,
}: {
  bg: string;
  fg: string;
  x: number;
  y: number;
  scale: number;
}) => {
  const [dataUrl, setDataUrl] = useState("");

  useEffect(() => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const backgroundImg = new Image();
    backgroundImg.onload = function () {
      if (backgroundImg.complete) {
        canvas.width = backgroundImg.width;
        canvas.height = backgroundImg.height;
        ctx?.drawImage(backgroundImg, 0, 0);
        setDataUrl(canvas.toDataURL());

        const foregroundImg = new Image();
        foregroundImg.onload = function () {
          if (foregroundImg.complete) {
            ctx?.drawImage(
              foregroundImg,
              x,
              y,
              foregroundImg.width * scale * 1.025,
              foregroundImg.height * scale
            );
            setDataUrl(canvas.toDataURL());
          }
        };
        foregroundImg.src = fg;
        foregroundImg.setAttribute("crossorigin", "anonymous");
      }
    };
    backgroundImg.src = bg;
  }, [bg, fg, x, y, scale]);

  return <img src={dataUrl} alt="Printed art preview" />;
};

export default function InteractiveArtModal() {
  const params = useParams();
  const navigate = useNavigate();
  const [search, setSearch] = useSearchParams();
  const [turf, setTurf] = useState<Turf & { id: string }>();
  const [loading, setLoading] = useState(false);
  // const [artPreviewUrl, setArtPreviewUrl] = useState("");
  const [mediaTab, setMediaTab] = useState<"ART" | "3D">("ART");

  const [exchangeRates, currency, setMapMode] = useUiStore((state) => [
    state.exchangeRates,
    state.currency,
    state.setMapMode,
  ]);
  const exchangeRate = exchangeRates.find((er) => er.target === currency);

  const [addProductToCart] = useReservationStore((state) => [
    state.addProductToCart,
  ]);
  const setSidebar = useUiStore((state) => state.setSidebar);

  const cardanoUrl = `https://www.jpg.store/asset/${POLICY_ID}${utf8ToHex(
    "TURF" + turf?.assetId
  )}#:~:text=Make Offer`;

  const polygonUrl = `https://opensea.io/assets/matic/${POLYGON_CONTRACT_ADDRESS}/${turf?.nftUid}`;

  const [ownerHasOptedOut, setOwnerHasOptedOut] = useState(false);
  useEffect(
    () =>
      onSnapshot(doc(productPermissionsCol, params.assetId), (snapshot) => {
        if (snapshot.exists()) {
          setOwnerHasOptedOut(snapshot.data()?.ownerHasOptedOut);
        }
      }),
    [turf?.assetId]
  );

  useEffect(() => {
    if (params.assetId) {
      setLoading(true);
      getTurfsByAssetIds([params.assetId])
        .then((turfs) => {
          if (turfs.length > 0) {
            setSearch({
              ...search,
              locationCode: turfs[0].locationCode,
              zoom: "12",
            });
            setTurf(turfs[0]);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [params.assetId]);

  useEffect(() => {
    if (turf) {
      return setPageMeta(turf);
    }
  }, [turf]);

  const onClose = () => navigate(`/${window.location.search}`);
  return (
    <Dialog
      open={Boolean(params.assetId)}
      onClose={onClose}
      maxWidth="lg"
      PaperProps={{
        sx: {
          m: { xs: 1, sm: 2 },
          p: 4,
          maxHeight: "none",
          height: (theme) => ({
            xs: `calc(100% - ${theme.spacing(2)})`,
            sm: `calc(100% - ${theme.spacing(4)})`,
          }),
          width: (theme) => ({
            xs: `calc(100vw - ${theme.spacing(2)})`,
            sm: `calc(100vw - ${theme.spacing(4)})`,
            md: 960,
          }),
        },
      }}
      closeAfterTransition={true}
      keepMounted={false}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 2,
        }}
      >
        <IconButton
          sx={{ position: "absolute", right: 0, mr: 4 }}
          onClick={onClose}
        >
          <Close />
        </IconButton>
        <Typography
          variant="h2"
          sx={{
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            textAlign: "center",
          }}
          className="gradient"
        >
          TURF
        </Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={7}>
          <Box
            sx={{
              borderRadius: 2,
              overflow: "hidden",
              mb: 1,
              lineHeight: 0,
              display: mediaTab === "ART" ? "block" : "none",
            }}
          >
            <ArtPreviewImage
              bg={`${process.env.PUBLIC_URL}/art_bg.png`}
              fg={turf?.artThumbUrl || ""}
              x={194.5}
              y={46.6}
              scale={0.695}
            />
          </Box>
          <Box
            sx={{
              position: "relative",
              overflow: "hidden",
              width: "100%",
              pt: "100%",
              borderRadius: 2,
              mb: 1,
              display: mediaTab === "3D" ? "block" : "none",
            }}
          >
            <CircularProgress
              sx={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 0,
              }}
            />
            {!!turf?.ipfsHashHtml && (
              <iframe
                title="interactive TURF"
                src={`${IPFS_GATEWAY}/${turf?.ipfsHashHtml}`}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  width: "100%",
                  height: "100%",
                  border: 0,
                }}
              />
            )}
          </Box>
          <Link
            component="button"
            sx={{
              mr: 2,
              textDecoration: mediaTab === "ART" ? "underline" : "none",
            }}
            onClick={() => setMediaTab("ART")}
          >
            Printed art
          </Link>
          <Link
            component="button"
            sx={{
              mr: 2,
              textDecoration: mediaTab === "3D" ? "underline" : "none",
            }}
            onClick={() => setMediaTab("3D")}
          >
            3D art
          </Link>
        </Grid>
        <Grid item xs md>
          {/* <Box sx={{ mb: 4 }}>
            <Tooltip
              title={
                ownerHasOptedOut
                  ? "The owner of this TURF does not want others to order prints"
                  : ""
              }
            >
              <span>
                <LoadingButton
                  className="gradient"
                  size="large"
                  fullWidth
                  onClick={async () => {
                    setLoading(true);
                    turf && (await addProductToCart(turf.id, 1));
                    setSidebar("CHECKOUT");
                    onClose();
                  }}
                  disabled={ownerHasOptedOut}
                  loading={loading}
                  sx={{ mb: 1, color: "white" }}
                >
                  Buy fine art print (
                  {exchangeRate?.element ? (
                    <exchangeRate.element />
                  ) : (
                    exchangeRate?.sign
                  )}{" "}
                  {Math.floor(PRINT_PRICE_EUR * (exchangeRate?.value || 1))})
                </LoadingButton>
              </span>
            </Tooltip>
            <Typography variant="caption" component="div">
              You can customize the title and colors of the TURF when buying a
              print. You can also{" "}
              <Link
                component="button"
                onClick={() => {
                  setMapMode("PRINT");
                  onClose();
                }}
              >
                select custom squares
              </Link>{" "}
              to create your own artwork.
            </Typography>
          </Box> */}
          <Table sx={{ mb: 2 }}>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>Place name:</TableCell>
                <TableCell>{turf?.placeName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>Country:</TableCell>
                <TableCell>{turf?.countryName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>Squares:</TableCell>
                <TableCell>{turf?.squares.length}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  <Link
                    href="https://maps.google.com/pluscodes/"
                    target="_blank"
                  >
                    Location code:
                  </Link>
                </TableCell>
                <TableCell>{turf?.locationCode}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>Minted on:</TableCell>
                <TableCell>
                  {turf?.mintedDate.toDate().toLocaleString("en-US", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }} colSpan={2}>
                  {turf?.chain === "POLYGON" ? (
                    <Link href={polygonUrl} target="_blank">
                      Buy NFT on OpenSea
                    </Link>
                  ) : (
                    <Link href={cardanoUrl} target="_blank">
                      Buy NFT on jpg.store
                    </Link>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Typography variant="caption">
            Share your TURF art with the world:
          </Typography>
          <Box>
            {["facebook", "twitter", "whatsapp", "linkedin"].map((sm) => (
              <Link
                key={sm}
                sx={{ mr: 1 }}
                target="_blank"
                href={`https://www.addtoany.com/add_to/${sm}?linkurl=https%3A%2F%2Fturfnft.com%2Fart%2F${turf?.assetId}&amp;linkname=TURF%20-%20Map%20art%20you%20truly%20own`}
              >
                <img
                  src={`https://static.addtoany.com/buttons/${sm}.svg`}
                  alt={sm}
                  width={32}
                  height={32}
                  style={{ backgroundColor: "#d1d1d1", borderRadius: "4px" }}
                />
              </Link>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
}
