import { BBox } from "@turf/helpers";
import { latLng, latLngBounds } from "leaflet";
import { ImageOverlay, Tooltip, useMap } from "react-leaflet";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getMintedTurfs } from "../my-api";
import OpenLocationCode from "./OpenLocationCode";

export default function MintedTurfs({ opacity = 1 }) {
  const { data: turfs = [] } = useQuery("turfs", getMintedTurfs);
  const map = useMap();
  const navigate = useNavigate();
  return (
    <>
      {turfs.map((turf) => {
        if (!turf.locationCode) {
          return null;
        }

        const location = OpenLocationCode.decode(turf.locationCode);
        const isWithinBounds = map
          .getBounds()
          .contains(latLng(location.latitudeCenter, location.longitudeCenter));

        if (!isWithinBounds) {
          return null;
        }

        const bbox = turf.bounds.split(",").map(parseFloat) as BBox;
        const bounds = latLngBounds([
          [bbox[1], bbox[0]],
          [bbox[3], bbox[2]],
        ]);

        return (
          !!turf.thumbUrl && (
            <ImageOverlay
              key={turf.bounds}
              bounds={bounds}
              url={turf.thumbUrl}
              interactive={true}
              eventHandlers={{
                click: () =>
                  navigate(
                    `/interactive/${turf.assetId}${window.location.search}`
                  ),
              }}
              opacity={opacity}
            >
              <Tooltip direction="top" opacity={1}>
                <div style={{ textAlign: "center" }}>
                  <div>
                    <span
                      style={{
                        background: "gray",
                        color: "white",
                        borderRadius: 6,
                        padding: 6,
                      }}
                    >
                      sold 😍
                    </span>
                  </div>
                </div>
              </Tooltip>
            </ImageOverlay>
          )
        );
      })}
    </>
  );
}
