import { Box, CircularProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useCallback, useEffect, useState } from "react";
import { useReservationStore } from "../../stores/reservationStore";

export default function ProcessingStep() {
  const turfs = useReservationStore((state) => state.turfs);

  const thumbs = turfs.map((turf) => turf.artThumbUrl || "");

  const [artThumbUrl, setArtThumbUrl] = useState(
    thumbs.length > 0 ? thumbs[0] : ""
  );

  const rotateArtThumbUrl = useCallback(() => {
    const index = thumbs.indexOf(artThumbUrl);
    setArtThumbUrl(
      index === -1 || index === thumbs.length - 1
        ? thumbs[0]
        : thumbs[index + 1]
    );
  }, [thumbs, artThumbUrl]);

  useEffect(() => {
    const interval = setInterval(rotateArtThumbUrl, 2000);
    return () => clearInterval(interval);
  }, [thumbs, rotateArtThumbUrl]);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "2em",
        }}
      >
        <Typography variant="h5" fontFamily="Roboto" fontWeight={700}>
          Painting your TURF
        </Typography>
      </Box>
      <Typography
        style={{
          margin: "1em auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        variant="body1"
        component="div"
      >
        This might take a few minutes - please bear with us as our overloaded
        servers generate all the goodies you get with a TURF:
        <ol>
          <li>An interactive 3D art experience</li>
          <li>A rotating GIF of the 3D art</li>
          <li>
            A HQ file download of the image below that’s ready to be printed on
            fine art paper
          </li>
          <li>An NFT certifying your ownership of your piece of the world</li>
        </ol>
      </Typography>
      <div style={{ textAlign: "center" }}>
        <Box
          sx={{
            marginBottom: "1em",
            background: "#FBF3DF",
            padding: "1em",
            borderRadius: "16px",
            position: "relative",
            textAlign: "center",
          }}
        >
          <div style={{ position: "absolute" }}>
            <CircularProgress size={20} />
          </div>
          {artThumbUrl && (
            <img
              src={artThumbUrl}
              alt="art preview"
              style={{ width: "50%", boxShadow: "4px 5px 8px rgba(0,0,0,0.3)" }}
            />
          )}
        </Box>

        <br />
      </div>
    </div>
  );
}
