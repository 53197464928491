import { Warning } from "@mui/icons-material";
import { Box, Container, Link, Typography } from "@mui/material";
import { utf8ToHex } from "lucid-cardano";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PrintOrder } from "../../../definitions";
import { CS22_POLICY_ID } from "../../config";
import { getPrintOrders } from "../../my-api";

export default function ClaimedPrints() {
  const [search] = useSearchParams();
  const [printOrders, setPrintOrders] = useState<
    Array<PrintOrder & { id: string }>
  >([]);
  useEffect(() => {
    getPrintOrders().then((res) => setPrintOrders(res));
  });

  const printId = search.get("id");

  const printOrder = printOrders.find((p) => p.id === printId);
  return (
    <Container
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {printId && printOrder?.status === "AVAILABLE" ? (
        <Typography variant="h5" sx={{ marginBottom: 4 }}>
          The print for this NFT is still available!
        </Typography>
      ) : printId ? (
        <Typography variant="h5" sx={{ marginBottom: 4 }}>
          <Warning /> Someone has already claimed the print for {printOrder?.id}{" "}
          <Warning />
        </Typography>
      ) : (
        <Typography variant="h5" sx={{ marginBottom: 4 }}>
          Please check the ID below to ensure your NFT is still eligible for the
          free print.
        </Typography>
      )}
      {printOrders.map((printOrder) => (
        <Box key={printOrder.id}>
          <Link
            sx={
              printOrder.status !== "AVAILABLE"
                ? { color: "GrayText", textDecoration: "line-through" }
                : {}
            }
            href={
              printOrder.status === "AVAILABLE"
                ? `https://www.jpg.store/asset/${CS22_POLICY_ID}${utf8ToHex(
                    printOrder.id
                  )}`
                : `https://cardanoscan.io/token/${CS22_POLICY_ID}.${printOrder.id}`
            }
            target="_blank"
          >
            {printOrder.id}
          </Link>
        </Box>
      ))}
    </Container>
  );
}
