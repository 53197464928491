import { useUiStore } from "../../stores/uiStore";
import MergeInstruction from "./MergeInstruction";
import TurfDetail from "./TurfDetail";
import TurfsList from "./TurfsList";

export default function MyTurfsSidebar() {
  const page = useUiStore((state) => state.myTurfsContent);
  const pageMap = {
    LIST: <TurfsList />,
    DETAIL: <TurfDetail />,
    MERGE_INSTRUCTION: <MergeInstruction />,
    // SELL_PRINTS: <SellPrints />,
  };

  return <>{pageMap[page]}</>;
}
