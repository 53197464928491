import {
  ArrowBackIosNew,
  ArrowForwardIos,
  Check,
  DeleteOutline,
  InfoRounded,
} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Link,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Turf } from "../../../definitions";
import { FREE_PRINT_EUR, PRINT_PRICE_EUR } from "../../config";
import {
  changeProductQuantity,
  removeTurfFromReservation,
  updateArtThumb,
} from "../../my-api";
import { useReservationStore } from "../../stores/reservationStore";
import { useUiStore } from "../../stores/uiStore";

const Timer = () => {
  const [currentReservation, resetReservation] = useReservationStore(
    (state) => [state.currentReservation, state.resetReservation]
  );
  const [remainingTime, setRemainingTime] = useState(0);
  const minutes = Math.floor(remainingTime / (1000 * 60));
  const seconds = Math.round((remainingTime % (1000 * 60)) / 1000);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(
        currentReservation?.expirationTime.toMillis() - new Date().valueOf()
      );
      if (remainingTime < 0) {
        resetReservation();
      }
    }, 1000);

    return () => clearInterval(interval);
  });
  return (
    <>
      {Boolean(remainingTime) && (
        <Typography
          variant="caption"
          sx={{ marginTop: 2, color: "text.primary" }}
          component="div"
        >
          This TURF is reserved for you alone. You have{" "}
          <b>
            {minutes}:{seconds >= 10 ? seconds : `0${seconds}`}
          </b>{" "}
          minutes to continue to the next step.
        </Typography>
      )}
    </>
  );
};

const TurfTile = ({
  turf,
  loading,
  setLoading,
}: {
  turf: Turf & { id: string };
  loading: boolean;
  setLoading: (loading: boolean) => void;
}) => {
  const [
    selectedAddressKeys,
    setSelectedAddressKeys,
    turfs,
    resetReservation,
    products,
    currentReservation,
  ] = useReservationStore((state) => [
    state.selectedAddressKeys,
    state.setSelectedAddressKeys,
    state.turfs,
    state.resetReservation,
    state.products,
    state.currentReservation,
  ]);

  const [setSidebar, setMapMode, currency, exhcangeRates] = useUiStore(
    (state) => [
      state.setSidebar,
      state.setMapMode,
      state.currency,
      state.exchangeRates,
    ]
  );

  const exchangeRate = exhcangeRates.find((er) => er.target === currency);

  const setError = useUiStore((state) => state.setError);
  const [deleting, setDeleting] = useState(false);
  const [nameEditOpen, setNameEditOpen] = useState(false);
  const [customName, setCustomName] = useState<string>();
  const [customNameInput, setCustomNameInput] = useState(false);

  const validPlaceNames: { key: string; value: string }[] = JSON.parse(
    turf.validPlaceNames || "[]"
  );
  useEffect(() => {
    if (
      !Object.keys(selectedAddressKeys).includes(turf.id) &&
      validPlaceNames.length > 0
    ) {
      setSelectedAddressKeys(turf.id, validPlaceNames[0].key);
    }
  }, [turf, validPlaceNames, selectedAddressKeys, setSelectedAddressKeys]);

  const updateThumb = async (
    turfId: string,
    addressKey: string,
    shuffleColors?: boolean
  ) => {
    setLoading(true);
    try {
      await updateArtThumb({
        turfId,
        addressKey,
        shuffleColors,
      });
      setSelectedAddressKeys(turfId, addressKey);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      key={turf.assetId}
      sx={{
        display: "flex",
        alignItems: "center",
        marginBottom: "1em",
        background: "#FBF3DF",
        padding: "1em",
        borderRadius: "16px",
        position: "relative",
        flexDirection: "column",
      }}
    >
      <IconButton
        onClick={async () => {
          setDeleting(true);
          try {
            await removeTurfFromReservation({ turfId: turf.id });
            if (
              currentReservation?.desiredSquares.length === turf.squares.length
            ) {
              resetReservation();
              setMapMode("DEFAULT");
              setSidebar("");
            }
          } catch (e: any) {
            setError(e.message);
          } finally {
            setDeleting(false);
          }
        }}
        disabled={deleting}
        sx={{
          position: "absolute",
          right: "4px",
          top: "4px",
        }}
      >
        <DeleteOutline color="inherit" />
      </IconButton>
      <Typography
        sx={{
          position: "absolute",
          left: (theme) => theme.spacing(2),
          top: (theme) => theme.spacing(2),
        }}
      >
        {exchangeRate?.element ? <exchangeRate.element /> : exchangeRate?.sign}{" "}
        {(
          (turf.priceEur +
            products
              .filter((p) => p.turfId === turf.id)
              .reduce(
                (prev, curr) => prev + curr.quantity * curr.priceEur,
                0
              )) *
          (exchangeRate?.value || 1)
        ).toFixed(0)}
      </Typography>
      {turf.artThumbUrl ? (
        <>
          <Box
            sx={{
              mb: 2,
              textAlign: "center",
              overflow: "hidden",
              position: "relative",
              width: "50%",
              boxShadow: "4px 5px 8px rgba(0,0,0,0.3)",
              lineHeight: 0,
            }}
          >
            <img
              src={
                // query param to prevent browser caching
                turf.artThumbUrl + `?addressKey=${selectedAddressKeys[turf.id]}`
              }
              alt="Your TURF art"
              style={{
                width: "100%",
                opacity: loading ? 0.5 : 1,
              }}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              disabled={loading}
              size="small"
              variant="text"
              onClick={async () => {
                setLoading(true);
                await updateThumb(turf.id, selectedAddressKeys[turf.id], true);
                setLoading(false);
                setNameEditOpen(false);
              }}
            >
              Shuffle colors
            </Button>
          </Box>

          {!customNameInput && (
            <>
              <Typography
                variant="caption"
                component="div"
                sx={{
                  textAlign: "left",
                  width: "100%",
                  marginBottom: 1,
                  marginLeft: 1,
                }}
              >
                Select the name to show on your TURF
              </Typography>
              <Box sx={{ display: "flex", width: "100%" }}>
                <Select
                  IconComponent={() => (
                    <ArrowBackIosNew
                      sx={{
                        fontSize: 20,
                        transform: "rotate(-90deg)",
                        padding: "4px",
                        marginRight: "12px",
                      }}
                    />
                  )}
                  sx={{
                    background: (theme) => theme.palette.common.white,
                    mb: 1,
                  }}
                  fullWidth
                  size="small"
                  value={selectedAddressKeys[turf.id] || ""}
                  onChange={async (e) => {
                    updateThumb(turf.id, e.target.value);
                  }}
                  disabled={loading}
                >
                  {validPlaceNames.map((place) => (
                    <MenuItem value={place.key} key={place.value}>
                      {place.value}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Typography
                variant="caption"
                sx={{ ml: 1, mb: 2, textAlign: "left", width: "100%" }}
              >
                Don't see the name of your choice?{" "}
                <Link
                  component="button"
                  sx={{ fontWeight: 700 }}
                  onClick={() => setCustomNameInput(true)}
                >
                  Enter a custom name
                </Link>
              </Typography>
            </>
          )}
          {customNameInput && (
            <>
              <Typography
                variant="caption"
                component="div"
                sx={{
                  textAlign: "left",
                  width: "100%",
                  marginBottom: 1,
                  marginLeft: 1,
                }}
              >
                Enther the name you want to show on your TURF{" "}
                <Tooltip
                  title={`TURFs with custom names will show a subtle "Personalized" tag on the front of the 3D art.`}
                >
                  <InfoRounded fontSize="inherit" />
                </Tooltip>
              </Typography>
              <TextField
                autoFocus
                onFocus={(event) => {
                  event.target.select();
                }}
                fullWidth
                size="small"
                sx={{
                  mb: 1,
                  background: (theme) => theme.palette.common.white,
                  pr: 0,
                }}
                value={
                  customName !== undefined
                    ? customName
                    : validPlaceNames.find(
                        (v: { key: string; value: string }) =>
                          v.key === selectedAddressKeys[turf.id]
                      )?.value
                }
                disabled={loading}
                onChange={async (e) => {
                  setCustomName(e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <LoadingButton
                      sx={{ minWidth: 0 }}
                      loading={loading}
                      onClick={async () => {
                        setLoading(true);
                        await updateThumb(turf.id, customName || "", true);
                        setLoading(false);
                        setNameEditOpen(false);
                      }}
                    >
                      <Check />
                    </LoadingButton>
                  ),
                }}
              />
              <Typography
                variant="caption"
                sx={{ ml: 1, mb: 2, textAlign: "left", width: "100%" }}
              >
                Want to select from the dropdown?{" "}
                <Link
                  component="button"
                  sx={{ fontWeight: 700 }}
                  onClick={() => setCustomNameInput(false)}
                >
                  Show official TURF naming options
                </Link>
              </Typography>
            </>
          )}
          {!Boolean(currentReservation?.assetIds) && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={
                  (products.find((p) => p.turfId === turf.id)?.quantity || 0) >
                  0
                }
                size="small"
                onChange={async (e) => {
                  setLoading(true);
                  try {
                    await changeProductQuantity({
                      reservationId: currentReservation?.id || "",
                      turfId: turf.id,
                      quantity: e.target.checked ? 1 : 0,
                    });
                  } finally {
                    setLoading(false);
                  }
                }}
                disabled={loading}
              />
              <Typography
                variant="caption"
                component="div"
                sx={{
                  textAlign: "left",
                  mx: 1,
                }}
              >
                I want a gorgeous 60x40cm TURF print shipped to my home{" "}
                <Tooltip
                  title={`Printed on museum grade paper ready for framing. Shipping included.`}
                >
                  <InfoRounded fontSize="inherit" />
                </Tooltip>
              </Typography>
            </Box>
          )}
        </>
      ) : (
        <>
          <CircularProgress size={30} />
          <Typography
            style={{
              marginTop: "1em",
              textAlign: "center",
            }}
            variant="caption"
          >
            Please hold while we generate your TURF art
          </Typography>
        </>
      )}
    </Box>
  );
};

export default function PreviewList() {
  const [selectedAddressKeys, confirmPlaceNames, turfs, products] =
    useReservationStore((state) => [
      state.selectedAddressKeys,
      state.confirmPlaceNames,
      state.turfs,
      state.products,
    ]);

  const [currency, exhcangeRates] = useUiStore((state) => [
    state.currency,
    state.exchangeRates,
  ]);

  const exchangeRate = exhcangeRates.find((er) => er.target === currency);

  const setError = useUiStore((state) => state.setError);
  const [loading, setLoading] = useState(false);

  const confirm = async () => {
    setLoading(true);
    try {
      // turfs.forEach((t) =>
      //   console.log(
      //     `Location code for ${
      //       JSON.parse(t.validPlaceNames || "[]").find(
      //         (v: { key: string; value: string }) =>
      //           v.key === selectedAddressKeys[t.id]
      //       ).value
      //     } is ${t.locationCode}`
      //   )
      // );
      await confirmPlaceNames();
    } catch (e: any) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const totalTurfPrice = turfs.reduce((prev, curr) => prev + curr.priceEur, 0);
  const totalProducts = products.reduce(
    (prev, curr) => prev + curr.quantity,
    0
  );
  const totalProductPrice = products.reduce(
    (prev, curr) => prev + curr.quantity * curr.priceEur,
    0
  );
  const totalDiscount =
    Math.min(totalProducts, Math.floor(totalTurfPrice / FREE_PRINT_EUR)) *
    PRINT_PRICE_EUR;

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "2em",
        }}
      >
        <Typography variant="h5">Finalize your TURF art</Typography>
      </Box>
      <div>
        {turfs.map((turf, index) => (
          <TurfTile
            turf={turf}
            loading={loading}
            setLoading={setLoading}
            key={turf.assetId}
          />
        ))}
      </div>
      {turfs.length > 0 && (
        <div
          style={{
            textAlign: "center",
            marginTop: "2em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 2,
              marginRight: 1,
              marginLeft: 1,
            }}
          >
            <Typography variant="subtitle1">Total: </Typography>
            <Typography variant="subtitle1" fontWeight={700}>
              {exchangeRate?.element ? (
                <exchangeRate.element />
              ) : (
                exchangeRate?.sign
              )}{" "}
              {(
                (totalTurfPrice + totalProductPrice) *
                (exchangeRate?.value || 1)
              ).toFixed(0)}
              {totalDiscount > 0 &&
                ` (- ${exchangeRate?.sign} ${
                  totalDiscount * (exchangeRate?.value || 1)
                } discount)`}
            </Typography>
          </Box>
          <LoadingButton
            onClick={confirm}
            variant="contained"
            loading={loading}
            loadingPosition="end"
            endIcon={<ArrowForwardIos style={{ fontSize: 10 }} />}
            loadingIndicator={<CircularProgress size={10} />}
            disabled={loading || turfs.some((row) => !row.artThumbUrl)}
            size="large"
            fullWidth
            className="gradient"
          >
            {products.some((p) => p.quantity > 0) ? "Shipping" : "Continue"}
          </LoadingButton>
          <Timer />
        </div>
      )}
    </Box>
  );
}
