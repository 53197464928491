// import * as firebase from 'firebase'
import * as firebase from "firebase/app";
import {
  collection,
  CollectionReference,
  connectFirestoreEmulator,
  getFirestore,
} from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import {
  Beneficiary,
  ExchangeRate,
  Price,
  PrimeLocation,
  PrimeLocationSquares,
  PrintOrder,
  Product,
  ProductPermission,
  Reservation,
  ReservationError,
  Turf,
} from "../definitions";

const firebaseConfig = {
  "mapmaker-330220": {
    apiKey: "AIzaSyBshCYWFKmeO7XFQxwONkuYkWdhdJwjLHI",
    authDomain: "mapmaker-330220.firebaseapp.com",
    databaseURL: "https://mapmaker-330220.firebaseio.com",
    projectId: "mapmaker-330220",
    storageBucket: "mapmaker-330220.appspot.com",
    messagingSenderId: "938828909891",
    appId: "1:938828909891:web:a17fea5d8ed18e29d633f6",
  },
  "mapmaker-staging": {
    apiKey: "AIzaSyCX9zXkiiubYZ7pYBjeMO6Q5abFCr1r3RI",
    authDomain: "mapmaker-staging.firebaseapp.com",
    projectId: "mapmaker-staging",
    storageBucket: "mapmaker-staging.appspot.com",
    messagingSenderId: "540078691404",
    appId: "1:540078691404:web:738f79029bcaa5cfcd50e9",
  },
};

const firebaseApp = firebase.initializeApp(
  // @ts-ignore
  firebaseConfig[process.env.REACT_APP_GCLOUD_PROJECT || "mapmaker-staging"]
);

const functions = getFunctions(firebaseApp, "us-central1");
const db = getFirestore(firebaseApp);

process.env.NODE_ENV === "development"
  ? connectFunctionsEmulator(functions, "localhost", 5001)
  : console.log("starting firebase with production functions");

process.env.NODE_ENV === "development"
  ? connectFirestoreEmulator(db, "localhost", 8080)
  : console.log("starting firebase with production database");

export const reservationsCol = collection(
  db,
  "reservations"
) as CollectionReference<Reservation>;
export const reservationErrorsCol = collection(
  db,
  "reservationErrors"
) as CollectionReference<ReservationError>;
export const pricesCol = collection(db, "prices") as CollectionReference<Price>;
export const turfsCol = collection(db, "turfs") as CollectionReference<Turf>;
export const productsCol = collection(
  db,
  "products"
) as CollectionReference<Product>;
export const printOrdersCol = collection(
  db,
  "printOrders"
) as CollectionReference<PrintOrder>;
export const exchangeRatesCol = collection(
  db,
  "exchangeRates"
) as CollectionReference<ExchangeRate>;
export const beneficiariesCol = collection(
  db,
  "beneficiaries"
) as CollectionReference<Beneficiary>;
export const productPermissionsCol = collection(
  db,
  "productPermissions"
) as CollectionReference<ProductPermission>;
export const primeLocationsCol = collection(
  db,
  "primeLocations"
) as CollectionReference<PrimeLocation>;
export const primeLocationSquaresCol = collection(
  db,
  "primeLocationSquares"
) as CollectionReference<PrimeLocationSquares>;

const firebaseTools = { db, functions };
export default firebaseTools;
