import { doc, onSnapshot, query, where } from "firebase/firestore";
import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";
import { shallow } from "zustand/shallow";
import {
  Product,
  Reservation,
  ReservationError,
  Turf,
} from "../../definitions";
import {
  productsCol,
  reservationErrorsCol,
  reservationsCol,
  turfsCol,
} from "../firebase.config";
import {
  addTurfToReservation,
  cancelReservation,
  changeProductQuantity,
  confirmPlaceNames,
  createMergeReservation,
  createRandomReservation,
  selectChain,
} from "../my-api";

interface ReservationStore {
  reservationId: string;
  setReservationId: (reservationId: string) => void;
  setCheckoutStep: (checkoutStep: CheckoutStep) => void;
  checkoutStep: CheckoutStep;
  selectedAddressKeys: { [key: string]: string };
  setSelectedAddressKeys: (k: string, v: string) => void;
  addTurfToCart: (desiredSquares: string[]) => Promise<void>;
  reservations: (Reservation & { id: string })[];
  currentReservation: (Reservation & { id: string }) | undefined;
  loadingReservations: boolean;
  confirmPlaceNames: () => Promise<void>;
  resetReservation: () => void;
  cancelReservation: () => Promise<void>;
  createMergeReservation: (l: string[]) => Promise<void>;
  turfs: (Turf & { id: string })[];
  products: (Product & { id: string })[];
  reservationErrors: ReservationError[];
  addProductToCart: (turfId: string, quantity: number) => Promise<void>;
  addCustomProductToCart: (
    squares: string[],
    quantity: number
  ) => Promise<void>;
  confirmProductReservation: () => void;
  // selectChain: (chain: "CARDANO" | "POLYGON") => Promise<void>;
  createRandomReservation: (
    placeName: string,
    quantity: number,
    chain: "CARDANO" | "POLYGON"
  ) => Promise<void>;
  randomReservationPlaceName: string;
  randomReservationQuantity: number;
}

type CheckoutStep =
  | ""
  | "PRODUCT_LIST"
  | "PREVIEW"
  | "PROCESSING"
  | "READY"
  | "COMPLETE_MERGE"
  | "RECIPIENT_FORM"
  // | "SELECT_CHAIN"
  | "ERROR"
  | "SUCCESS";

export const useReservationStore = create<ReservationStore>()(
  subscribeWithSelector((set, get) => {
    onSnapshot(
      query(reservationsCol, where("expired", "==", false)),
      (snapshot) => {
        const reservations = snapshot.docs.map((d) => ({
          id: d.id,
          ...d.data(),
        }));

        set({
          reservations,
          loadingReservations: false,
        });
      }
    );

    return {
      reservationId: "",
      currentReservation: undefined,
      setReservationId: (reservationId) => set({ reservationId }),
      checkoutStep: "",
      // only used for product reservations, otherwise checkout step will be updated with the subscriber below
      setCheckoutStep: (checkoutStep) => set({ checkoutStep }),
      selectedAddressKeys: {},
      loadingReservations: true,
      setSelectedAddressKeys: (k, v) =>
        set((state) => ({
          selectedAddressKeys: { ...state.selectedAddressKeys, [k]: v },
        })),
      addTurfToCart: async (desiredSquares) => {
        if (!["", "PREVIEW"].includes(get().checkoutStep)) {
          throw new Error(
            "Please complete or cancel your current reservation first."
          );
        }
        const {
          data: { reservationId, turfId, freePrints },
        } = await addTurfToReservation({
          desiredSquares,
          reservationId: get().reservationId,
        });

        const productQuantity = get().products.reduce(
          (p, c) => (p += c.quantity),
          0
        );

        if (freePrints > productQuantity) {
          await changeProductQuantity({
            reservationId,
            turfId,
            quantity: 1,
          });
        }

        set((state) => ({
          reservationId,
        }));
      },
      addProductToCart: async (turfId: string, quantity: number) => {
        const { reservationId } = (
          await changeProductQuantity({
            turfId,
            quantity,
            reservationId: get().reservationId,
          })
        ).data;

        set((state) => ({
          reservationId,
        }));
      },
      addCustomProductToCart: async (squares: string[], quantity: number) => {
        const { reservationId } = (
          await changeProductQuantity({
            squares,
            quantity,
            reservationId: get().reservationId,
          })
        ).data;

        set((state) => ({
          reservationId,
        }));
      },
      confirmProductReservation: () => {
        set({ checkoutStep: "RECIPIENT_FORM" });
      },
      confirmPlaceNames: async () => {
        await confirmPlaceNames({
          reservationId: get().reservationId,
          addressKeys: get().selectedAddressKeys,
        });
        await selectChain({
          reservationId: get().reservationId,
          chain: "CARDANO",
        });
      },
      // selectChain: async (chain) => {
      //   await selectChain({
      //     reservationId: get().reservationId,
      //     chain,
      //   });
      // },
      resetReservation: () => {
        set((state) => ({
          reservationId: "",
          checkoutStep: "",
          turfs: [],
          products: [],
        }));
      },
      cancelReservation: async () => {
        await cancelReservation({ reservationId: get().reservationId });
        get().resetReservation();
      },
      createMergeReservation: async (assetIds: string[]) => {
        const { data: reservationId } = await createMergeReservation({
          assetIds,
        });
        set((state) => ({ reservationId }));
      },
      createRandomReservation: async (placeName, quantity, chain) => {
        const res = await createRandomReservation({
          placeName,
          quantity,
        });
        await selectChain({
          reservationId: res.data.id,
          chain,
        });
        set({ reservationId: res.data.id });
      },
      randomReservationPlaceName: "",
      randomReservationQuantity: 1,
      reservations: [],
      turfs: [],
      products: [],
      reservationErrors: [],
    };
  })
);

useReservationStore.subscribe(
  (state) => state.reservationId,
  (reservationId) => {
    if (!reservationId) {
      useReservationStore.setState({
        turfs: [],
        reservationErrors: [],
        products: [],
      });
      return;
    }

    onSnapshot(doc(reservationsCol, reservationId), (snapshot) => {
      useReservationStore.setState({
        currentReservation: {
          ...snapshot.data(),
          id: snapshot.id,
        } as Reservation & { id: string },
      });
    });

    onSnapshot(
      query(turfsCol, where("reservation", "==", reservationId)),
      (snapshot) => {
        useReservationStore.setState({
          turfs: snapshot.docs.map((d) => ({ id: d.id, ...d.data() })),
        });
      }
    );

    onSnapshot(
      query(productsCol, where("reservationId", "==", reservationId)),
      (snapshot) => {
        useReservationStore.setState({
          products: snapshot.docs.map((d) => ({ id: d.id, ...d.data() })),
        });
      }
    );

    onSnapshot(
      query(reservationErrorsCol, where("reservation", "==", reservationId)),
      (snapshot) => {
        useReservationStore.setState({
          reservationErrors: snapshot.docs.map((d) => ({
            id: d.id,
            ...d.data(),
          })),
        });
      }
    );
  },
  { equalityFn: shallow }
);

useReservationStore.subscribe(
  (state) => ({
    reservationId: state.reservationId,
    currentReservation: state.currentReservation,
    turfs: state.turfs,
    reservationErrors: state.reservationErrors,
    // products: state.products,
  }),
  ({
    reservationId,
    currentReservation,
    turfs,
    reservationErrors,
    // products,
  }) => {
    const placeNamesConfirmed =
      turfs.length > 0 && turfs.every((t) => Boolean(t.placeName));

    if (currentReservation?.expired) {
      return useReservationStore.setState({
        reservationId: "",
        checkoutStep: "",
        currentReservation: undefined,
      });
    }

    if (!reservationId) {
      return useReservationStore.setState({ checkoutStep: "" });
    }

    if (currentReservation?.type === "PRODUCT") {
      if (currentReservation.paymentAddress) {
        return useReservationStore.setState({ checkoutStep: "READY" });
      }

      return useReservationStore.setState({ checkoutStep: "PRODUCT_LIST" });
    }

    if (reservationErrors.length > 0) {
      return useReservationStore.setState({ checkoutStep: "ERROR" });
    }

    if (
      // products.some((p) => p.quantity > 0) &&
      placeNamesConfirmed &&
      !currentReservation?.hasRecipientDetails
    ) {
      return useReservationStore.setState({ checkoutStep: "RECIPIENT_FORM" });
    }

    // if (
    //   (placeNamesConfirmed || currentReservation?.randomReservationPlaceName) &&
    //   !currentReservation?.chain
    // ) {
    //   return useReservationStore.setState({ checkoutStep: "SELECT_CHAIN" });
    // }

    if (
      currentReservation?.type === "MERGE" &&
      currentReservation?.paymentAddress
    ) {
      return useReservationStore.setState({ checkoutStep: "COMPLETE_MERGE" });
    }

    if (currentReservation?.paymentAddress) {
      return useReservationStore.setState({ checkoutStep: "READY" });
    }

    if (placeNamesConfirmed) {
      return useReservationStore.setState({ checkoutStep: "PROCESSING" });
    }

    return useReservationStore.setState({ checkoutStep: "PREVIEW" });
  },
  { equalityFn: shallow }
);
