import { latLng } from "leaflet";
import { MapContainer } from "react-leaflet";
import { Map } from ".";
import { isTouchDevice } from "../utils";

export default function TurfMapContainer() {
  return (
    <>
      <MapContainer
        style={{
          height: "100vh",
          // maxWidth: "90vh",
          margin: "0 auto",
          zIndex: 10,
        }}
        zoom={12}
        center={latLng(0, 0)}
        doubleClickZoom={!isTouchDevice()}
      >
        <Map />
      </MapContainer>
    </>
  );
}
