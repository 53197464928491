import { Navigate, useParams } from "react-router-dom";
import { MAP_CENTER } from "../config";

export default function Redirect() {
  const params = useParams();

  return (
    <Navigate
      to={`/?locationCode=${encodeURIComponent(
        params.locationCode || MAP_CENTER
      )}&zoom=${params.zoom || 12}`}
    />
  );
}
