import { onSnapshot } from "firebase/firestore";
import { xorBy } from "lodash";
import { create } from "zustand";
import { Price, PricedGridSquare } from "../../definitions";
import { pricesCol } from "../firebase.config";

interface MapStore {
  selectedSquares: PricedGridSquare[];
  toggleSquare: (s: PricedGridSquare) => void;
  prices: (Price & { id: string })[];
  loadingPrices: boolean;
  resetSelectedSquares: () => void;
}

export const useMapStore = create<MapStore>((set, get) => {
  onSnapshot(pricesCol, (snapshot) => {
    set({
      prices: snapshot.docs.map((d) => ({ ...d.data(), id: d.id })),
      loadingPrices: false,
    });
  });

  return {
    selectedSquares: [],
    prices: [],
    loadingPrices: true,
    toggleSquare: (square) =>
      set((state) => ({
        selectedSquares: xorBy(
          get().selectedSquares,
          [square],
          (s) => s.properties.id
        ),
      })),
    resetSelectedSquares: () => set((state) => ({ selectedSquares: [] })),
  };
});
