import {
  ArrowForwardIos,
  Check,
  DeleteOutline,
  Info,
} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Product, Turf } from "../../../definitions";
import {
  changeProductQuantity,
  getTurfsById,
  updatePrintOrderArt,
} from "../../my-api";
import { useReservationStore } from "../../stores/reservationStore";
import { useUiStore } from "../../stores/uiStore";

const ProductTile = ({
  product,
  turfs,
}: {
  product: Product & { id: string };
  turfs: (Turf & { id: string })[];
}) => {
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [nameEditOpen, setNameEditOpen] = useState(false);
  const [customName, setCustomName] = useState<string>();
  const [setSidebar, setError, currency, exhchangeRates] = useUiStore(
    (state) => [
      state.setSidebar,
      state.setError,
      state.currency,
      state.exchangeRates,
    ]
  );

  const [products, resetReservation] = useReservationStore((state) => [
    state.products,
    state.resetReservation,
  ]);

  const customPlaceName =
    customName !== undefined
      ? customName
      : product.customPlaceName ||
        turfs.find((t) => t.id === product.turfId)?.placeName ||
        "";

  const thumbUrl =
    product.thumbUrl || turfs.find((t) => t.id === product.turfId)?.artThumbUrl;
  useEffect(() => {
    if (thumbUrl) {
      setLoading(false);
    }
  }, [thumbUrl]);

  const exchangeRate = exhchangeRates.find((er) => er.target === currency);
  return (
    <Box
      key={product.id}
      sx={{
        display: "flex",
        alignItems: "center",
        marginBottom: "1em",
        background: "#FBF3DF",
        padding: "1em",
        borderRadius: "16px",
        position: "relative",
        flexDirection: "column",
      }}
    >
      <IconButton
        onClick={async () => {
          setDeleting(true);
          try {
            await changeProductQuantity({
              turfId: product.turfId,
              reservationId: product.reservationId,
              quantity: 0,
            });
            if (products.length <= 1) {
              console.log(product.id, product.reservationId);
              console.log();
              resetReservation();
              setSidebar("");
            }
          } catch (e: any) {
            setError(e.message);
          } finally {
            setDeleting(false);
          }
        }}
        disabled={deleting}
        sx={{
          position: "absolute",
          right: "4px",
          top: "4px",
        }}
      >
        <DeleteOutline color="inherit" />
      </IconButton>
      <Typography
        sx={{
          position: "absolute",
          left: (theme) => theme.spacing(2),
          top: (theme) => theme.spacing(2),
        }}
      >
        {exchangeRate?.sign}{" "}
        {(
          product.quantity *
          product.priceEur *
          (exchangeRate?.value || 1)
        ).toFixed(0)}
        <Tooltip title={`or ${product.quantity} TURF tokens`}>
          <Info fontSize="inherit" sx={{ color: "GrayText" }} />
        </Tooltip>
      </Typography>
      <Box
        sx={{
          mb: 1,
          textAlign: "center",
          overflow: "hidden",
          position: "relative",
          width: "50%",
          boxShadow: "4px 5px 8px rgba(0,0,0,0.3)",
          lineHeight: 0,
        }}
      >
        {!!thumbUrl ? (
          <img
            src={thumbUrl}
            alt="Your TURF art"
            style={{
              width: "100%",
              opacity: loading ? 0.7 : 1,
            }}
          />
        ) : (
          <Box
            sx={{
              width: "100%",
              pt: "150%",
              display: "flex",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <CircularProgress
              sx={{
                top: "50%",
                position: "absolute",
              }}
            />
          </Box>
        )}
      </Box>
      <Box sx={{ mb: 4 }}>
        <ClickAwayListener onClickAway={() => setNameEditOpen(false)}>
          <span>
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: { bgcolor: "rgba(255,255,255,0.8)", boxShadow: 4 },
                },
                arrow: {
                  sx: { ":before": { bgcolor: "rgba(255,255,255,0.8)" } },
                },
              }}
              title={
                <TextField
                  sx={{ m: 1 }}
                  label="Custom title"
                  value={customPlaceName}
                  disabled={loading}
                  onChange={async (e) => {
                    setCustomName(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <LoadingButton
                        loading={loading}
                        onClick={async () => {
                          setLoading(true);
                          await updatePrintOrderArt({
                            turfId: product.turfId,
                            productId: product.id,
                            placeName: customPlaceName,
                            shuffleColors: false,
                          });
                          setLoading(false);
                          setNameEditOpen(false);
                        }}
                      >
                        <Check />
                      </LoadingButton>
                    ),
                  }}
                />
              }
              open={nameEditOpen}
              onClose={() => setNameEditOpen(false)}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              placement="top"
              arrow
            >
              <Button
                disabled={loading}
                size="small"
                variant="text"
                onClick={() => setNameEditOpen(!nameEditOpen)}
                sx={{ cursor: "pointer", mr: 2 }}
              >
                Enter custom name
              </Button>
            </Tooltip>
          </span>
        </ClickAwayListener>
        <Button
          disabled={loading}
          size="small"
          variant="text"
          onClick={async () => {
            setLoading(true);
            await updatePrintOrderArt({
              turfId: product.turfId,
              productId: product.id,
              placeName: customPlaceName,
              shuffleColors: true,
            });
            setLoading(false);
            setNameEditOpen(false);
          }}
        >
          Shuffle colors
        </Button>
      </Box>
      <TextField
        type="number"
        label="How many prints do you want?"
        value={product.quantity}
        disabled={loading}
        onChange={async (e) => {
          setLoading(true);
          changeProductQuantity({
            turfId: product.turfId,
            reservationId: product.reservationId,
            quantity: parseInt(e.target.value),
          }).finally(() => setLoading(false));
        }}
      />
    </Box>
  );
};

export default function ProductList() {
  const [products] = useReservationStore((state) => [state.products]);

  const [turfs, setTurfs] = useState<(Turf & { id: string })[]>([]);
  useEffect(() => {
    getTurfsById(products.map((p) => p.turfId)).then((res) => setTurfs(res));
  }, [products]);

  const [confirmProductReservation] = useReservationStore((state) => [
    state.confirmProductReservation,
  ]);

  const [currency, exhcangeRates] = useUiStore((state) => [
    state.currency,
    state.exchangeRates,
  ]);

  const exchangeRate = exhcangeRates.find((er) => er.target === currency);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          marginBottom: "2em",
        }}
      >
        <Typography variant="h5">Order your prints</Typography>
        <Typography sx={{ mb: 2 }}>
          We print on the highest quality fine art paper (200gsm, 5.9oz/yd) with
          a dimension of 40x60 cm (16x24").
        </Typography>
        <Typography>
          <i>
            10% of revenue generated by this print sale will go to the owners of
            the underlying NFT
          </i>{" "}
          😍
        </Typography>
      </Box>
      <div>
        {products
          .filter((p) => p.quantity > 0)
          .map((row, index) => (
            <ProductTile product={row} turfs={turfs} key={row.id} />
          ))}
      </div>
      <div
        style={{
          textAlign: "center",
          marginTop: "2em",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 2,
            marginRight: 1,
            marginLeft: 1,
          }}
        >
          <Typography variant="subtitle1">Total: </Typography>
          <Typography variant="subtitle1" fontWeight={700}>
            {exchangeRate?.sign}{" "}
            {(
              products.reduce(
                (prev, curr) => prev + curr.quantity * curr.priceEur,
                0
              ) * (exchangeRate?.value || 1)
            ).toFixed(0)}{" "}
            <Tooltip
              title={`or ${products.reduce(
                (p, c) => p + c.quantity,
                0
              )} TURF tokens`}
            >
              <Info fontSize="inherit" sx={{ color: "GrayText" }} />
            </Tooltip>
          </Typography>
        </Box>
        <LoadingButton
          disabled={products.some((p) => !p.thumbUrl && !p.turfId)}
          onClick={confirmProductReservation}
          variant="contained"
          loadingPosition="end"
          endIcon={<ArrowForwardIos style={{ fontSize: 10 }} />}
          loadingIndicator={<CircularProgress size={10} />}
          size="large"
          fullWidth
          className="gradient"
        >
          Shipping
        </LoadingButton>
      </div>
    </Box>
  );
}
