import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  MenuItem,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import * as yup from "yup";
import { RecipientDetails } from "../../../definitions";
import { COUNTRIES } from "../../config";
import { addRecipientNew } from "../../my-api";
import { useReservationStore } from "../../stores/reservationStore";

const recipientDetailsInfo: {
  key: keyof RecipientDetails;
  validation: yup.AnySchema;
  label: string;
}[] = [
  {
    key: "recipientName",
    validation: yup.string().min(3).required(),
    label: "Full name",
  },
  {
    key: "recipientEmail",
    validation: yup.string().email().required(),
    label: "Email address",
  },
  {
    key: "address1",
    validation: yup.string().min(5).required(),
    label: "Address 1",
  },
  { key: "address2", validation: yup.string(), label: "Addres 2" },
  {
    key: "town",
    validation: yup.string().min(3).required(),
    label: "City/Town",
  },
  { key: "stateCounty", validation: yup.string(), label: "State/County" },
  {
    key: "countryCode",
    validation: yup
      .string()
      .default("NL")
      .oneOf(
        COUNTRIES.map((c) => c.code),
        "Value is not a valid option"
      ),
    label: "Country",
  },
  {
    key: "postcode",
    validation: yup.string().required(),
    label: "Postal code",
  },
];

const validateField = (validation: yup.AnySchema, val: any) => {
  let message = "";
  try {
    validation.validateSync(val);
  } catch (error: any) {
    message = error.message;
  }
  return message;
};

const RecipientForm = () => {
  const [error, setError] = useState("");
  const [reservationId, products] = useReservationStore((state) => [
    state.reservationId,
    state.products,
  ]);
  const [recipientDetails, setRecipientDetails] = useState(
    Object.fromEntries(
      recipientDetailsInfo.map((r) => [r.key, r.validation.getDefault() || ""])
    )
  );

  const status = products.some((p) => p.quantity > 0)
    ? "AWAITING_CONFIRMATION"
    : "KYC";

  const [recipientDetailsErrors, setRecipientDetailsErrors] =
    useState<RecipientDetails>({});

  const [loading, setLoading] = useState(false);

  return (
    <>
      <Typography variant={"h5"} sx={{ mb: 2 }}>
        Personal details
      </Typography>
      {/* <Typography fontWeight="700">Shipping details for your print</Typography> */}
      <Typography sx={{ mb: 2 }}>
        We need your personal information for shipping prints, and to comply
        with Dutch KYC regulations. If you ordered a print shipping can take up
        to two weeks.
      </Typography>
      {recipientDetailsInfo.map((info) => {
        return (
          <Box key={info.key}>
            <TextField
              label={info.label}
              variant="outlined"
              margin="dense"
              fullWidth
              select={info.key === "countryCode"}
              disabled={loading}
              onBlur={(e) => {
                const message = validateField(info.validation, e.target.value);
                setRecipientDetailsErrors({
                  ...recipientDetailsErrors,
                  [info.key]: message,
                });
              }}
              onChange={(e) => {
                setRecipientDetails({
                  ...recipientDetails,
                  [info.key]: e.target.value,
                });

                if (recipientDetailsErrors[info.key]) {
                  const message = validateField(
                    info.validation,
                    e.target.value
                  );
                  setRecipientDetailsErrors({
                    ...recipientDetailsErrors,
                    [info.key]: message,
                  });
                }
              }}
              error={!!recipientDetailsErrors[info.key]}
              helperText={recipientDetailsErrors[info.key]}
              value={recipientDetails[info.key]}
            >
              {info.key === "countryCode" &&
                COUNTRIES.map((country) => (
                  <MenuItem key={country.code} value={country.code}>
                    {country.name}
                  </MenuItem>
                ))}
            </TextField>
          </Box>
        );
      })}
      <LoadingButton
        className="gradient"
        sx={{ mt: 2 }}
        onClick={async () => {
          const errors: RecipientDetails = {};
          setLoading(true);
          recipientDetailsInfo.forEach((info) => {
            const error = validateField(
              info.validation,
              recipientDetails[info.key]
            );
            if (error) errors[info.key] = error;
          });

          if (Object.keys(errors).length > 0) {
            setRecipientDetailsErrors(errors);
            setLoading(false);
            return;
          }

          try {
            await addRecipientNew({ recipientDetails, reservationId, status });
          } catch (e: any) {
            setError(e.message || e.info || e);
          } finally {
            setLoading(false);
          }
        }}
        variant="contained"
        size="large"
        loading={loading}
      >
        Submit
      </LoadingButton>
      <Snackbar
        onClose={() => setError("")}
        autoHideDuration={5000}
        open={!!error}
      >
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </>
  );
};

export default RecipientForm;
