import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { onSnapshot } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PrimeLocation } from "../../definitions";
import { primeLocationsCol } from "../firebase.config";
import { useReservationStore } from "../stores/reservationStore";
import { useUiStore } from "../stores/uiStore";

const RANDOM_SQUARE_PRICE = 30;
const RANDOM_SQUARE_PRICE_BUMP = 5;

export default function RandomDropModal() {
  const navigate = useNavigate();
  const params = useParams();
  const { placeName } = params;

  const [primeLocations, setPrimeLocations] = useState<PrimeLocation[]>([]);
  const [currentLocation, setCurrentLocation] = useState<PrimeLocation>();
  const dialogRef = useRef<HTMLDivElement>(null);

  const [setSidebar, currency, exchangeRates, setShowWelcomeScreen] =
    useUiStore((state) => [
      state.setSidebar,
      state.currency,
      state.exchangeRates,
      state.setShowWelcomeScreen,
    ]);
  const createRandomReservation = useReservationStore(
    (state) => state.createRandomReservation
  );
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const exchangeRate = exchangeRates.find((er) => er.target === currency);
  const adaRate = exchangeRates.find((er) => er.target === "ADA");
  const maticRate = exchangeRates.find((er) => er.target === "MATIC");

  const currentPrice = currentLocation
    ? Math.floor(currentLocation.priceEur * (exchangeRate?.value || 1))
    : 0;
  const nextPrice = currentLocation
    ? Math.floor(
        (currentLocation.priceEur +
          (currentLocation.priceEur > 70 ? 0 : RANDOM_SQUARE_PRICE_BUMP)) *
          (exchangeRate?.value || 1)
      )
    : 0;

  const { totalSquares = 0, availableSquares = 0 } = currentLocation || {};
  let selectedPriceEur = 0;

  for (let i = 0; i < quantity; i++) {
    const bump = Math.floor(
      ((totalSquares - availableSquares - i) / totalSquares) * 10
    );
    const squarePriceEur =
      RANDOM_SQUARE_PRICE + RANDOM_SQUARE_PRICE_BUMP * bump;
    selectedPriceEur += squarePriceEur;
  }

  const bump = totalSquares / 10;
  const squaresUntilBump = Math.ceil(
    bump - ((totalSquares - availableSquares) % bump)
  );

  useEffect(() => {
    setShowWelcomeScreen(false);
    return onSnapshot(primeLocationsCol, (snapshot) => {
      setPrimeLocations(snapshot.docs.map((p) => p.data()));
    });
  }, []);

  useEffect(() => {
    const currentLocation = primeLocations.find(
      (pl) => pl.placeName === placeName
    );
    if (currentLocation) {
      setCurrentLocation(currentLocation);
    }
  }, [primeLocations, placeName, exchangeRate]);

  return (
    <Dialog
      open={true}
      onClose={() => navigate(`/${window.location.search}`)}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: {
          m: { xs: 1, sm: 2 },
          p: 4,
          maxHeight: (theme) => ({
            xs: `calc(100% - ${theme.spacing(2)})`,
            sm: `calc(100% - ${theme.spacing(4)})`,
          }),
        },
        ref: dialogRef,
      }}
      keepMounted={false}
    >
      <IconButton
        sx={{ position: "absolute", right: 0, mr: 2 }}
        onClick={() => navigate(`/${window.location.search}`)}
      >
        <Close />
      </IconButton>
      <Typography
        variant="h2"
        sx={{
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          textAlign: "center",
          mb: 8,
        }}
        className="gradient"
      >
        TURF
      </Typography>
      <Grid container spacing={4} alignItems="stretch" justifyContent="center">
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography variant="h2" fontFamily="Roboto">
            {placeName}
          </Typography>
          <Stack
            fontSize="1.2em"
            direction={{ xs: "column", md: "row" }}
            spacing={{ xs: 0, md: 2 }}
            divider={<Divider orientation="vertical" flexItem />}
            sx={{ justifyContent: "center", mb: 4 }}
          >
            <Typography>
              Total squares: {currentLocation?.totalSquares}
            </Typography>
            <Typography>
              Sold squares:{" "}
              {(currentLocation?.totalSquares || 0) -
                (currentLocation?.availableSquares || 0)}
            </Typography>
            <Typography>
              Current price:{" "}
              {exchangeRate?.element ? (
                <exchangeRate.element
                  sx={{ mb: exchangeRate.target === "ADA" ? -1 : 0 }}
                />
              ) : (
                exchangeRate?.sign
              )}{" "}
              {currentPrice}
            </Typography>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          lg={6}
          sx={{
            textAlign: "center",

            alignItems: "center",
            justifyContent: "center",
            // mb: 8,
          }}
        >
          <Typography
            sx={{ mb: 1 }}
          >{`How many random squares of ${placeName} do you want?`}</Typography>
          <Select
            sx={{
              // background: (theme) => theme.palette.common.white,
              width: 100,
              mb: 4,
            }}
            fullWidth
            size="small"
            value={quantity}
            onChange={(e) => {
              setQuantity(e.target.value as number);
            }}
            disabled={loading}
          >
            {Array(Math.min(currentLocation?.availableSquares || 9, 9))
              .fill(null)
              .map((_number, index) => (
                <MenuItem value={index + 1} key={index + 1}>
                  {index + 1}
                </MenuItem>
              ))}
          </Select>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <LoadingButton
              size="large"
              onClick={async () => {
                setLoading(true);
                try {
                  !!placeName &&
                    (await createRandomReservation(
                      placeName,
                      quantity,
                      "CARDANO"
                    ));
                  setSidebar("CHECKOUT");
                  navigate(`/${window.location.search}`);
                } finally {
                  setLoading(false);
                }
              }}
              sx={{ fontWeight: 700, color: "white" }}
              className="gradient"
              loading={loading}
              fullWidth
            >
              Buy NFT{quantity > 1 ? "s" : ""} on Cardano (
              {adaRate?.element ? <adaRate.element /> : adaRate?.sign}{" "}
              {Math.floor(selectedPriceEur * (adaRate?.value || 1))})
            </LoadingButton>
            <LoadingButton
              size="large"
              onClick={async () => {
                setLoading(true);
                try {
                  !!placeName &&
                    (await createRandomReservation(
                      placeName,
                      quantity,
                      "POLYGON"
                    ));
                  setSidebar("CHECKOUT");
                  navigate(`/${window.location.search}`);
                } finally {
                  setLoading(false);
                }
              }}
              sx={{ fontWeight: 700, color: "white" }}
              className="gradient"
              loading={loading}
              fullWidth
            >
              Buy NFT{quantity > 1 ? "s" : ""} on Polygon (
              {maticRate?.element ? <maticRate.element /> : maticRate?.sign}{" "}
              {Math.floor(selectedPriceEur * (maticRate?.value || 1))})
            </LoadingButton>
          </Stack>
          <Typography sx={{ mt: 2 }} fontStyle="italic">
            Price increases to{" "}
            {exchangeRate?.element ? (
              <exchangeRate.element />
            ) : (
              exchangeRate?.sign
            )}{" "}
            {nextPrice} when {squaresUntilBump} more squares of {placeName} are
            sold
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            maxWidth: { xs: "100%", md: "450px" },
            marginTop: 4,
          }}
        >
          <img
            style={{
              boxShadow: "3px 3px 10px rgba(0,0,0,0.3)",
              borderRadius: "16px",
            }}
            src={`${process.env.PUBLIC_URL}/pricing_table.gif`}
            alt={currentLocation?.placeName}
          />
        </Box>
      </Grid>
      <Divider sx={{ my: 8 }} />
      <Typography variant="h4" textAlign="center" fontFamily="Roboto">
        Or get random squares from other cities!
      </Typography>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={6}>
          <Table>
            <TableHead>
              <TableRow sx={{ fontWeight: "700" }}>
                <TableCell>City</TableCell>
                <TableCell>Total squares</TableCell>
                <TableCell>Sold squares</TableCell>
                <TableCell>Current price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {primeLocations.map((pl) => (
                <TableRow key={pl.placeName}>
                  <TableCell sx={{ fontWeight: "700" }}>
                    <Link
                      component="button"
                      onClick={() => {
                        navigate(
                          `/random-drop/${
                            pl.placeName
                          }?locationCode=${encodeURIComponent(pl.locationCode)}`
                        );
                        dialogRef.current?.scrollTo({ top: 0 });
                      }}
                    >
                      {pl.placeName}
                    </Link>
                  </TableCell>
                  <TableCell>{pl.totalSquares}</TableCell>
                  <TableCell>{pl.totalSquares - pl.availableSquares}</TableCell>
                  <TableCell>
                    {exchangeRate?.element ? (
                      <exchangeRate.element />
                    ) : (
                      exchangeRate?.sign
                    )}{" "}
                    {Math.floor(pl.priceEur * (exchangeRate?.value || 1))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Dialog>
  );
}
