import { onSnapshot } from "firebase/firestore";
import { ReactElement } from "react";
import { create } from "zustand";
import { ExchangeRate } from "../../definitions";
import { CardanoIcon } from "../components/CardanoIcon";
import { PolygonIcon } from "../components/PolygonIcon";
import { exchangeRatesCol } from "../firebase.config";

type Sidebar = "" | "MY_TURFS" | "CHECKOUT";
type MyTurfsContent = "LIST" | "DETAIL" | "MERGE_INSTRUCTION";
type MapMode = "DEFAULT" | "MERGE" | "PRINT";

interface UiStore {
  error: string | ReactElement;
  setError: (e: string | ReactElement) => void;
  loadingGridSquares: boolean;
  setLoadingGridSquares: (loading: boolean) => void;
  showWelcomeScreen: boolean;
  setShowWelcomeScreen: (showWelcomeScreen: boolean) => void;
  showNewsletterSignup: boolean;
  setShowNewsletterSignup: (showNewsletterSignup: boolean) => void;
  gridError: string;
  setGridError: (message: string) => void;
  menuOpen: boolean;
  setMenuOpen: (menuOpen: boolean) => void;
  sidebar: Sidebar;
  setSidebar: (sidebar: Sidebar) => void;
  myTurfsContent: MyTurfsContent;
  myTurfsState?: { assetId?: string };
  setMyTurfsContent: (
    sidebar: MyTurfsContent,
    state?: { assetId: string }
  ) => void;
  mapMode: MapMode;
  setMapMode: (mapMode: MapMode) => void;
  currency: string;
  setCurrency: (currency: string) => void;
  exchangeRates: (ExchangeRate & { element?: any })[];
}

export const useUiStore = create<UiStore>()((set, get) => {
  onSnapshot(exchangeRatesCol, (snapshot) => {
    const signs: { [key: string]: any } = {
      MATIC: PolygonIcon,
      ADA: CardanoIcon,
    };
    set({
      exchangeRates: [
        ...snapshot.docs.map((doc) => ({
          ...doc.data(),
          element: signs[doc.data().target],
        })),
        {
          base: "EUR",
          target: "EUR",
          sign: "€",
          value: 1,
          lastUpdate: Date.now(),
        },
      ],
    });
  });

  return {
    error: "",
    setError: (error) => set((state) => ({ error })),
    loadingGridSquares: false,
    setLoadingGridSquares: (loading) => set({ loadingGridSquares: loading }),
    gridError: "",
    setGridError: (message) => set({ gridError: message }),
    showWelcomeScreen: localStorage.getItem("hasSeenWelcome") !== "true",
    setShowWelcomeScreen: (showWelcomeScreen) => {
      localStorage.setItem("hasSeenWelcome", String(!showWelcomeScreen));
      set({ showWelcomeScreen });
    },
    showNewsletterSignup: false,
    setShowNewsletterSignup: (showNewsletterSignup) => {
      set({ showNewsletterSignup });
    },
    menuOpen: false,
    setMenuOpen: (menuOpen) => set({ menuOpen }),
    sidebar: "",
    setSidebar: (sidebar) =>
      set({ sidebar, myTurfsContent: "LIST", myTurfsState: {} }),
    myTurfsContent: "LIST",
    setMyTurfsContent: (myTurfsContent, myTurfsState) => {
      set({
        myTurfsContent,
        myTurfsState: { ...get().myTurfsState, ...myTurfsState },
      });
    },
    mapMode: "DEFAULT",
    setMapMode: (mapMode: MapMode) => set({ mapMode }),
    currency: "EUR",
    setCurrency: (currency) =>
      set({
        currency,
      }),
    exchangeRates: [],
    currentRate: 1,
  };
});
