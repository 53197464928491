import {
  Add,
  Lightbulb,
  Merge,
  ShoppingBagOutlined,
  Wallet,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Badge,
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Grow,
  Link,
  ListItemIcon,
  MenuItem,
  Paper,
  Stack,
  SxProps,
  ThemeProvider,
  Tooltip,
  Typography,
} from "@mui/material";
import { utf8ToHex } from "lucid-cardano";
import React, { useEffect, useState } from "react";
import { POLICY_ID } from "../config";
import { useMapStore } from "../stores/mapStore";
import { useReservationStore } from "../stores/reservationStore";
import { useUiStore } from "../stores/uiStore";
import { useWalletStore } from "../stores/walletStore";
import { darkTheme, lightTheme } from "../theme";
import { isValidSquareSelection } from "../utils";

const barHeight = 67;

const MyWalletMenu = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [disconnect] = useWalletStore((state) => [state.disconnect]);
  const setSidebar = useUiStore((state) => state.setSidebar);

  const menu = (
    <Paper
      sx={{
        py: 1,
        position: "absolute",
        bottom: (theme) => ({
          xs: `calc(${barHeight}px + ${theme.spacing(1)})`,
          md: "auto",
        }),
        right: { xs: "auto", md: 0 },
        left: { xs: 0, md: "auto" },
        top: (theme) => ({
          xs: "auto",
          md: `calc(${barHeight}px + ${theme.spacing(1)})`,
        }),
      }}
    >
      <Stack direction="column" divider={<Divider />}>
        <MenuItem
          onClick={() => {
            setSidebar("MY_TURFS");
            onClose();
          }}
        >
          My TURFs
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            setSidebar("ROYALTIES");
            onClose();
          }}
        >
          Royalties
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            disconnect();
            onClose();
          }}
        >
          Disconnect
        </MenuItem>
      </Stack>
    </Paper>
  );
  return (
    <ThemeProvider theme={lightTheme}>
      <Grow in={open}>{menu}</Grow>
    </ThemeProvider>
  );
};

export const WalletList = ({
  onClose,
  open,
  sx = {},
}: {
  onClose: () => Promise<void>;
  open: boolean;
  sx?: SxProps;
}) => {
  const [loading, setLoading] = useState(false);
  const [enableWallet, walletList] = useWalletStore((state) => [
    state.enableWallet,
    state.walletList,
  ]);

  const setError = useUiStore((state) => state.setError);

  const menu = (
    <Paper
      sx={{
        p: 2,
        position: "absolute",
        bottom: (theme) => ({
          xs: `calc(${barHeight}px + ${theme.spacing(1)})`,
          md: "auto",
        }),
        right: { xs: "auto", md: 0 },
        left: { xs: 0, md: "auto" },
        top: (theme) => ({
          xs: "auto",
          md: `calc(${barHeight}px + ${theme.spacing(1)})`,
        }),
        ...sx,
      }}
    >
      <Stack direction="column" divider={<Divider />}>
        {!Boolean(window.cardano) && (
          <Typography>
            <i>Polygon minting available through our regular checkout!</i>
            <br />
            <br />
            Cardano wallet support only for now. Adding Polygon in the near
            future.
            <br />
            <br />
            Need a Cardano wallet? We recommend{" "}
            <Link href="https://typhonwallet.io" target="_blank">
              Typhon
            </Link>
            .
          </Typography>
        )}
        {(Object.keys(walletList) as Array<keyof typeof walletList>).map(
          (wallet) =>
            Boolean(window.cardano) &&
            Boolean(window.cardano[wallet]) && (
              <MenuItem
                disabled={loading}
                onClick={async () => {
                  setLoading(true);
                  try {
                    await enableWallet(wallet);
                    await onClose();
                  } catch (e) {
                    setError("Could not connect wallet.");
                    setLoading(false);
                  }
                }}
                key={wallet}
              >
                <ListItemIcon>
                  <img
                    src={`${process.env.PUBLIC_URL}/${walletList[wallet].icon}`}
                    alt={wallet}
                    style={{ maxWidth: 20, maxHeight: 20 }}
                  />
                </ListItemIcon>
                {walletList[wallet].label}
              </MenuItem>
            )
        )}
      </Stack>
    </Paper>
  );
  return (
    <ThemeProvider theme={lightTheme}>
      <Grow in={open}>{menu}</Grow>
    </ThemeProvider>
  );
};

const AddToBasketButton = () => {
  const setSidebar = useUiStore((state) => state.setSidebar);
  const addTurfToCart = useReservationStore((state) => state.addTurfToCart);

  const [selectedSquares, resetSelectedSquares] = useMapStore((state) => [
    state.selectedSquares,
    state.resetSelectedSquares,
  ]);
  const [setError, currency, exchangeRates] = useUiStore((state) => [
    state.setError,
    state.currency,
    state.exchangeRates,
  ]);
  const selectedSquaresValid = isValidSquareSelection(selectedSquares);
  const exchangeRate = exchangeRates.find((er) => er.target === currency);

  const [loading, setLoading] = useState(false);

  const addTurf = async () => {
    setLoading(true);
    try {
      await addTurfToCart(selectedSquares.map((s) => s.properties.id));
      resetSelectedSquares();
      setSidebar("CHECKOUT");
    } catch (e: any) {
      console.log(e);
      setError(e.message);
      resetSelectedSquares();
    }
  };

  return (
    <Tooltip
      title={
        <React.Fragment>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Lightbulb sx={{ marginRight: 1 }} />

            <Typography variant="body1">
              <Typography
                variant="caption"
                sx={{
                  color: "white",
                }}
              >
                Currently selected value: {exchangeRate?.sign}{" "}
                {Math.floor(
                  selectedSquares.reduce((prev, curr) => {
                    return curr.priceEur + prev;
                  }, 0) * (exchangeRate?.value || 1)
                )}
              </Typography>
              <br />
              {selectedSquares.length === 1
                ? "You can select an area of up to 5x5 squares to create bigger TURFs!"
                : `Select a square area of up to 5x5 TURFs and click "+"`}
              <br />
              <Typography
                variant="caption"
                sx={{
                  cursor: "pointer",
                  color: "white",
                  textDecoration: "underline",
                }}
                onClick={resetSelectedSquares}
              >
                Reset square selection
              </Typography>
            </Typography>
          </Box>
        </React.Fragment>
      }
      arrow
      open={!loading}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: "secondary.main",
            maxWidth: 300,
            fontSize: "1em",
            margin: 1,
          },
        },
        arrow: {
          sx: {
            ":before": {
              backgroundColor: "secondary.main",
            },
          },
        },
      }}
    >
      <LoadingButton
        variant="contained"
        onClick={addTurf}
        sx={{
          minWidth: "auto",
          p: "5px",
          mr: 2,
        }}
        loading={loading}
        disabled={!selectedSquaresValid}
        className={selectedSquaresValid ? "jump" : ""}
      >
        <Add />
      </LoadingButton>
    </Tooltip>
  );
};

const CreateMergeReservationButton = () => {
  const [setSidebar, setMapMode] = useUiStore((state) => [
    state.setSidebar,
    state.setMapMode,
  ]);
  const createMergeReservation = useReservationStore(
    (state) => state.createMergeReservation
  );
  const setError = useUiStore((state) => state.setError);
  const [loading, setLoading] = useState(false);
  const [selectedAssetIds, resetSelectedAssetIds] = useWalletStore((state) => [
    state.selectedAssetIds,
    state.resetSelectedAssetIds,
  ]);
  return (
    <Tooltip
      title={
        <React.Fragment>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Lightbulb sx={{ marginRight: 1 }} />{" "}
            <Typography variant="body1">
              Once you've selected the TURFs you want to merge, click here.
            </Typography>
          </Box>
        </React.Fragment>
      }
      arrow
      open={selectedAssetIds.length > 0}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: "secondary.main",
            maxWidth: 300,
            fontSize: "1em",
            margin: 1,
          },
        },
        arrow: {
          sx: {
            ":before": {
              backgroundColor: "secondary.main",
            },
          },
        },
      }}
    >
      <LoadingButton
        variant="contained"
        onClick={async () => {
          setLoading(true);
          try {
            await createMergeReservation(selectedAssetIds);
            setMapMode("DEFAULT");
            setSidebar("CHECKOUT");
            resetSelectedAssetIds();
          } catch (e) {
            setError("Cannot merge Turfs with these location codes");
          } finally {
            setLoading(false);
          }
        }}
        sx={{
          minWidth: "auto",
          p: "5px",
          mr: 2,
        }}
        loading={loading}
        className="jump"
      >
        <Merge />
      </LoadingButton>
    </Tooltip>
  );
};

const CreateProductReservationButton = () => {
  const [setSidebar, setError] = useUiStore((state) => [
    state.setSidebar,
    state.setError,
  ]);
  const [resetReservation, addCustomProductToCart] = useReservationStore(
    (state) => [state.resetReservation, state.addCustomProductToCart]
  );
  const [loading, setLoading] = useState(false);

  const [selectedSquares, resetSelectedSquares] = useMapStore((state) => [
    state.selectedSquares,
    state.resetSelectedSquares,
  ]);
  const selectedSquaresValid = isValidSquareSelection(selectedSquares);
  return (
    <Tooltip
      title={
        <React.Fragment>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Lightbulb sx={{ marginRight: 1 }} />{" "}
            <Typography variant="body1">
              Select a square area of up to 5x5 squares and click "+" to see a
              preview of the artwork.
              <br />
              <Typography
                variant="caption"
                sx={{
                  cursor: "pointer",
                  color: "white",
                  textDecoration: "underline",
                }}
                onClick={resetSelectedSquares}
              >
                Reset square selection
              </Typography>
            </Typography>
          </Box>
        </React.Fragment>
      }
      arrow
      open={true}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: "secondary.main",
            maxWidth: 300,
            fontSize: "1em",
            margin: 1,
          },
        },
        arrow: {
          sx: {
            ":before": {
              backgroundColor: "secondary.main",
            },
          },
        },
      }}
    >
      <LoadingButton
        variant="contained"
        onClick={async () => {
          setLoading(true);
          try {
            await addCustomProductToCart(
              selectedSquares.map((s) => s.properties.id),
              1
            );
            setSidebar("CHECKOUT");
          } catch (e: any) {
            if (e.code === "functions/permission-denied") {
              const url = `https://www.jpg.store/asset/${POLICY_ID}${utf8ToHex(
                "TURF" + e.message
              )}#:~:text=Make Offer`;
              setError(
                <>
                  The owner of the underlying NFT has blocked printing 😟. Still
                  keen on getting it? Try buying the NFT on{" "}
                  <Link href={url} target="_blank">
                    jpg.store
                  </Link>{" "}
                  to unlock printing.
                </>
              );
            } else {
              setError(e.message);
            }
          } finally {
            resetSelectedSquares();
            setLoading(false);
          }
        }}
        sx={{
          minWidth: "auto",
          p: "5px",
          mr: 2,
        }}
        loading={loading}
        disabled={!selectedSquaresValid}
        className={selectedSquaresValid ? "jump" : ""}
      >
        <Add />
      </LoadingButton>
    </Tooltip>
  );
};

const BasketButton = () => {
  const [turfs, products] = useReservationStore((state) => [
    state.turfs,
    state.products,
  ]);
  const setSidebar = useUiStore((state) => state.setSidebar);

  const productCount = products.reduce((p, c) => p + c.quantity, 0);
  return (
    <Badge
      badgeContent={turfs.length || productCount}
      color="secondary"
      sx={{
        mr: 2,
      }}
    >
      <LoadingButton
        variant="contained"
        onClick={() => setSidebar("CHECKOUT")}
        sx={{
          minWidth: "auto",
          p: "5px",
        }}
      >
        <ShoppingBagOutlined />
      </LoadingButton>
    </Badge>
  );
};

export default function Basket() {
  const [mapMode, setMapMode, currency, setCurrency, exchangeRates] =
    useUiStore((state) => [
      state.mapMode,
      state.setMapMode,
      state.currency,
      state.setCurrency,
      state.exchangeRates,
    ]);
  const selectedSquares = useMapStore((state) => state.selectedSquares);
  const [lucid, walletName, enableWallet, walletList, selectedAssetIds] =
    useWalletStore((state) => [
      state.lucid,
      state.walletName,
      state.enableWallet,
      state.walletList,
      state.selectedAssetIds,
    ]);
  const [loading, setLoading] = useState(false);
  const [menuOpen, setMenuOpen] = React.useState(false);

  useEffect(() => {
    if (walletName) {
      setLoading(true);
      enableWallet(walletName, () => {
        setLoading(false);
      });
    }
  }, [walletName, setLoading, enableWallet]);

  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <Box
          sx={{
            border: 0,
            borderRadius: 3,
            position: "absolute",
            right: {
              xs: "auto",
              md: 0,
            },
            left: {
              xs: 0,
              md: "auto",
            },
            top: {
              md: 0,
            },
            bottom: {
              xs: 0,
            },
            m: {
              xs: 1,
              sm: 2,
            },
            zIndex: 99,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            height: barHeight,
            px: 2,
          }}
          className="gradient"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mr: 2,
            }}
          >
            {/* {mapMode === "MERGE" && selectedAssetIds.length > 0 && (
              <CreateMergeReservationButton />
            )}

            {mapMode === "PRINT" && selectedSquares.length > 0 && (
              <CreateProductReservationButton />
            )}

            {mapMode === "DEFAULT" && selectedSquares.length > 0 && (
              <AddToBasketButton />
            )}

            {selectedSquares.length === 0 && selectedAssetIds.length === 0 && (
              <BasketButton />
            )} */}

            <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
              {!!lucid ? (
                <Box sx={{ zIndex: 9 }}>
                  <Button
                    startIcon={
                      walletName && (
                        <img
                          src={`${process.env.PUBLIC_URL}/${walletList[walletName].icon}`}
                          width="20"
                          alt={walletList[walletName].label}
                        />
                      )
                    }
                    variant="outlined"
                    onClick={(e) => setMenuOpen(!menuOpen)}
                    disabled={loading}
                  >
                    My wallet
                  </Button>
                  <MyWalletMenu
                    open={menuOpen}
                    onClose={() => setMenuOpen(false)}
                  />
                </Box>
              ) : (
                <></>
              )}
            </ClickAwayListener>
            <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
              {!lucid ? (
                <Box sx={{ zIndex: 9 }}>
                  <Button
                    onClick={(e) => setMenuOpen(!menuOpen)}
                    variant="outlined"
                    startIcon={<Wallet />}
                    disabled={loading}
                  >
                    Wallet
                  </Button>
                  <WalletList
                    onClose={async () => setMenuOpen(false)}
                    open={menuOpen}
                  />
                </Box>
              ) : (
                <></>
              )}
            </ClickAwayListener>
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Select
              size="small"
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
              autoWidth
              fullWidth
            >
              {exchangeRates.map((er) => (
                <MenuItem
                  value={er.target}
                  key={er.target}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ mr: 1, display: "flex" }}>
                      {er.element ? <er.element /> : er.sign}
                    </Box>
                    <Box>{er.target}</Box>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </Box> */}
          {/* {mapMode === "MERGE" && (
            <Button
              size="small"
              sx={{
                position: "absolute",
                top: { xs: "-75%", md: "100%" },
                left: 0,
                my: 1,
              }}
              onClick={() => setMapMode("DEFAULT")}
            >
              Cancel merge
            </Button>
          )} */}
          {/* {mapMode === "PRINT" && (
            <Button
              size="small"
              sx={{
                position: "absolute",
                top: { xs: "-75%", md: "100%" },
                left: 0,
                my: 1,
              }}
              onClick={() => setMapMode("DEFAULT")}
            >
              Switch to NFT mode
            </Button>
          )} */}
          {/* {mapMode === "DEFAULT" && (
            <Button
              size="small"
              sx={{
                position: "absolute",
                top: { xs: "-75%", md: "100%" },
                left: 0,
                my: 1,
              }}
              onClick={() => setMapMode("PRINT")}
            >
              Switch to print mode
            </Button>
          )} */}
        </Box>
      </ThemeProvider>
    </>
  );
}
