import { useTheme } from "@mui/material";
import bbox from "@turf/bbox";
import { latLngBounds } from "leaflet";
import { SVGOverlay, useMap } from "react-leaflet";
import { usePricedSquares } from "../hooks";
import { useMapStore } from "../stores/mapStore";

export default function PrintMapGrid() {
  const [toggleSquare, selectedSquares] = useMapStore((state) => [
    state.toggleSquare,
    state.selectedSquares,
  ]);

  const map = useMap();
  const bounds = map.getBounds().toBBoxString();
  const zoom = map.getZoom();

  const pricedSquares = usePricedSquares(bounds, zoom);

  const theme = useTheme();

  return (
    <>
      {pricedSquares.map((square) => {
        const featBbox = bbox(square);
        const bounds = latLngBounds([
          [featBbox[1], featBbox[0]],
          [featBbox[3], featBbox[2]],
        ]);
        return (
          <SVGOverlay
            interactive={true}
            bounds={bounds}
            key={square.properties.id}
            eventHandlers={{
              click: () => {
                console.log(square.properties.id);
                toggleSquare(square);
              },
            }}
          >
            <svg height="100%" width="100%">
              <rect
                width="100%"
                height="100%"
                fill={
                  selectedSquares
                    .map((s) => s.properties.id)
                    .includes(square.properties.id)
                    ? theme.palette.secondary.main
                    : "transparent"
                }
                opacity={0.5}
                strokeWidth={1}
                stroke={theme.palette.secondary.main}
              />
            </svg>
          </SVGOverlay>
        );
      })}
    </>
  );
}
