import { Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Wallets from "../Wallets";

export default function WalletList() {
  const navigate = useNavigate();
  return (
    <Container
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Typography variant={"h5"}>
        Choose the wallet that holds your TURF NFTs
      </Typography>
      <Wallets onSuccess={() => navigate("/claim-your-print/assets")} />
    </Container>
  );
}
