import { BBox } from "@turf/helpers";
import { latLngBounds } from "leaflet";
import { ImageOverlay } from "react-leaflet";
import { useQuery } from "react-query";
import { getTurfsByAssetIds } from "../my-api";
import { useWalletStore } from "../stores/walletStore";
import { getTurfAssetIdsFromAssets, locationCodeFromAssetId } from "../utils";

export default function CurrentTurfs() {
  const [assetsInWallet, selectedAssetIds, toggleSelectedAssetId] =
    useWalletStore((state) => [
      state.assetsInWallet,
      state.selectedAssetIds,
      state.toggleSelectedAssetId,
    ]);

  const availableAssetIds = getTurfAssetIdsFromAssets(
    Object.keys(assetsInWallet)
  );

  const { data: turfs = [] } = useQuery(
    ["availableTurfs", ...availableAssetIds],
    () => getTurfsByAssetIds(availableAssetIds)
  );

  return (
    <>
      {turfs.map((turf) => {
        if (!turf.assetId) {
          return null;
        }

        const bbox = turf.bounds.split(",").map(parseFloat) as BBox;
        const bounds = latLngBounds([
          [bbox[1], bbox[0]],
          [bbox[3], bbox[2]],
        ]);

        return (
          !!turf.thumbUrl && (
            <ImageOverlay
              key={turf.locationCode}
              bounds={bounds}
              url={turf.thumbUrl}
              interactive={true}
              opacity={
                selectedAssetIds
                  .map(locationCodeFromAssetId)
                  .includes(turf.locationCode)
                  ? 1
                  : 0.5
              }
              eventHandlers={{
                click: () => toggleSelectedAssetId(turf.assetId),
              }}
            />
          )
        );
      })}
    </>
  );
}
