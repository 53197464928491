import { Drawer } from "@mui/material";
import { ReactElement } from "react";

const barHeight = 67;
const Sidebar = ({
  element,
  open,
  onClose,
}: {
  element: ReactElement;
  open: boolean;
  onClose: () => void;
}) => {
  return (
    <Drawer
      open={open}
      anchor="right"
      onClose={onClose}
      ModalProps={{ keepMounted: false }}
      PaperProps={{
        sx: {
          p: 3,
          m: { xs: 1, sm: 2 },
          height: (theme) => ({
            xs: `calc(100% - ${theme.spacing(2)})`,
            sm: `calc(100% - ${theme.spacing(4)})`,
            md: `calc(100% - ${theme.spacing(6)} - ${barHeight}px)`,
          }),
          width: (theme) => ({
            xs: `calc(100% - ${theme.spacing(2)})`,
            sm: `calc(100% - ${theme.spacing(4)})`,
            md: "50vw",
            lg: "30vw",
          }),
          top: "auto",
          bottom: 0,
        },
      }}
      sx={{ width: "" }}
    >
      {element}
    </Drawer>
  );
};

export default Sidebar;
